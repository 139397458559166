import React, { useEffect, useState } from 'react'
import { useGetProfileQuery,useGetMarginQuery ,useGetHoldingQuery} from '../../../features/upstoxapi';
// import Treemapchart from '../..';

function Dashboard() {
  const [margin,setmargin]=useState([])
  const [value,setvalue]=useState({
    current:0,
    invested:0,
    pl:0,
    pl_percent:0
  })
  // const { data, error, isLoading, refetch } = useGetProfileQuery();
  const { data:holdingdata, error, isLoading, refetch } = useGetHoldingQuery();
  const { data:margindata, error:marginerror, isLoading:marginloading, refetch:marginrefetch } = useGetMarginQuery();
  useEffect(()=>{
    setmargin(margindata?.data?.data)

},[marginloading])

useEffect(()=>{
console.log(holdingdata);
let current=holdingdata?.data?.data?.map((ele)=>ele.quantity*ele.last_price)?.reduce((a,b)=>a+b,0)

let invested=holdingdata?.data?.data?.map((ele)=>ele.quantity*ele.average_price)?.reduce((a,b)=>a+b,0)
const pl=current-invested
const pl_percent=(pl*100)/invested
setvalue({
  current,
  invested,
  pl,
  pl_percent
})
},[isLoading])

 
  
  
  return (
    <>
    {/* name */}
    <div className='page-content'>
      <h1 className='pb-15 mb-30 fs-1-5 ' >Hi,<span>jinendra</span></h1>
      {/* equity section */}
    <div className='border-bottom pb-50 mb-50'>
      <div className='row '>
        {/* equity */}
        <div className='col-6'>
          <div className='mb-20 fs-1rem'><span className='me-2'><i class="bi bi-pie-chart"></i></span>Equity</div>
          <div className='row gx-2'>
            <div className='col-5 border-end'>
              <div className='fs-2625rem stats-block'>{margin?.equity?.available_margin}</div>
              <div className='fs-med text-muted'>Margin available</div>
            </div>
            <div className='col-7 ps-5 px-20 py-10' >
              <div className='mb-10'><span className='fs-med text-muted'>Margins used</span><span>{margin?.equity?.used_margin}</span></div>
              <div className='mb-10'><span className='fs-med text-muted'>Opening balance</span><span>{margin?.equity?.payin_amount}</span></div>
              
              <a href='' className='fs-med mt-10'><span className='me-1 text-primary'><i class="bi bi-stop-circle-fill"></i></span><span>view statement</span></a>
            </div>
          </div>
        </div>
        {/* commodity */}
        <div className='col-6'>
          <div className='mb-20 fs-1rem'><span className='me-2'><i class="bi bi-pie-chart"></i></span>Commodity</div>
          <div className='row gx-2'>
            <div className='col-5 border-end'>
              <div className='fs-2625rem stats-block'>{margin?.commodity?.available_margin}</div>
              <div className='fs-med text-muted'>Margin available</div>
            </div>
            <div className='col-7 ps-5 px-20 py-10' >
              <div className='mb-10'><span className='fs-med text-muted'>Margins used</span><span>{margin?.commodity?.used_margin}</span></div>
              <div className='mb-10'><span className='fs-med text-muted'>Opening balance</span><span>{margin?.commodity?.payin_amount}</span></div>
              
              <a href='' className='fs-med mt-10'><span className='me-1 text-primary'><i class="bi bi-stop-circle-fill"></i></span><span>view statement</span></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* holdings section */}
    <div className='border-bottom pb-50 mb-50' >
      <div className='me-30 w-100'>
        <div className='mb-20 fs-1rem'><span></span><span>Holdings</span><span>(26)</span></div>
        <div className='row'>
          <div className='col-5'>
            <div><span className='fs-2625rem stats-block'>{value?.pl?.toFixed(2)}</span><span>{value?.pl_percent?.toFixed(2)}</span></div>
            <div><span className='fs-med text-muted'>P&L</span></div>
          </div>
          <div className='col-7'>
            <div className='mb-10'><span className='fs-med text-muted'>Current value</span><span>{value?.current?.toFixed(2)}</span></div>
            <div className='mb-10'><span className='fs-med text-muted'>Investment</span><span>{value?.invested?.toFixed(2)}</span></div>
          </div>
        </div>
        <div></div>
      </div>
      <div>
       {/* {holdingdata?.data?.data?.length>0 ?<Treemapchart data={holdingdata?.data?.data}/>:null} */}
      </div>
    </div>
    {/*  Market overview section */}
    <div className='border-bottom pb-50 mb-50'></div>
    </div>
    </>
  )
}

export default Dashboard
