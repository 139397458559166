import React, { useState, useEffect } from "react";
import Iframe from "react-iframe";
import { useSelector, useDispatch } from "react-redux";
import { showtechnical, ordertype, showorder } from "../../features/counterSlice";

const Technical = () => {
  const [modeldata, setmodeldata] = useState("");
  const data = useSelector((state) => state.counter.data);
  const show = useSelector((state) => state.counter.show);
  const dispatch = useDispatch();

  useEffect(() => {
    if (show === true) {
      setmodeldata(
        `https://mo.streak.tech/?utm_source=context-menu&utm_medium=kite&stock=${data?.data?.exchange}:${data?.data?.tradingsymbol}&theme=default`
      );
    }
  }, [data, show]);

  return (
    <>
      <div
        className="modal fade"
        id="technical"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content" style={{ width: "700px" }}>
            <div className="border-bottom">
              <Iframe url={modeldata} width="100%" height="400px" />
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={() => {
                  dispatch(ordertype("BUY"));
                  dispatch(showorder(true));
                }}
                data-bs-toggle="modal"
                data-bs-target="#createorder"
              >
                BUY
              </button>
              <button
                type="button"
                className="btn btn-outline-danger"
                onClick={() => {
                  dispatch(ordertype("SELL"));
                  dispatch(showorder(true));
                }}
                data-bs-toggle="modal"
                data-bs-target="#createorder"
              >
                SELL
              </button>
              <button
                type="button"
                className="btn btn-outline-secondary"
                data-bs-dismiss="modal"
                onClick={() => dispatch(showtechnical(false))}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Technical;
