import React from 'react'

function Sidebar() {
  return (
    <div className='position-fixed border-end' style={{height:"calc(100% - 50px)",overflowX:"hidden",overflowY:"auto",zIndex:1,width:"430px",fontSize:"0.8125rem",backgroundColor:"#fff"}}>
      {/* search input */}
      <div className='position-sticky-top'>
        <div>
            <div className='position-relative'>
                <span className='position-absolute p-3'><i class="bi bi-search"></i></span>
                <span><input className='  border-0 border-bottom w-100' type='text' placeholder='Search' style={{padding:"15px 10px 15px 40px"}} /></span>
            </div>
        </div>

      </div>
      {/* list item */}
      <div>
            <div className='border-bottom' >
                <div className='d-flex w-100  align-items-center' style={{padding:"12px"}}>
                    <div className='d-flex flex-grow-1 justify-content-between align-items-center'>
                        <div><span></span>APOLLOHOSP<span></span>NSE</div>
                        <div>1 <i class="bi bi-bag"></i></div>
                    </div>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div  className='mx-1' style={{minWidth:"50px"}}>
                            <div className='d-flex align-items-center'><span>-39.05</span><span style={{marginLeft:"5px"}}>-0.78</span></div>
                        </div>
                        <div className=' d-flex align-items-center justify-content-center'>
                            <span className='mx-1'><i class="bi bi-chevron-up"></i></span><span style={{minWidth:"60px"}}>4991.55</span>
                        </div>
                    </div>
                </div>
            </div>
      </div>
    </div>
  )
}

export default Sidebar
