import React, { useEffect } from "react";
import { Modal } from "antd";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { showinfo } from "../../features/counterSlice";

function Infomodal() {
  const dispatch = useDispatch();
  const show = useSelector((state) => state.counter.show_info);
  const gdata = useSelector((state) => state.counter.data);
  const [data, setdata] = useState([]);
  useEffect(()=>{
    setdata(gdata?.data)
  },[gdata])

  return (
    <>
      
      <Modal
        title="Basic Modal"
        open={show}
        onCancel={()=>dispatch(showinfo(false))}
      >
       <div>
        <div className="d-flex justify-content-between" >
<div><span>{data?.product}</span><span>{data?.tradingsymbol}</span><span>{data?.exchange}</span></div>
<div>{data?.status}</div>


        </div>

<div className="row">
    <div className="col-6">
<ul>
    <li className="d-flex justify-content-between" >
        <span>Net qty</span><span>{data?.quantity}</span>
    </li>
    {/* <li className="d-flex justify-content-between" >
        <span>Carry forward qty.</span><span>0</span>
    </li> */}
    <li className="d-flex justify-content-between" >
        <span>Avg. price</span><span>{data?.price}</span>
    </li>
    <li className="d-flex justify-content-between" >
        <span>Last price</span><span>1</span>
    </li>

    <li className="d-flex justify-content-between" >
        <span>Last close price</span><span>1</span>
    </li>
    <li className="d-flex justify-content-between" >
        <span>P&L</span><span>1</span>
    </li>
    <li className="d-flex justify-content-between" >
        <span>Day's P&L</span><span>1</span>
    </li>
    <li className="d-flex justify-content-between" >
        <span>Product</span><span>1</span>
    </li>
</ul>



    </div>
    <div className="col-6" ></div>

</div>


       </div>
      </Modal>
    </>
  );
}

export default Infomodal;
