import React,{useState,useEffect} from 'react'
import { useGetPositionQuery } from '../../../features/upstoxapi';

function Positions() {
  const { data, error, isLoading, refetch } = useGetPositionQuery();
  const [positions, setpositions] = useState([]);
  console.log(data?.data?.data);
  useEffect(()=>{
    console.log(data?.data);
  },[isLoading])
  
  return (
    <div className='page-content'>
       <div className='d-flex justify-content-between align-items-center pb-10'>
          <h3 className='fs-1125rem m-0'>Positions ({positions?.length})</h3>
          <div className='d-flex align-items-center'>
          <div className='me-2 position-relative '><span className='position-absolute p-2  fs-small'><i class="bi bi-search"></i></span><input className='fs-med smallsearch border ' placeholder='Search' type='text '/></div>
          <div className='fs-small'><a href=''>Contract note</a></div>
          <div className='fs-small mx-2'><a href=''><span className='me-1'><i class="bi bi-stop-circle-fill"></i></span>View history</a></div>
          <div className='fs-small'><a href=''>Download</a></div>
          </div>
        </div>
     <table class="table border-top ">
  <thead>
    <tr>
      <th className='border-end'>Product</th>
      <th>Instrument</th>
      <th>Qty.</th>
      <th className='border-end'>Avg.</th>
      <th>LTP</th>
      <th>P&L</th>
      <th>Chg.</th>
      
    </tr>
  </thead>
  <tbody>
    {positions?.map((ele,i)=>{
      return <tr key={i}>
      <th className='border-end' >{ele.product}</th>
      <th >{ele.tradingsymbol}</th>
      <th >{ele.quantity}</th>
      <th className='border-end' >{ele.buy_value}</th>
      <th >{(ele.last_price)?.toFixed(2)}</th>
      <th >{ele.pnl?.toFixed(2)}</th>
      
      <th >{ele.pnl?.toFixed(2)}</th>
      
      
      
    </tr>
    })}
   
  </tbody>
</table>
    </div>
  )
}

export default Positions
