import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
// import "bootstrap";
import 'ag-grid-community/styles//ag-grid.css';
import 'ag-grid-community/styles//ag-theme-alpine.css';
// import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap/dist/css/bootstrap.min.css';

import { ConfigProvider } from 'antd';

// import "./App.css";
import "./index.css"

import App from "./App";

// import reportWebVitals from "./reportWebVitals";
import { store } from "./store";
import { Provider } from "react-redux";
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <QueryClientProvider client={queryClient}>
     <ReactQueryDevtools initialIsOpen={false} />
  <Provider store={store}>
    <StrictMode>
    <ConfigProvider theme={{ token: { fontFamily:'Open Sans,sans-serif'  } }}>
    <App />
    </ConfigProvider>
    </StrictMode>
  </Provider>
  </QueryClientProvider>
);

// reportWebVitals();
