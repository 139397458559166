import React, { useEffect, useState } from "react";
import { Button, Empty, Table } from "antd";
import BasketModal from "../../modals/BasketModal";
import { basketDelete, basketGet } from "../../api/api";
import BasketModal2 from "../../modals/Basketmodal2";
import { Dropdown, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';

// const items = [
//   {
//     label:  <BasketModal2 title={elm.name} name="Edit" />,
//     key: '0',
//   },
//   {
//     type: 'divider',
//   },
//   {
//     label: <span type='button'  onClick={() => deletebasekt(elm.name)}>Delete</span>,
//     key: '3',
//   },
// ];


function Basket() {
  const [basket, setbasket] = useState([]);
  const [loading, setloading] = useState(false);
  useEffect(() => {
    fetchdata();
  }, []);

  const deletebasekt = async (name) => {
    await basketDelete(name);
    fetchdata();
  };

  const fetchdata = async () => {
    setloading(true)
    let data = await basketGet("all");
    data.data.forEach((element,i )=> {
      element.key=i
    });
    setbasket(data?.data);
    setloading(false)
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, elm) => (
        
        <div className="d-flex justify-content-between">
          <div>{elm.name}</div>
          <div>
            {/* <div className="dropdown">
              <button
                className="btn btn-outline-primary btn-sm  py-1"
                
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="bi bi-three-dots"></i>
              </button>
              <ul className="dropdown-menu">
                <li>
                  <div  className="dropdown-item">
                    <BasketModal2 title={elm.name} name="Edit" />
                  </div>
                </li>
                <li>
                  <button
                    
                    className="dropdown-item"
                    onClick={() => deletebasekt(elm.name)}
                  >
                    Delete
                  </button>
                </li>
              </ul>
            </div> */}
            <Dropdown
    menu={{
      items:[
        {
          label:  <BasketModal2 title={elm.name} name="Edit" />,
          key: '0',
        },
        {
          type: 'divider',
        },
        {
          label: <Button  onClick={() => deletebasekt(elm.name)}>Delete</Button>,
          key: '3',
        },
      ],
    }}
    trigger={['click']}
  >
    <a onClick={(e) => e.preventDefault()}>
      <Space>
        
        <DownOutlined />
      </Space>
    </a>
  </Dropdown>
          </div>
        </div>
      ),
    },
    {
      title: "Items",
      dataIndex: "all_order",
      key: "all_order",
    },
    {
      title: "Created on",
      dataIndex: "created_at",
      key: "created_at",
    },
    
  ];

  return (
    <div   >
      <div className="d-flex justify-content-between align-items-center">
        <h4>Baskets({basket?.length})</h4>
        <div className="d-flex">
          <div className="me-2">
            <BasketModal />
          </div>
          
        </div>
      </div>
 <div>
       {basket.length >0 ? <Table  loading = { loading} columns={columns} dataSource={basket} />:<Empty/>}
      </div>
    </div>
  );
}

export default Basket;
