import React, { useState } from 'react';
import { Button, Modal} from 'antd';
import { useNavigate } from "react-router-dom";

function LogoutModal() {
    let navigate = useNavigate();
    const logout=()=>{
      console.log("fds");
        localStorage.clear();
        navigate("/home");
    }
  
  return (
    <>
    
    <Button
        //   type="primary"
        className='w-100 border-0'
          onClick={() => {
            Modal.confirm({
              title: 'Confirm',
              content: 'Are you sure you want to Logout',
              onOk: () => logout(), 
              // footer: (_, { OkBtn, CancelBtn }) => (
              //   <>
              //     <CancelBtn  />
              //     <OkBtn onClick={()=>logout()} />
              //   </>
              // ),
            });
          }}
        >
          Log out
        </Button>
    
    
      </>
  )
}

export default LogoutModal