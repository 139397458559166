import React from 'react'
import Navbar from './compenent/Navbar'
import Sidebar from './compenent/Sidebar'

import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Holdings from './compenent/Mainmenu/Holdings';

import Funds from './compenent/Mainmenu/Funds';
import Positions from './compenent/Mainmenu/Positions';
import Dashboard from './compenent/Mainmenu/Dashboard';
import Ordersmain from './compenent/Mainmenu/Ordersmain';



function MainHomeUpstox() {
  return (
   <>
   <Navbar/>
   <div className='d-flex p-0 w-100 m-0 position-relative ' style={{minHeight:"calc(100vh - 60px)",backgroundColor:"#fff"}}>
    <div className='container-left  shadow-sm border' style={{flex:"0 0 430px"}}><Sidebar/></div>
    <div className='container-right  w-100'>
      
    <Routes>
              <Route exact path="/" element={<Dashboard />} />
              <Route exact path="holdings" element={<Holdings />} />
              <Route exact path="orders/*" element={<Ordersmain/>} />
              <Route exact path="funds" element={<Funds />} />

              {/* <Route exact path="profile" element={<Profile />} /> */}
              <Route exact path="positions" element={<Positions />} />
              {/* <Route exact path="sidebar" element={<Sidebar />} /> */}
              {/* <Route
                exact
                path="/chart/web/ciq/:exchange/:instrument/:token?"
                element={<Chart key={location.pathname} />}
              /> */}
              </Routes>
              </div>
    
   </div>
   </>
  )
}

export default MainHomeUpstox
