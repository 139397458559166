import React, { useState ,useEffect} from 'react'
import { useGetMarginQuery } from '../../../features/upstoxapi';

function Funds() {
  const { data, error, isLoading, refetch } = useGetMarginQuery();
  const [funds,setfunds]=useState([])
  useEffect(()=>{
    
    setfunds(data?.data?.data)

 },[isLoading])
  
 
  return (
    <div className='page-content'>
      <div>
        <div className='text-end'>
          <span>Instant, zero-cost fund transfers with </span>
          <button className='btn btn-primary btn-sm mx-2'>Add Funds</button>
          <button className='btn btn-success btn-sm'>Withdraw</button>
        </div>
        <div className='row'>
          <div className='col-6'>
            <h3><span></span><span>Equity</span></h3>
            <div className='border' style={{padding:"20px"}}>
            <table class="table table-borderless">
  
  <tbody>
    <tr>
      <td className='text-muted'>Available_margin</td>
      <td className='text-end bigtitle text-primary  fw-normal'>{funds?.equity?.available_margin}</td>
    </tr>
    <tr>
      <td className='text-muted'>Used margin</td>
      <td className='text-end bigtitle   fw-normal'>{funds?.equity?.used_margin}</td>
    </tr>
    <tr className='border-bottom'>
      <td className='text-muted'>Payin amount</td>
      <td className='text-end bigtitle  fw-normal'>{funds?.equity?.payin_amount}</td>
    </tr><tr>
      <td className='text-muted'>Span margin</td>
      <td className='text-end'>{funds?.equity?.span_margin}</td>
    </tr><tr>
      <td className='text-muted'>Adhoc margin</td>
      <td className='text-end'>{funds?.equity?.adhoc_margin}</td>
    </tr><tr>
      <td className='text-muted'>Notional cash</td>
      <td className='text-end'>{funds?.equity?.notional_cash}</td>
    </tr><tr>
      <td className='text-muted'>exposure_margin</td>
      <td className='text-end'>{funds?.equity?.exposure_margin}</td>
    </tr>
  </tbody>
</table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Funds
