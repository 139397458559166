import React from 'react'
import { Button } from 'antd';
import { Link } from 'react-router-dom';


function Navbar() {
  
  return (

<nav className='d-flex px-4 py-2 position-sticky top-0 bg-light shadow-sm'>
  <div className='flex-shrink-1'><img  style={{maxHeight:"35px"}} src='/jenyalogo2.png' alt="company logo"/></div> 
  <div className='w-100 text-end'>
  <Button type='primary'><Link to={localStorage.getItem('accessdata')===null?'/login':'/'}>{localStorage.getItem('accessdata')===null?'Sign in':'Go to home'}</Link></Button>  
  </div> 

</nav>
  )
}

export default Navbar
