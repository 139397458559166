import React, { useEffect, useState } from "react";
import { Button, Empty, Input, Select, Table } from "antd";
import { useSelector, useDispatch } from "react-redux";

import {  websocketSend } from "../../features/counterSlice";

import { positionsPost } from "../api/api";
import { data } from "autoprefixer";
import { Alert } from 'antd';
// import { Select, Space } from 'antd';
import DropdownOrderMenu from "../components/DropdownOrderMenu";
import {
  useQuery,
  
} from '@tanstack/react-query'







const Positions = () => {
  
  const [net,setNet]=useState([])
  const [day,setDay]=useState([])
  const [loading,setLoading]=useState(false)
  const [amount,setamount]=useState(0)
  const dispatch = useDispatch();
  const wsReceivedata = useSelector((state) => state.counter.websocket_receive);

  const fetchdata=async ()=>{
    setLoading(true)
     const data= await positionsPost()
     setNet(data?.data?.data?.net ?? [])
     
     data?.data?.data?.day?.forEach((elm)=>{
      elm.intraday_pl= elm.day_buy_quantity > elm.day_sell_quantity ?
    ((elm.day_sell_price-elm.day_buy_price)*elm.day_sell_quantity):
    ((elm.day_sell_price-elm.day_buy_price) *elm.day_buy_quantity)
     })
     
     setDay(data?.data?.data?.day ?? [])

     let intrument = data?.data?.data?.day?.map((ele) => Number(ele.instrument_token))
  let message = { a: "mode", v: ["quote", intrument] }
  
  dispatch(websocketSend(message))
  setLoading(false)
  }
  
  
  useEffect(()=>{
    return () => {
      let intrument = data?.data?.data?.day?.map((ele) => ele.instrument_token)
      let message = { a: "unsubscribe", v: intrument }
      
      dispatch(websocketSend(message))
    }
  
  },[])

  const query = useQuery({ queryKey: ['position'], queryFn: fetchdata })



  useEffect(() => {
    day?.forEach((ele) => {
      let ltp = wsReceivedata?.find((e) => ele.instrument_token == e.instrument_token)?.last_price
      let change = wsReceivedata?.find((e) => ele.instrument_token == e.instrument_token)?.change
      
      if (ltp) {
        ele.last_price = ltp
        ele.day_change_percentage = change
        ele.day_change = ltp - (ltp / (1 + (change / 100)))
      }

    })

    

    if(localStorage.getItem("haircutting") != null && localStorage.getItem("haircuttingExecution")=="true" ){
      
      if(localStorage.getItem("haircutting") >= total){
        localStorage.removeItem("haircutting")
        localStorage.removeItem("haircuttingExecution")
        
      }
    }


  }, [wsReceivedata,data])

  const total = day.map((elm)=>((elm.last_price-elm.average_price)*elm.quantity)-elm?.intraday_pl).reduce((a,b)=>a+b,0).toFixed(2)

  
  

  const columns = [
    {
      title: "Product",
      dataIndex: "product",
      sorter: (a, b) => a.product.localeCompare(b.product),
      render: (_, elm) => elm.product,
    },
    {
      title: "Symbol",
      dataIndex: "tradingsymbol",
      sorter: (a, b) => a.tradingsymbol.localeCompare(b.tradingsymbol),
      render: (_, elm) => (
        <div className="d-flex justify-content-between">
          <div className="text-left">
            {elm.tradingsymbol} <small>{elm.exchange}</small>
          </div>
          <div>
           <DropdownOrderMenu data={elm}/>
          </div>
        </div>
      ),
    },

    {
      title: "Qty",
      dataIndex: "quantity",
      sorter: (a, b) => a.quantity-b.quantity,
      render: (_, elm) => elm.quantity,
    },
    {
      title: "Avg.",
      dataIndex: "average_price",
      sorter: (a, b) => a.average_price-b.average_price,
      render: (_, elm) => elm?.average_price?.toFixed(),
    },
    {
      title: "Ltp",
      dataIndex: "last_price",
      sorter: (a, b) => a.last_price-b.last_price,
      render: (_, elm) => elm?.last_price?.toFixed(2),
    },
    {
      title: "Day buy qty",
      dataIndex: "day_buy_quantity",
      sorter: (a, b) => a.day_buy_quantity-b.day_buy_quantity,
      render: (_, elm) => elm?.day_buy_quantity?.toFixed(2),
    },
    {
      title: "Day sell qty",
      dataIndex: "day_sell_quantity",
      sorter: (a, b) => a.day_sell_quantity-b.day_sell_quantity,
      render: (_, elm) => elm?.day_sell_quantity?.toFixed(2),
    },
    {
      title: "Intraday P&L",
      dataIndex: "intraday P&L",
      sorter: (a, b) =>a.intraday_pl-b.intraday_pl,
      render: (_, elm) => elm?.intraday_pl?.toFixed(2),
    },
    {
      title: "Position P&L",
      dataIndex: "position P&L",
      sorter: (a, b) =>((a.last_price-a.average_price)*a.quantity) - ((b.last_price-b.average_price)*b.quantity),
      render: (_, elm) => (((elm.last_price-elm.average_price)*elm.quantity)-elm?.intraday_pl)?.toFixed(2),
    },
    
    {
      title: "P&L",
      dataIndex: "unrealised2",
      sorter: (a, b) => ((a.last_price-a.average_price)*a.quantity)-((b.last_price-b.average_price)*b.quantity),
      render: (_, elm) => ((elm.last_price-elm.average_price)*elm.quantity).toFixed(2),
    },
    {
      title: "Chg.",
      dataIndex: "netchange",
      sorter: (a, b) =>((a.last_price - a.average_price) / a.average_price)-((b.last_price - b.average_price) / b.average_price) ,
      render: (_, elm) =>((elm.last_price - elm.average_price) / elm.average_price).toFixed(2)},
  ];

  const renderFooter = () => {
    
    return (

      <div className=" d-flex flex-end">
        <span>Total:</span> <strong className={`ms-2 ${total>0 ? "text-success" : "text-danger"}`}> {total}</strong>
      </div>
    );
  };

  const exitPosition = async ()=>{
    localStorage.setItem("haircutting",amount)
    localStorage.setItem("haircuttingExecution",true)
  }

  const [selectedValues, setSelectedValues] = useState({
    transaction_type: 'all',
    order_type: 'all',
    policy: 'all',
  });

  const dataArray = day.filter((trade) => {
    return (
      (selectedValues.transaction_type === 'buy' && trade.buy_quantity > 0) ||
      (selectedValues.transaction_type === 'sell' && trade.sell_quantity > 0) ||
      (selectedValues.transaction_type === 'all') // Handle 'all' case
  
    ) && (
      (selectedValues.order_type === 'all') || // Handle 'all' case
      (trade.product === selectedValues.order_type)
    )

    &&

    (
      (selectedValues.policy === 'all') || // Handle 'all' case
      (trade.product === selectedValues.policy)
    )
  });

  return (

    <div  className="allpadding pb-5">
{day.length ==0 && net.length ==0  ?<> <h3>Positions</h3> <Empty /></>:
<>
      
      {/* {localStorage.getItem("haircutting") !== null && localStorage.getItem("haircutting") !== undefined && 
       
      <Alert message={`Price is tracked for given position at Amount of ${localStorage.getItem("haircutting")}`} type="success" />
      } */}
      <div>
        
        {/* <div className="d-flex">
        <div>
          <Input type="Number" placeholder="Please Enter Amount" value={amount} onChange={(values)=>{setamount(values.target.value)}}/>
        </div>
        <div className="ms-2">
          <Button  onClick={exitPosition} type="primary">
            Execute Position
          </Button>
        </div>
        </div> */}
        <div className="d-flex">
      <h5 className="py-2">
        Positions ({day.length})
      </h5>
      <Select
      className="mx-2"
      defaultValue="all"
      onChange={(e)=> setSelectedValues(prevValues => ({
        ...prevValues,
        transaction_type: e,
      }))}
      style={{
        width: 200,
      }}
      // placeholder='Filter by transaction type'
      // onChange={handleChange}
      options={[
        {
          value: 'buy',
          label: 'buy',
        },
        {
          value: 'sell',
          label: 'Sell',
        },
        {
          value: 'all',
          label: 'All',
        }
        
      ]}
    />

<Select
      defaultValue="all"
      style={{
        width: 200,
      }}
      onChange={(e)=> setSelectedValues(prevValues => ({
        ...prevValues,
        order_type: e,
      }))}
      // placeholder='Filter by transaction type'
      // onChange={handleChange}
      options={[
        {
          value: 'CNC',
          label: 'CNC',
        },
        {
          value: 'MIS',
          label: 'MIS',
        },
        {
          value: 'all',
          label: 'All',
        }
        
      ]}
    />


    
<Select
      defaultValue="all"
      style={{
        width: 200,
      }}

      className="mx-2"
      // placeholder='Filter by transaction type'
      onChange={(e)=> setSelectedValues(prevValues => ({
        ...prevValues,
        policy: e,
      }))}
      options={[
        {
          value: 'general',
          label: 'General',
        },
        {
          value: 'waya',
          label: 'waya',
        },
        {
          value: 'all',
          label: 'All',
        }
        
      ]}
    />
      </div>
    {day.length>0 && <Table 
    // dataSource={day}
    dataSource={dataArray}
    columns={columns}
    footer={renderFooter}
    loading={loading}
    />}
    </div>
        
    <div>
      
    <h5>
    Day's history ({net.length})
        </h5>

        
    <Table 
    dataSource={net}
    loading={loading}
    columns={columns}
    />
    </div>
    </>
    }
    </div>


  );
};

export default Positions;
