import React,{useEffect, useState} from 'react'
import { useGetHoldingQuery } from '../../../features/upstoxapi';

function Holdings() {
  const { data, error, isLoading, refetch } = useGetHoldingQuery();
  const [holdings, setholdings] = useState([]);
  console.log(data?.data?.data);
  useEffect(()=>{
    setholdings(data?.data?.data)
  },[isLoading])
  return (
    <>
    <div className='page-content'>
    <div className='d-flex justify-content-between align-items-center pb-10'>
          <h3 className='fs-1125rem m-0'>Holdings ({holdings?.length})</h3>
          <div className='d-flex align-items-center'>
          <div className='me-2 position-relative '><span className='position-absolute p-2  fs-small'><i class="bi bi-search"></i></span><input className='fs-med smallsearch border ' placeholder='Search' type='text '/></div>
          <div className='fs-small'><a href=''>Contract note</a></div>
          <div className='fs-small mx-2'><a href=''><span className='me-1'><i class="bi bi-stop-circle-fill"></i></span>View history</a></div>
          <div className='fs-small'><a href=''>Download</a></div>
          </div>
        </div>
    <table class="table border-top table-hover">
  <thead>
    <tr>
      <th className='border-end'>Instrument</th>
      <th>Qty</th>
      <th>Avg. cost</th>
      <th className='border-end'>LTP</th>
      <th>Cur. val</th>
      <th>P&L</th>
      <th>Net chg.</th>
      <th>Day chg.</th>
    </tr>
  </thead>
  <tbody>
    {holdings?.map((ele,i)=>{
      return <tr key={i}>
      <th className='border-end' scope="row" style={{width:"200px"}}>{ele.tradingsymbol}</th>
      <th >{ele.quantity}</th>
      <th >{(ele.quantity*ele.average_price)?.toFixed(2)}</th>
      <th className='border-end' >{ele.last_price?.toFixed(2)}</th>
      <th >{(ele.last_price*ele.quantity)?.toFixed(2)}</th>
      <th >{ele.pnl?.toFixed(2)}</th>
      <th >{ele.pnl?.toFixed(2)}</th>
      <th >{ele.day_change?.toFixed(2)}</th>
      
      
      
    </tr>
    })}
   
  </tbody>
</table>
</div>
    </>
  )
}

export default Holdings
