import React ,{useState,useEffect}from 'react'
import { Button, Statistic, Table } from "antd";
import { sell_reference, taxpnl } from './api/api';
import dateFormat from "dateformat";
// import { CSVLink } from "react-csv";
import { exportToExcel } from '../../libs/exportToExcel';

function Consell() {

    const [data, setdata] = useState([]);
    const [loading, setloading] = useState(false);
    useEffect(() => {
        fetch();
      }, []);
    
      const fetch = async () => {
        setloading(true)
        let data = await taxpnl('2023-10-05');
        setdata(data?.data);
        setloading(false)
      };

      

    const tableColumns = [
      {
        title: "Date",
        dataIndex: "buy_date",
        sorter: (a, b) => a.buy_date - b.buy_date,
        render:(_,elm)=>dateFormat(elm?.buy_date, "dd-mm-yyyy")
      },
        {
          title: "Instrument",
          dataIndex: "tradingsymbol",
          sorter: (a, b) =>a.tradingsymbol.localeCompare(b.tradingsymbol),
          render: (_, elm) => (
            <>
            <div className='d-flex justify-content-between align-items-center'>
              <div>{elm.tradingsymbol}</div>
              <div className='text-muted fs-small'>{elm.exchange}</div>
              </div>
            </>
          ),
        },
        {
          title: "Qty",
          dataIndex: "deduct_quantity",
          sorter: (a, b) => a.deduct_quantity- b.deduct_quantity,
          
        },
        {
          title: "Buy price",
          dataIndex: "buy_price",
          sorter: (a, b) => a.buy_price -b.buy_price,
          
        },
        {
          title: "Sell price",
          dataIndex: "sell_price",
          sorter: (a, b) => a.sell_price- b.sell_price,
          
        },
        
        {
          title: "P&L",
          dataIndex: "pl",
          sorter: (a, b) => a.pl - b.pl,
          render: (_, elm) => (
            <span className={elm.pl > 0 ? "text-success" : "text-danger"}>
              {elm.pl?.toFixed(2)}
            </span>
          ),
        },
       
      ];
  return (
    <div className="">
    <div className="py-4 border-bottom  ">
      <h5 >
        <span className="me-2">
        <i className="bi bi-journal-x"></i>
        </span>
      <span>Tax P&L</span>
      </h5>
      <div className='d-flex'>
      <Statistic  className='me-3' title="Total profit" value={data.map((a)=>a.pl).reduce((a,b)=>a+b,0).toFixed(2)} />
      <Statistic title="Total Invested" value={data.map((a)=>a.buy_price*a.deduct_quantity).reduce((a,b)=>a+b,0).toFixed(2)} />

      </div>
    </div>
    <div className='text-end my-2'>
      <Button loading={loading} type='primary' onClick={()=>exportToExcel(data)}>Download</Button>
    </div>
    <Table loading={loading} columns={tableColumns} bordered dataSource={data} />
  </div>
  )
}

export default Consell
