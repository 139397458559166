import React from 'react'

function GTT() {
  return (
    <div>
      cvxcv
    </div>
  )
}

export default GTT
