import React, { useEffect, useState } from "react";
import { holdings } from "./api/api";
import { Table,Button, Statistic, Space } from "antd";
import { DatePicker} from 'antd';

import dateFormat from "dateformat";

import { exportToExcel } from "../../libs/exportToExcel";

function Conholdings() {
  const [data, setdata] = useState([]);
  const [date, setdate] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    fetchholdings();
  }, []);

  const fetchholdings = async () => {
    setLoading(true)
    let data = await holdings(date);
    data.data.forEach((ele)=>{
      ele.invested=ele.quantity*ele.average_price
      ele.current=ele.quantity*ele.ltp

    })
    setdata(data.data);
    setLoading(false)
  };
  const tableColumns = [
    {
      title: "Instrument",
      dataIndex: "tradingsymbol",
      sorter: (a, b) =>a.tradingsymbol.localeCompare(b.tradingsymbol),

      
    },
    {
      title: "Isin",
      dataIndex: "isin",
      sorter: (a, b) =>a.tradingsymbol.localeCompare(b.tradingsymbol),
    },
    {
      title: "Qty.",
      dataIndex: "quantity",
      sorter: (a, b) => a.quantity - b.quantity,
    },
    {
      title: "Buy avg.",
      dataIndex: "average_price",
      sorter: (a, b) => a.average_price - b.average_price,
      render: (_, elm) => elm.average_price?.toFixed(2),
    },
    {
      title: "invested",
      dataIndex: "invested",
      sorter: (a, b) => a.invested - b.invested,
      render: (_, elm) =>elm.invested.toFixed(2)
    },

    {
      title: "current",
      dataIndex: "current",
      sorter: (a, b) => a.current - b.current,
      render: (_, elm) =>elm.current.toFixed(2)
    },
    {
      title: "ltpdate",
      dataIndex: "ltpdate",
      sorter: (a, b) =>new Date(a.ltpdate) - new Date(b.ltpdate),
      render: (_, elm) =>dateFormat(elm.ltpdate, "dd-mm-yyyy")
    },

    {
      title: "ltp",
      dataIndex: "ltp",
      sorter: (a, b) =>a.ltp-b.ltp,
      // render: (_, elm) =>dateFormat(elm.ltpdate, "dd-mm-yyyy")
    },
    
    
    {
      title: "unrealised",
      dataIndex: "unrealised",
      sorter: (a, b) => a.unrealised - b.unrealised,
      render: (_, elm) => <span className={elm.unrealised>0?"text-success":"text-danger"}>{elm.unrealised?.toLocaleString("en-IN")}</span>,
    },
  ];

  const onChange = (date, dateString) => {
    setdate(dateString)
  };

  const download=async()=>{
    await exportToExcel(data)
  }

  return (
    <div className="">
      <div className="py-4 border-bottom  ">
        <div className="d-flex justify-content-between">
        <h5 >
          <span className="me-2">
          <i className="bi bi-briefcase"></i>
          </span>
        <span>  Holdings</span>
      
    
        </h5>
        <div>
        <DatePicker onChange={onChange} />
        <Button loading={loading} className="mx-2" onClick={fetchholdings} type="primary"><i className=" bi bi-arrow-right "></i></Button>
        <Button loading={loading}  type="primary" onClick={download} >Download</Button>
        </div>
        </div>
<div className="d-flex my-4 align-items-center justify-content-center">
        
        <Statistic  title="Unrealised" value={data.map((ele)=>ele.ltp==0?0:ele.unrealised).reduce((a,b)=>a+b,0).toFixed(2)?.toLocaleString("en-IN")} />
        
        <Space/>
        <Statistic className="mx-5" title="Invested" value={data.map((ele)=>ele.invested).reduce((a,b)=>a+b,0).toFixed(2)?.toLocaleString("en-IN")} />

        
        <Space/>

<Statistic title="Current" value={data.map((ele)=>ele.current).reduce((a,b)=>a+b,0).toFixed(2)?.toLocaleString("en-IN")} />

</div>
      </div>
      <Table columns={tableColumns} loading={loading} bordered dataSource={data} 
      pagination={false}
      />
    </div>
  );
}

export default Conholdings;
