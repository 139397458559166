import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASEURL } from "../zerodha/BaseURL";

//profile
export const profileApi = createApi({
  
  reducerPath: "profile",
  baseQuery: fetchBaseQuery({ baseUrl: BASEURL+'upstox/profile' }),
  endpoints: (builder) => ({
  getProfile: builder.query({
      query: () => `?token=${localStorage.getItem('accessdata')}`,
  }),
  }),
});

///get-funds-and-margin
export const marginApi = createApi({
  
  reducerPath: "margin",
  baseQuery: fetchBaseQuery({ baseUrl: BASEURL+'upstox/get-funds-and-margin' }),
  endpoints: (builder) => ({
  getMargin: builder.query({
      query: () => `?token=${localStorage.getItem('accessdata')}`,
  }),
  }),
});

//portfolio/long-term-holdings

export const holdingApi = createApi({
  
  reducerPath: "holding",
  baseQuery: fetchBaseQuery({ baseUrl: BASEURL+'upstox/holdings' }),
  endpoints: (builder) => ({
  getHolding: builder.query({
      query: () => `?token=${localStorage.getItem('accessdata')}`,
  }),
  }),
});

//portfolio/short-term-positions

export const positionApi = createApi({
  
  reducerPath: "position",
  baseQuery: fetchBaseQuery({ baseUrl: BASEURL+'upstox/positions' }),
  endpoints: (builder) => ({
  getPosition: builder.query({
      query: () => `?token=${localStorage.getItem('accessdata')}`,
  }),
  }),
});

//Get order book current day
export const orderApi = createApi({
  
  reducerPath: "order",
  baseQuery: fetchBaseQuery({ baseUrl: BASEURL+'upstox/orders' }),
  endpoints: (builder) => ({
  getOrder: builder.query({
      query: () => `?token=${localStorage.getItem('accessdata')}`,
  }),
  }),
});
// Get trades
export const tradeApi = createApi({
  
  reducerPath: "trade",
  baseQuery: fetchBaseQuery({ baseUrl: BASEURL+'upstox/trades' }),
  endpoints: (builder) => ({
  getTrade: builder.query({
      query: () => `?token=${localStorage.getItem('accessdata')}`,
  }),
  }),
});


export const { useGetProfileQuery  } = profileApi;
export const { useGetMarginQuery  } = marginApi;
export const { useGetHoldingQuery  } = holdingApi;
export const { useGetPositionQuery  } = positionApi;
export const { useGetOrderQuery  } = orderApi;
export const { useGetTradeQuery  } = tradeApi;