import React, { useEffect, useState } from "react";
import { Button, Empty, Table, Tag, Tooltip } from "antd";
import dateFormat from "dateformat";
// import { format } from "date-fns";
import { OrderDataPost, getunofficialorders, getunofficialtrades, tradesPost, zerodhaOrder } from "../../api/api";
// import Dropdown from "../../shared-components/Dropdown";
// import { motion } from "framer-motion";

import ContractNoteModals from "../../modals/ContractNoteModals";
import DropdownOrderMenu from "../../components/DropdownOrderMenu";
import {
  useQuery,
  
} from '@tanstack/react-query'

function Orders2() {
  // const [isLoading, setisLoading] = useState(false);
  // const [openorders, setopenorders] = useState([]);
  // const [executedorders, setexecuted] = useState([]);
  // const [loading, setloading] = useState(false);

const fetchdata=async ()=>{
  // setisLoading(true)
  const response= await zerodhaOrder()

  return response.data
  
  // setopenorders(response?.data?.filter((ele) => ele.status !== "COMPLETE"));
    // setexecuted(response?.data?.filter((ele) => ele.status === "COMPLETE"));
    // setisLoading(false)
}
  

  // useEffect(() => {
  //   fetchdata()
  // }, []);

  const {data,isLoading} = useQuery({ queryKey: ['holdings'], queryFn: fetchdata })

  const openorders=data?.filter((ele) => ele.status !== "COMPLETE")
  const executedorders=data?.filter((ele) => ele.status === "COMPLETE")

  return (
    <div>
      
        {openorders?.length===0 && executedorders?.length === 0 && <Empty/> }
     
     {openorders?.length>0 && <OpenOrders isLoading={isLoading} data={openorders} />}
      
      <div>
       {executedorders?.length > 0 && <OpenOrders isLoading={isLoading} data={executedorders} type={"executed"} />}
      </div>
      
      <div>
        <Trades />
      </div>
    </div>
  );
}

export default Orders2;

//##################### open orders ##############################################

function OpenOrders(props) {
  const orderColumns = [
    {
      title: "Time",
      dataIndex: "order_timestamp",
      sorter: (a, b) => a.order_timestamp-b.order_timestamp,
      render: (_, elm) => dateFormat(new Date(elm.order_timestamp), "dd-mm-yyyy hh:mm:ss") ,
    },
    {
      title: "Type",
      dataIndex: "transaction_type",
      sorter: (a, b) => a.transaction_type.localeCompare(b.transaction_type),
      render: (_, elm) => (
        <>
          <div
            className=""
          >
            <Tag color={elm.transaction_type == 'BUY'?"green":"red"}> {elm.transaction_type}</Tag>
          </div>
        </>
      ),
    },
    {
      title: "Instrument",
      dataIndex: "tradingsymbol",
      sorter: (a, b) => a.tradingsymbol.localeCompare(b.tradingsymbol),
      render: (_, elm) => (
        <div className="d-flex justify-content-between">
          <div >
            {elm.tradingsymbol} <small>{elm.exchange}</small>
          </div>
          
          <div >
          <DropdownOrderMenu data={elm}/>
          
          </div>
          
        </div>
      ),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      sorter: (a, b) => a.quantity-b.quantity,
      render: (_, elm) => <>{elm.filled_quantity} / {elm.quantity}</>,
    },
    {
      title: "Product",
      dataIndex: "product",
      sorter: (a, b) => a.product-b.product,
    },
    {
      title: "Average Price",
      dataIndex: "price",
      sorter: (a, b) =>a.price-b.price ,
      render: (_, elm) =><>{elm.price} / {elm.trigger_price}</>,
    },

    // {
    //   title: props.executed === "executed" ? "LTP" : "",
    //   dataIndex: "curval",
    //   sorter: (a, b) => utils.antdTableSorter(a, b, "curval"),
      
    // },

    {
      title: "Status",
      dataIndex: "status",
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (_, elm) => <Tooltip title={elm.status_message}>
         <Tag color={elm.status==='COMPLETE'?"green":"red"}>{elm.status}</Tag>
        {/* <span className={elm.status==='COMPLETE'?"text-white bg-success border-success fs-small rounded p-1":" rounded text-white border-danger fs-small bg-danger p-1"}  >{elm.status}</span> */}
      </Tooltip>,
    },
  ];

  return (
    <div className="mt-3">
      <div className="d-flex justify-content-between">
        <h5>{props.type === "executed" ? "Executed Orders" : "Open orders"} &nbsp;({props?.data?.length})</h5>
        <span>{props.type === "executed" && <ContractNoteModals data={props?.data}/>}</span>
      </div>

      <div
       className="border-top mt-2 mb-5"
      >
        <Table loading={props.isLoading} columns={orderColumns} dataSource={props?.data} pagination={false}  />
      </div>
    </div>
  );
}

//##################### Executed orders ##############################################

//##################### Trades orders ##############################################

function Trades() {
  // const [trades, setTrades] = useState([]);

  // useEffect(() => {
  //   fetchtrades();
  // }, []);

  

  const fetchtrades = async () => {
    const data = await getunofficialtrades();
    data.data.forEach((element,i )=> {
      element.key=i
    });

    return data.data
    // setTrades(data?.data);
  };

  const {data:trades,isLoading} = useQuery({ queryKey: ['trades'], queryFn: fetchtrades })

  const tradeColumns = [
    {
      title: "Trade ID",
      dataIndex: "trade_id",
      sorter: (a, b) => a.trade_id-b.trade_id,
    },
    {
      title: "Fill Time",
      dataIndex: "fill_timestamp",
      sorter: (a, b) => a.fill_timestamp-b.fill_timestamp,
      render: (_, elm) =>dateFormat(new Date(elm.fill_timestamp), "hh:mm:ss") 
      // format(new Date(elm.fill_timestamp), "kk:mm:ss")
        
    },
    {
      title: "Type",
      dataIndex: "transaction_type",
      sorter: (a, b) => a.transaction_type.localeCompare(b.transaction_type),
      render: (_, elm) => <Tag color={elm.transaction_type == 'BUY'?"green":"red"}> {elm.transaction_type}</Tag>,
    },
    {
      title: "Instrument",
      dataIndex: "instrument",
      sorter: (a, b) => a.tradingsymbol.localeCompare(b.tradingsymbol),
      render: (_, elm) => (
        <div className="d-flex align-items-start justify-content-between">
          
           <span> {elm.tradingsymbol} <small>{elm.exchange}</small></span>
        
          <DropdownOrderMenu data={elm}/>
          
         
          
        </div>
      ),
    },
    {
      title: "Product",
      dataIndex: "product",
      sorter: (a, b) => a.product.localeCompare(b.product),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      sorter: (a, b) => a.quantity-b.quantity,
     
    },
    {
      title: "Avg. Price",
      dataIndex: "average_price",
      sorter: (a, b) => a.average_price-b.average_price,
      
    },
  ];

  return (
   <>{trades?.length>0 && 
   <div>
      <h5>Trades&nbsp;({trades?.length})</h5>
      <div>
        <Table columns={tradeColumns} loading={isLoading} dataSource={trades} pagination={false}  />
      </div>
    </div>}
    </>
  );
}
