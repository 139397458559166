import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BASEURL } from "../zerodha/BaseURL";

const GetaccesstokenUpstox = (props) => {
  const navigate = useNavigate();
  const [searchParams, setsearchParams] = useSearchParams();
  //   const [code, setcode] = useState("")

  useEffect(() => {
    if (searchParams.get("code") !== "") {
      getuserdata(searchParams.get("code"));
    }
  }, []);

  const getuserdata = async (token) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);
    const getdata = await fetch(BASEURL + "upstox/getaccesstoken", {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        code: token,
      }),
    });

    const data = await getdata.json();
    console.log(data);
    if (data.status === 200) {
      if (data.access_token !== "") {
        
        localStorage.setItem("accessdata", data.access_token);
        localStorage.setItem("broker", "upstox");
        
        navigate("/upstox", { replace: true });
      } else {
      }
    }
  };
};

export default GetaccesstokenUpstox;
