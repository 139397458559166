import { Button, Input } from 'antd'
import React, { useState } from 'react'
import {  message } from "antd";
import { saveWsToDb } from './api/api';

function Morning() {
    const [value, setvalue] = useState("")
    const inputValue=(e)=>{
            setvalue(e.target.value)
    }

    const saveToDb =async ()=>{
        try {
            const data= await saveWsToDb(value)
            console.log(data.type,data.message);
            message?.[data?.type](data.message);
        } catch (error) {
            message.error(`Something went wrong`);
        }
            
    }

    return (
    <div className='d-flex align-items-center justify-content-center flex-column' style={{height:"100vh"}}>
        <h2 className='my-4'>Enter WebSocket Url</h2>
       
        <Input size="large" className='w-50'  onChange={inputValue} />
        <Button  className='mt-4 ' onClick={saveToDb}>Save websocket url</Button>
        
    </div>
  )
}

export default Morning