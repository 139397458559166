import { BASEURL } from "../../BaseURL";


const holdings=async(date)=>{
    const myHeaders = new Headers();
    const token = localStorage.getItem("requesttoken");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);
    const getdata = await fetch(BASEURL + `console/holdings?date=${date}`, {
      method: "GET",
      headers: myHeaders,
     
    });
  
    const data = await getdata.json();
    return data;
}


const tradebook=async(isin)=>{
    const myHeaders = new Headers();
    // console.log(isin.queryKey[1]);
    const token = localStorage.getItem("requesttoken");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);
    const getdata = await fetch(BASEURL + `console/trades?isin=${isin.queryKey[1]}`, {
      method: "GET",
      headers: myHeaders,
     
    });
  
    const data = await getdata.json();
    
    return data;
}

const trade_calculate=async(isin)=>{
  console.log(isin.queryKey[1]);
  const myHeaders = new Headers();
  const token = localStorage.getItem("requesttoken");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", token);
  const getdata = await fetch(BASEURL + `console/trades_calculate?isin=${isin.queryKey[1]}`, {
    method: "GET",
    headers: myHeaders,
   
  });

  const data = await getdata.json();
  
  return data;
}


const pnl=async(from,to)=>{
  const myHeaders = new Headers();
  const token = localStorage.getItem("requesttoken");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", token);
  const getdata = await fetch(BASEURL + `console/pnl?from=${from}&to=${to}`, {
    method: "GET",
    headers: myHeaders,
   
  });

  const data = await getdata.json();
  
  return data;
}

const taxpnl=async(from,to,symbol)=>{
  const myHeaders = new Headers();
  const token = localStorage.getItem("requesttoken");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", token);
  const getdata = await fetch(BASEURL + `console/taxpnl?from=${from}&to=${to}&isin=${symbol}`, {
    method: "GET",
    headers: myHeaders,
   
  });

  const data = await getdata.json();
  
  return data;
}



const holdingSummary=async(date)=>{
  try {
    const myHeaders = new Headers();
  const token = localStorage.getItem("requesttoken");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", token);
  const getdata = await fetch(BASEURL + `console/holding-summary`, {
    method: "GET",
    headers: myHeaders,
   
  });

  const data = await getdata.json();
  
  return data;
  } catch (error) {
    console.log(error);
  }
}

const holdingSummaryPolicy=async(date)=>{
  try {
    const myHeaders = new Headers();
  const token = localStorage.getItem("requesttoken");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", token);
  const getdata = await fetch(BASEURL + `console/holding-summary-policy`, {
    method: "GET",
    headers: myHeaders,
   
  });

  const data = await getdata.json();
  
  return data;
  } catch (error) {
    console.log(error);
  }
}

const holdingSummaryBreakdown=async(date)=>{
  try {
    const myHeaders = new Headers();
  const token = localStorage.getItem("requesttoken");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", token);
  const getdata = await fetch(BASEURL + `console/holding-summary-breakdown?date=${date}`, {
    method: "GET",
    headers: myHeaders,
   
  });

  const data = await getdata.json();
  
  return data;
  } catch (error) {
    console.log(error);
  }
}



const sell_reference=async(date)=>{
  const myHeaders = new Headers();
  const token = localStorage.getItem("requesttoken");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", token);
  const getdata = await fetch(BASEURL + `console/sell?date=${date}`, {
    method: "GET",
    headers: myHeaders,
   
  });

  const data = await getdata.json();
  
  return data;
}


const eventApi=async(year)=>{
  const myHeaders = new Headers();
  const token = localStorage.getItem("requesttoken");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", token);
  const getdata = await fetch(BASEURL + `console/event?year=${year}`, {
    method: "GET",
    headers: myHeaders,
   
  });

  const data = await getdata.json();
  
  return data;
}


const differenceApi=async()=>{
  const myHeaders = new Headers();
  const token = localStorage.getItem("requesttoken");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", token);
  const getdata = await fetch(BASEURL + "console/difference", {
    method: "GET",
    headers: myHeaders,
   
  });

  const data = await getdata.json();
  
  return data;
}


const indicesChartApi=async(instrumentToken)=>{
  const myHeaders = new Headers();
  const token = localStorage.getItem("requesttoken");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", token);
  const getdata = await fetch(BASEURL + `console/preopen?token=${instrumentToken}`, {
    method: "GET",
    headers: myHeaders,
   
  });

  const data = await getdata.json();
  
  return data;
}

const search=async(q)=>{
  const myHeaders = new Headers();
  const token = localStorage.getItem("requesttoken");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", token);
  const getdata = await fetch(BASEURL + `console/search?search=${q}`, {
    method: "GET",
    headers: myHeaders,
   
  });

  const data = await getdata.json();
  
  return data;
}

export {holdings,tradebook,pnl,sell_reference,eventApi,holdingSummaryPolicy,trade_calculate,holdingSummaryBreakdown,differenceApi,holdingSummary,indicesChartApi,search,taxpnl}