import React, { useState, useEffect } from "react";
import {Spin, Image } from "antd";
import dateFormat from "dateformat";

import {
  
  
  ordertype, showorder,
  
} from "../../features/counterSlice";

import { APPKEY } from "../BaseURL";
import useWebSocket from "react-use-websocket";
// import { format } from "date-fns";
import { useSelector, useDispatch } from "react-redux";


var NseCD = 3,
  BseCD = 6,
  Indices = 9;

var triggers = {
  connect: [],
  ticks: [],
  disconnect: [],
  error: [],
  close: [],
  reconnect: [],
  noreconnect: [],
  message: [],
  order_update: [],
};
var modeFull = "full", // Full quote including market depth. 164 bytes.
  modeQuote = "quote", // Quote excluding market depth. 52 bytes.
  modeLTP = "ltp";

function Marketwatch() {
  const dispatch = useDispatch();
  const show = useSelector((state) => state.counter.show_marketwatch);
  const gdata = useSelector((state) => state.counter.data);
  const [tokendata, settokendata] = useState([]);

  const [InstrumentData, setInstrumentData] = useState([]);

  const [instloading, setinstloading] = useState("");
  
  const [socketUrl, setSocketUrl] = useState(
    `wss://ws.kite.trade?api_key=${APPKEY}&access_token=${localStorage.getItem(
      "accessdata"
    )}`
  );
  const { sendMessage, lastMessage, getWebSocket } = useWebSocket(socketUrl, {
    share: false,
  });

  useEffect(() => {
    setTimeout(() => {
      getWebSocket().binaryType = "arraybuffer";
    }, 2000);
  }, [gdata]);

  useEffect(() => {
    if (lastMessage !== null) {
      if (lastMessage?.data instanceof ArrayBuffer) {
        trigger("message", [lastMessage?.data]);

        if (lastMessage?.data?.byteLength > 2) {
          var d = parseBinary(lastMessage?.data);
          


            
          let data = d?.filter((i) =>{
          
         return   Number(gdata?.data?.instrument_token)===Number(i?.instrument_token)
             }  );

          

          if (data.length > 0) {
            if (d) trigger("ticks", [data]);
            settokendata(data);
            
          }
          
        }
      } else {
        parseTextMessage(lastMessage.data);
      }
    }
  }, [lastMessage, settokendata, gdata]);

  function parseTextMessage(data) {
    try {
      data = JSON.parse(data);
    } catch (e) {
      return;
    }

    if (data.type === "order") {
      trigger("order_update", [data.data]);
    }
  }

  function trigger(e, args) {
    if (!triggers[e]) return;
    for (var n = 0; n < triggers[e].length; n++) {
      triggers[e][n].apply(triggers[e][n], args ? args : []);
    }
  }

  function splitPackets(bin) {
    // number of packets
    var num = buf2long(bin.slice(0, 2)),
      j = 2,
      packets = [];

    for (var i = 0; i < num; i++) {
      // first two bytes is the packet length
      var size = buf2long(bin.slice(j, j + 2)),
        packet = bin.slice(j + 2, j + 2 + size);

      packets.push(packet);

      j += 2 + size;
    }

    return packets;
  }

  function buf2long(buf) {
    var b = new Uint8Array(buf),
      val = 0,
      len = b.length;

    for (var i = 0, j = len - 1; i < len; i++, j--) {
      val += b[j] << (i * 8);
    }

    return val;
  }

  function parseBinary(binpacks) {
    var packets = splitPackets(binpacks),
      ticks = [];

    for (var n = 0; n < packets.length; n++) {
      var bin = packets[n],
        instrument_token = buf2long(bin.slice(0, 4)),
        segment = instrument_token & 0xff;

      var tradable = true;
      if (segment === Indices) tradable = false;

      // Add price divisor based on segment
      var divisor = 100.0;
      if (segment === NseCD) {
        divisor = 10000000.0;
      } else if (segment == BseCD) {
        divisor = 10000.0;
      }

      // Parse LTP
      if (bin.byteLength === 8) {
        ticks.push({
          tradable: tradable,
          mode: modeLTP,
          instrument_token: instrument_token,
          last_price: buf2long(bin.slice(4, 8)) / divisor,
        });
        // Parse indices quote and full mode
      } else if (bin.byteLength === 28 || bin.byteLength === 32) {
        var mode = modeQuote;
        if (bin.byteLength === 32) mode = modeFull;

        var tick = {
          tradable: tradable,
          mode: mode,
          instrument_token: instrument_token,
          last_price: buf2long(bin.slice(4, 8)) / divisor,
          ohlc: {
            high: buf2long(bin.slice(8, 12)) / divisor,
            low: buf2long(bin.slice(12, 16)) / divisor,
            open: buf2long(bin.slice(16, 20)) / divisor,
            close: buf2long(bin.slice(20, 24)) / divisor,
          },
          change: buf2long(bin.slice(24, 28)),
        };

        // Compute the change price using close price and last price
        if (tick.ohlc.close != 0) {
          tick.change =
            ((tick.last_price - tick.ohlc.close) * 100) / tick.ohlc.close;
        }

        // Full mode with timestamp in seconds
        if (bin.byteLength === 32) {
          tick.exchange_timestamp = null;
          var timestamp = buf2long(bin.slice(28, 32));
          if (timestamp)
            tick.exchange_timestamp = 
            dateFormat(new Date(timestamp * 1000), "dd-mm-yyyy hh:mm:ss")
            // format(
            //   new Date(timestamp * 1000),
            //   "yyyy-MM-dd kk:mm:ss"
            // );
        }

        ticks.push(tick);
      } else if (bin.byteLength === 44 || bin.byteLength === 184) {
        var mode = modeQuote;
        if (bin.byteLength === 184) mode = modeFull;

        var tick = {
          tradable: tradable,
          mode: mode,
          instrument_token: instrument_token,
          last_price: buf2long(bin.slice(4, 8)) / divisor,
          last_traded_quantity: buf2long(bin.slice(8, 12)),
          average_traded_price: buf2long(bin.slice(12, 16)) / divisor,
          volume_traded: buf2long(bin.slice(16, 20)),
          total_buy_quantity: buf2long(bin.slice(20, 24)),
          total_sell_quantity: buf2long(bin.slice(24, 28)),
          ohlc: {
            open: buf2long(bin.slice(28, 32)) / divisor,
            high: buf2long(bin.slice(32, 36)) / divisor,
            low: buf2long(bin.slice(36, 40)) / divisor,
            close: buf2long(bin.slice(40, 44)) / divisor,
          },
        };
          
        // Compute the change price using close price and last price
        if (tick.ohlc.close != 0) {
          tick.change =
            ((tick.last_price - tick.ohlc.close) * 100) / tick.ohlc.close;
        }

        // Parse full mode
        if (bin.byteLength === 184) {
          // Parse last trade time
          tick.last_trade_time = null;
          var last_trade_time = buf2long(bin.slice(44, 48));
          if (last_trade_time)
            tick.last_trade_time = 
            dateFormat(new Date(last_trade_time * 1000), "dd-mm-yyyy hh:mm:ss")
            // format(
            //   new Date(last_trade_time * 1000),
            //   "yyyy-MM-dd kk:mm:ss"
            // );

          // Parse timestamp
          tick.exchange_timestamp = null;
          var timestamp = buf2long(bin.slice(60, 64));
          if (timestamp)
            tick.exchange_timestamp =
            dateFormat(new Date(timestamp * 1000), "dd-mm-yyyy hh:mm:ss")
            // format(
            //   new Date(timestamp * 1000),
            //   "yyyy-MM-dd kk:mm:ss"
            // );

          // Parse OI
          tick.oi = buf2long(bin.slice(48, 52));
          tick.oi_day_high = buf2long(bin.slice(52, 56));
          tick.oi_day_low = buf2long(bin.slice(56, 60));
          tick.depth = {
            buy: [],
            sell: [],
          };

          var s = 0,
            depth = bin.slice(64, 184);
          for (var i = 0; i < 10; i++) {
            s = i * 12;
            tick.depth[i < 5 ? "buy" : "sell"].push({
              quantity: buf2long(depth.slice(s, s + 4)),
              price: buf2long(depth.slice(s + 4, s + 8)) / divisor,
              orders: buf2long(depth.slice(s + 8, s + 10)),
            });
          }
        }

        ticks.push(tick);
      }
    }

    return ticks;
  }

  useEffect(() => {
    setTimeout(() => {
      var message = { a: "mode", v: ["full", [Number(gdata?.data?.instrument_token)]] };
      sendMessage(JSON.stringify(message));
    }, 3000);
  }, [gdata]);

  
  
  const onInstrumentSearch = () => {};
  const getInstrumentTokenData = () => {};

  return (
    <>
      <div
        className="modal fade"
        id="marketdepth"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content" style={{ width: "500px" }}>
            <div className="p-3">
              <div 
              // className="input-group flex-nowrap"
              className="d-flex border"
              >
                <span
                  // className="input-group-text input-group-lg"
                  // id="addon-wrapping"
                  className="border-0 p-2"
                >
                  <i className="bi bi-search"></i>
                </span>
                <input
                  type="text"
                  onChange={(e) => onInstrumentSearch(e)}
                  className="py-2  border-0 w-100 "
                  placeholder="Search eg: infy bse, nifty fut, nifty weekly, gold mcx"
                  // aria-label="Username"
                  // aria-describedby="addon-wrapping"
                />
              </div>

            {InstrumentData.length>0?  <div style={{ position: "relative" }}>
                <ul className="omnisearch-results">
                  {InstrumentData?.map((item, i) => {
                    return (
                      <li
                        key={i}
                        className="search-result-item selected"
                        onClick={() => getInstrumentTokenData(item)}
                      >
                        <span className="symbol">
                          <span className="tradingsymbol">
                            {item?.tradingsymbol}
                          </span>
                        </span>
                        <span className="exchange">
                          <span className="company">{item?.name}</span>
                          <span className="exchange-tag text-label text-label-outline NSE">
                            {item.exchange}
                          </span>
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </div>:null}
              {tokendata?.length > 0 ? (
                <div className="mt-3">
                  <b>{gdata?.data?.tradingsymbol}</b>
                  <sub>{gdata?.data?.exchange}</sub>
                  <b style={{ float: "right" }}>
                    {tokendata[0]?.last_price?.toFixed(2)}
                    <small className="text-danger">
                      {(
                        tokendata[0].last_price - tokendata[0]["ohlc"].close
                      ).toFixed(2)}
                      ({tokendata[0]?.change?.toFixed(2)} %)
                    </small>
                  </b>
                </div>
              ) : null}

              {instloading ? (
                <div className="text-center">
                  <Spin tip="Loading..." />
                </div>
              ) : tokendata?.length === 0 ? (
                <div className="text-center">
                  <Image
                    src="../assets/images/marketwatch.svg"
                    width={150}
                    preview={{ visible: false }}
                    className="empty-img"
                  />
                  <div>
                    <h3>Find an instrument</h3>
                    <p>Use the above search bar to find an instrument</p>
                  </div>
                </div>
              ) : (
                <>
                  <div className="row w-100 g m-0 py-3 border-bottom ">
                    <table className="col me-2  ">
                      <thead className="text-light">
                        <tr>
                          <th className="order-price">
                            <span>Bid</span>
                          </th>
                          <th className="orders">Orders</th>
                          <th className="text-end">Qty.</th>
                        </tr>
                      </thead>
                      <tbody className="text-primary">
                        {tokendata[0]?.depth?.buy?.map((item, i) => {
                          return (
                            <>
                              <tr key={i}>
                                <td className="rate">{item?.price}</td>
                                <td className="orders">{item?.orders}</td>
                                <td className="text-end">{item?.quantity}</td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                      <tfoot className="text-primary">
                        <tr>
                          <td className="text-start">Total</td>
                          <td className="orders"></td>
                          <td className="text-end">
                            {tokendata[0]["total_buy_quantity"]}
                          </td>
                        </tr>
                      </tfoot>
                    </table>

                    <table className="col ms-2">
                      <thead className="text-danger">
                        <tr>
                          <th className="order-price">
                            <span>Offer</span>
                          </th>
                          <th className="orders">Orders</th>
                          <th className="text-end">Qty.</th>
                        </tr>
                      </thead>
                      <tbody className="text-danger">
                        {tokendata[0]["depth"]["sell"]?.map((item, i) => {
                          return (
                            <>
                              <tr key={i}>
                                <td className="rate">{item.price}</td>
                                <td className="text-center">{item.orders}</td>
                                <td className="text-end">{item.quantity}</td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                      <tfoot className="text-danger">
                        <tr>
                          <td className="text-start">Total</td>
                          <td className="orders"></td>
                          <td className="text-end">
                            {tokendata[0]["total_sell_quantity"]}
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>

                  <div className="py-3 border-bottom ">
                    <div className="row">
                      <div style={{ width: "50%" }}>
                        <label>Open</label>
                        <div
                          className="value"
                         
                        >
                          {tokendata[0]["ohlc"].open}
                        </div>
                      </div>
                      <div style={{ width: "50%" }}>
                        <label>High</label>
                        <div
                          className="value"
                          
                        >
                          {tokendata[0]["ohlc"].high}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div style={{ width: "50%" }}>
                        <label>Low</label>
                        <div
                          className="value"
                          
                        >
                          {tokendata[0]["ohlc"]?.low}
                        </div>
                      </div>
                      <div style={{ width: "50%" }}>
                        <label>Prev. Close</label>
                        <div
                          className="value"
                          
                        >
                          {tokendata[0]["ohlc"]?.close}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div style={{ width: "50%" }}>
                        <label>Volume</label>
                        <span
                          className="value"
                          
                        >
                          {tokendata[0]?.volume_traded}
                        </span>
                      </div>
                      <div style={{ width: "50%" }}>
                        <label>Avg. price</label>
                        <div
                          className="value"
                          
                        >
                          {tokendata[0]?.average_traded_price}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div style={{ width: "50%" }}>
                        <label>LTQ</label>
                        <span className="value" style={{ cursor: "pointer" }}>
                          {tokendata[0]?.last_traded_quantity}
                        </span>
                      </div>
                      <div style={{ width: "50%" }}>
                        <label>LTT</label>
                        <span
                          className="value"
                          
                        >
                          {tokendata[0]?.last_trade_time}
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="">
              <div className="row my-3 px-3">
                <div className="col-4">
                  <button className="btn btn-outline-primary"   data-bs-toggle="modal" data-bs-target="#creategtt22">
                    Create GTT
                  </button>
                </div>
                <div className="col-8 text-end">
                  <button className="btn btn-outline-primary px-3 " 
                  onClick={() => {dispatch(ordertype("BUY"));dispatch(showorder(true))}}
                  data-bs-toggle="modal" data-bs-target="#createorder">Buy</button>
                  <button className="btn btn-outline-danger px-3 mx-2"  
                  onClick={() => {dispatch(ordertype("SELL"));dispatch(showorder(true))}}
                  data-bs-toggle="modal" data-bs-target="#createorder">
                    Sell
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => {
                      var message = {
                        a: "unsubscribe",
                        v: [gdata?.data?.instrument_token],
                      };

                      sendMessage(JSON.stringify(message));
                    }}
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Marketwatch;
