import React from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';
import { useSelector, useDispatch } from "react-redux";

import {
  
  technicaldata,
  showtechnical,
  showorder,
  
  ordertype,
  showalert,
  showinfo,
  showgtt,
  
  
} from "../../features/counterSlice";
import {  deletegttPost, deleteorderPost } from "../api/api";
import { useNavigate } from "react-router-dom";
import Creategtt from '../modals/CreateGTT';


function DropdownOrderMenu(props) {

const dispatch = useDispatch();
  const navigate = useNavigate();
  const items = [
    {
      label: <span  onClick={() => {dispatch(ordertype("SELL"));dispatch(showorder(true));}}> <i className="bi bi-dash"></i> Exit</span>,
      key: '0',
    },
    {
      label: <span  onClick={() => {dispatch(ordertype("BUY"));dispatch(showorder(true))}}>  <i className="bi bi-plus"></i> Add</span>,
      key: '1',
    },
    {
      type: 'divider',
    },
    {
      label: <span onClick={async () => await deleteorderPost(props.data.order_id,props.data.variety)}> Cancel order</span>,
      key: '3',
    },
    {
      label: <span onClick={() => {
        dispatch(showinfo(true));
      }}><i className="bi bi-plus"></i> Info</span>,
      key: '4',
    },
    {
      label:  <a
      target="_blank"
      href={`https://console.zerodha.com/portfolio/holdings?segment=EQ&isin=${props?.data?.isin}&tradingsymbol=${props?.data?.instument_token}+&src=kiteweb`}
    >
      <i className="bi bi-menu-button"></i>
      View breakdown
    </a>,
      key: '5',
    },
    {
      label: <span  onClick={() => {
        dispatch(showgtt(true));
      }}><i className="bi bi-fast-forward-btn  "></i> Modify gtt</span>,
      key: '6',
    },
    {
      label: <span onClick={async () => {
        await deletegttPost(props.data.trigger_id);
      }}> <i className="bi bi-fast-forward-btn  "></i> Delete GTT</span>,
      key: '7',
    },
    {
      // label: <span  data-bs-toggle="modal" data-bs-target="#creategtt22"><i className="bi bi-fast-forward-btn  "></i> Create GTT / GTC</span>,
      label: <Creategtt data={props.data}/>,
      key: '8',
    },
    {
      label: <span data-bs-toggle="modal"
      data-bs-target="#alertmodal"
      onClick={()=>dispatch(showalert(true))}><i className="bi bi-exclamation-triangle"></i> Create Alert</span>,
      key: '9',
    },
    {
      label:  <a
              
      target="_blank"
      
      href={`https://console.zerodha.com/gift?src=kiteweb`}
    >
      <i className="bi bi-gift"></i>
      Send as gift
    </a>,
      key: '10',
    },
    {
      label:<span   data-bs-toggle="modal"
      data-bs-target="#marketdepth"> Market depth</span>,
      key: '11',
    },
    {
      label:<span onClick={() =>
        navigate(
          `/chart/web/ciq/${data?.data?.exchange}/${data?.data?.tradingsymbol}/${data?.data?.instrument_token}`,
          { replace: true }
        )
      }> <i className="bi bi-graph-up"></i> Chart</span>,
      key: '12',
    },
    {
      label:<span > <i className="bi bi-plus-circle"></i> Add to Marketwatch</span>,
      key: '13',
    },
    {
      label:<span  onClick={() => {
        window.open(
          `https://stocks.tickertape.in/${data?.data?.tradingsymbol}?exchange=${data?.data?.exchange}&broker=kite&theme=default`,
          "_blank",
          "location=yes,height=450,width=600,directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,"
        );
      }}> <i className="bi bi-signpost-2"></i> Fundamentals</span>,
      key: '14',
    },
    {
      label:<span  data-bs-toggle="modal"
      data-bs-target="#technical"
      onClick={() => dispatch(showtechnical(true))} >Technical</span>,
      key: '15',
    },
    
  ];
  

  const data = useSelector((state) => state.counter.data);
  return (
    <Dropdown
     onClick={() => {
            dispatch(technicaldata(props));
            
          }}
    menu={{
      items,
    }}
    trigger={['click']}
  >
    <a  onClick={(e) => {e.preventDefault();
            dispatch(technicaldata(props));
            
          }}>
      <Space>
      
        <DownOutlined />
      </Space>
    </a>
  </Dropdown>
  )
}

export default DropdownOrderMenu