import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Avatar } from 'antd';

import { Dropdown, Space } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import { Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";



import { getprofilePost, zerodhaGetBulletin } from "../api/api";
import LogoutModal from "../modals/LogoutModal";
import { bulletin } from "../../features/counterSlice";

const Header = () => {

  const dispatch = useDispatch();
  const wsReceivedata = useSelector((state) => state.counter.websocket_receive);

  const [nifty, setnifty] = useState([]);
  const [sensex, setsensex] = useState([]);
  const [data, setdata] = useState([]);

  const [windowSize, setWindowSize] = useState(getWindowSize());

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    getuserdata();
  }, []);

  const getuserdata = async () => {
    const data = await getprofilePost();

    const getbulletin = await zerodhaGetBulletin()
    dispatch(bulletin(getbulletin.data))

    setdata(data?.data);
  };

  useEffect(() => {
    const nifty = wsReceivedata?.find((i) => i.instrument_token === 256265);

    if (nifty !== undefined) {
      setnifty(nifty);
    }

    const sensex = wsReceivedata?.find((i) => i.instrument_token === 265);
    if (sensex !== undefined) {
      setsensex(sensex);
    }
  }, [wsReceivedata]);

  const items = [
    {
      label: data?.user_name,
      key: '0',
    },
    {
      label: data?.email,
      key: '0',
    },
    {
      label: <a
        target="_blank"
        href="https://console.zerodha.com/dashboard/"
      >
        <i className="bi bi-record-circle-fill text-primary"></i>{" "}
        Console
      </a>,
      key: '0',
    },
    {
      label: <a
        target="_blank"
        href="https://coin.zerodha.com/login"
      >
        <i className="bi bi-coin"></i> Coin
      </a>,
      key: '1',
    },
    {
      type: 'divider',
    },
    {
      label: <a

        target="_blank"
        href="https://support.zerodha.com/login"


      >
        <i className="bi bi-telephone"></i> Support
      </a>,
      key: '3',
    },
    {
      label: <a

        target="_blank"
        href="https://console.zerodha.com/refer"


      >
        <i className="bi bi-person-plus"></i> Invite Friends
      </a>,
      key: '4',
    },
    {
      label: <NavLink

        to="/home"
        target="_blank"
      >
        Screener
      </NavLink>,
      key: '5',
    },
    {
      label: <NavLink

        to="/console2"
        target="_blank"
      >
        New console
      </NavLink>,
      key: '6',
    },

    {
      label: <a

        target="_blank"
        href="https://kite.zerodha.com/"

      >
        <i className="bi bi-compass"></i> Tour Kite
      </a>,
      key: '7',
    },
    {
      label: <a

        target="_blank"
        href="http://kite.trade/docs/kite"


      >
        <i className="bi bi-journal"></i> User Manual
      </a>,
      key: '8',
    },
    {

      label: <LogoutModal />,
      key: '9',
    },

  ];

  return (
    <>
      <div className="  shadow-sm position-sticky top-0">
        <header >
          <div className=" d-flex align-items-center  ">
            {windowSize.innerWidth > 800 ? (
              <div className="border-end" style={{ width: "430px" }}>

                <div className="  d-flex align-items-center justify-content-between ">

                  <div className="d-flex align-items-center" >
                    <small>NIFTY 50</small>
                    <small className={`mx-1 ${nifty?.change > 0 ? 'text-success' : "text-danger"}`}>
                      {nifty?.last_price?.toFixed(2)}
                    </small>
                    <small class="text-muted">
                      {(nifty?.last_price - nifty?.ohlc?.close)?.toFixed(2)}
                      ({nifty?.change?.toFixed(2)}
                      %)
                    </small>
                  </div>


                  <div className="mx-2 d-flex align-items-center">

                    <small >SENSEX</small>
                    <small className={`mx-1 ${sensex?.change > 0 ? 'text-success' : "text-danger"}`}>
                      {sensex?.last_price}
                    </small>
                    <small class="text-muted">
                      {(sensex?.last_price - sensex?.ohlc?.close)?.toFixed(
                        2
                      )}
                      ({sensex?.change?.toFixed(2)}
                      %)
                    </small>

                  </div>
                </div>
              </div>

            ) : null}
            <div className=" flex-grow-1">
              <div className=" align-items-center d-flex justify-content-between px-3  ">

                <NavLink to="/" >
                  <img
                    style={{ width: "27px" }}
                    src="/jenyalogo2.png"
                    alt="kitelogo"
                  />
                </NavLink>

                <ul className="d-flex align-items-center m-0 ">
                  <li >
                    {windowSize.innerWidth < 800 &&

                      <Tooltip placement="bottom" title="Sidebar">
                        <NavLink
                          className={({ isActive }) => `ms-3 ${isActive && 'text-danger  '}`}
                          to="sidebar"
                        >
                          {windowSize.innerWidth < 800 ? (
                            <i className="bi bi-bookmark "></i>
                          ) : (
                            "sidebar"
                          )}
                        </NavLink>
                      </Tooltip>

                    }


                    <NavLink
                      className={({ isActive }) => `ms-3 ${isActive && 'text-danger '}`}
                      to="/"
                    >
                      {windowSize.innerWidth < 800 ? (
                        <i className="bi bi-house "></i>
                      ) : (
                        "Dashboard"
                      )}
                    </NavLink>



                    <NavLink
                      className={({ isActive }) => `ms-3 ${isActive && 'text-danger '}`}
                      to="orders"
                    >
                      {windowSize.innerWidth < 800 ? (
                        <i className="bi bi-archive "></i>
                      ) : (
                        "Orders"
                      )}
                    </NavLink>



                    <NavLink
                      className={({ isActive }) => `ms-3 ${isActive && 'text-danger '}`}
                      to="holdings"
                    >
                      {windowSize.innerWidth < 800 ? (
                        <i className="bi bi-briefcase "></i>
                      ) : (
                        "Holdings"
                      )}
                    </NavLink>



                    <NavLink
                      className={({ isActive }) => `ms-3 ${isActive && 'text-danger '}`}
                      to="positions"
                    >
                      {windowSize.innerWidth < 800 ? (
                        <i className="bi bi-journal-bookmark "></i>
                      ) : (
                        "Positions"
                      )}
                    </NavLink>




                    <NavLink
                      className={({ isActive }) => `ms-3 ${isActive && 'text-danger '}`}
                      to="funds"
                    >
                      {windowSize.innerWidth < 800 ? (
                        <i className="bi bi-wallet "></i>
                      ) : (
                        "Funds"
                      )}
                    </NavLink>



                  </li>

                  <li className="ms-3" >

                    <Dropdown
                      menu={{
                        items,
                      }}
                      trigger={['click']}
                    >
                      <a onClick={(e) => e.preventDefault()}>
                        <Space>
                          <Avatar
                            style={{ backgroundColor: '#fde3cf', color: '#f56a00' }}
                            size={28} icon={data?.user_name ? data?.user_name.split(' ').map(word => word[0]).join('').toUpperCase() : <UserOutlined />} />
                          {data?.user_id}
                        </Space>
                      </a>
                    </Dropdown>

                  </li>
                </ul>
              </div>
            </div>
          </div>
        </header>
      </div>
    </>
  );
};

export default Header;
