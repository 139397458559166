export default function formatLargeNumber(number) {
    const abbreviations = ['', 'k', 'L', 'Cr', 'Ar']; // Adjust as needed
    const tier = Math.log10(number) / 3 | 0;
    
  
    if (tier === 0) return number;
  
    const suffix = abbreviations[tier];
    const scale = Math.pow(10, tier * 3);
  
    const scaledNumber = number / scale;
  
    // Use toFixed to limit the number of decimal places
    // Adjust the argument of toFixed as needed
    const formattedNumber = scaledNumber.toFixed(2);
  
    return `${formattedNumber}${suffix}`;
  }
  
  
  