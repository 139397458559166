import React from 'react'
import { NavLink } from 'react-router-dom'
import { DownOutlined, SmileOutlined,BookOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, Space } from 'antd';
import { UserOutlined } from '@ant-design/icons';

function Navbar() {
  const items = [
    {
      key: '1',
      label:<NavLink   to="tradebook" >
      <BookOutlined />Tradebook
    </NavLink>,
    },
    {
      key: '2',
      label: <NavLink   to="pnl" >
      P&L
    </NavLink>,
      // icon: <SmileOutlined />,
      
    },
    {
      key: '3',
      label: <NavLink   to="taxpl" >
      Tax P&L
    </NavLink>,
      
    },
    // {
    //   key: '4',
    
    //   label: <NavLink   to="events" >
    //   Events
    // </NavLink>,
    // },
  ];

  const portfolio=[
    {
      key: '1',
      label: <NavLink   to="/console/positions" >
      Positions
    </NavLink>,
    },
    {
      key: '2',
      label: <NavLink   to="/console/holdings" >
      Holdings
    </NavLink>,
      icon: <SmileOutlined />,
      
    },
    {
      key: '3',
      label: <NavLink   to="/console/holding-summary" >
      Holding Summary
    </NavLink>,
      
    },
    {
      key: '4',
    
      label: <NavLink   to="/console/holding-summary-policy" >
      Holding Summary Policy
    </NavLink>,
    },
    // {
    //   key: '5',
    
    //   label:  <NavLink   to="/console/difference" >
    //   Difference
    // </NavLink>,
    // },
  ];

  const funds=[
    {
      key: '1',
      label: <a  href="#">Withdrawal</a>,
    },
    {
      key: '2',
      label: <a  href="#">Statement</a>,
      icon: <SmileOutlined />,
      
    }

  ];

  return (
    
      <nav className='py-3 shadow-sm  ' >
  <div className="container d-flex align-items-center">
  <NavLink  to="/console">Console</NavLink>
    
    <div className='flex-grow-1 text-end' >
    
      
      

        
  <Dropdown menu={{items:portfolio}}>
    <a onClick={(e) => e.preventDefault()}>Portfolio</a>
  </Dropdown>
        
        
        
        <Dropdown menu={{items}} className='mx-3'>
    <a onClick={(e) => e.preventDefault()}>Reports</a>
  </Dropdown>
        

        
        <Dropdown
        className='me-3'
    menu={{
      items:funds,
    }}
  >
    <a onClick={(e) => e.preventDefault()}>
      
        Funds
        
      
    </a>
  </Dropdown>
        
        
        
        {/* <li className="nav-item">
          <a className="nav-link active" aria-current="page" href="#">Account</a>
        </li> */}
        
      
      {/* <div>JB
GMG829</div> */}
<Dropdown
    // menu={{
    //   items,
    // }}
    trigger={['click']}
  >
    <a onClick={(e) => e.preventDefault()}>
      <Space>
      <Avatar 
      style={{ backgroundColor: '#fde3cf', color: '#f56a00' }}
      size={28} icon={<UserOutlined />}/>
        GMG829
      </Space>
    </a>
  </Dropdown>
      
    </div>
  </div>
</nav>
    
  )
}

export default Navbar
