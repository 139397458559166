import { Button, Modal,  Tooltip } from "antd";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table, Input } from "antd";
import { showbasket, technicaldata } from "../../features/counterSlice";
import {  basketGet, basketPost, instrumentsearchPost } from "../api/api";
import React from 'react';
import { notification } from 'antd';
import * as xlsx from "xlsx";

import { exportToExcel } from "../../libs/exportToExcel";
import { BASEURL } from "../BaseURL";







const BasketModal2 = (props) => {

  
  
  
    const { Search } = Input;
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [InstrumentData, setInstrumentData] = useState([]);
    const [basket, setbasket] = useState([]);
    const [api, contextHolder] = notification.useNotification();
    const [abortController, setAbortController] = useState(null);

const openNotification = (title, description, state) => {
  api[state]?.({
    message: title,
    description: description,
    duration: 0,
  });
};


  
    useEffect(() => {
      basket_get();
    }, [open]);
  
    const showModal = () => {
      setOpen(true);
    };

    
    
    const handleOk =async () => {
      setLoading(true)
            
            const placeBasket = async (name) => {
              const controller = new AbortController();
              setAbortController(controller);

              const myHeaders = new Headers();
              const token = localStorage.getItem("requesttoken");
              let accessToken = localStorage.getItem("accessdata");
              const zhcookie=JSON.parse(localStorage.getItem("zhcookie"))
              const zAuthorixation=JSON.parse(localStorage.getItem("zAuthorixation"))
              myHeaders.append("Content-Type", "application/json");
              myHeaders.append("Authorization", token);
              const getdata = await fetch(BASEURL + "place-basket", {
                method: "POST",
                headers: myHeaders,
                body: JSON.stringify({
                  name: name,
                  broker: localStorage.getItem("broker"),
                  accessToken,
                  zhcookie,
                  zAuthorixation
                }),
                signal: controller.signal,
              });
            
              const data = await getdata.json();
              return data;
            };
            await placeBasket(props.title)
            

             openNotification("Success", "Basket order placed","success")
            
            
            setLoading(false)
    };
    const handleCancel = () => {
      setOpen(false);
    };
    //search instrument token
    const onInstrumentSearch = async (e) => {
      document.getElementsByClassName(
        "omnisearch-results-sidebar-2"
      )[0].style.display = "block";
      setInputValue(e.target.value);
      if (e.target.value === "") {
        document.getElementsByClassName(
          "omnisearch-results-sidebar-2"
        )[0].style.display = "none";
        basket_get()

      }
  
      const data = await instrumentsearchPost(e.target.value);
      setInstrumentData(data.data);
    };
  
  
    const basket_get = async () => {

      const data = await basketGet(props?.title);
      setbasket(data?.data);
    };
  
    const columns = [
      {
        title: "",
        dataIndex: "transaction_type",
        key: "transaction_type",
      },
      {
        title: "Variety",
        dataIndex: "variety",
        key: "variety",
      },
      {
        title: "tradingsymbol",
        dataIndex: "tradingsymbol",
        key: "tradingsymbol",
      },
      {
        title: "Exchange",
        dataIndex: "exchange",
        key: "exchange",
      },
      {
        title: "Order Type",
        dataIndex: "order_type",
        key: "order_type",
      },
      {
        title: "Product",
        dataIndex: "product",
        key: "product",
      },
      {
        title: "Quantity",
        dataIndex: "quantity",
        key: "quantity",
      },
      {
        title: "Price",
        dataIndex: "price",
        key: "price",
        render: (_, elm) => (elm?.price ? Number(elm?.price)?.toFixed(2) : "-"),
      },
      {
        title: "Policy",
        dataIndex: "policy",
        key: "policy",
        render: (_, elm) => elm.policy,
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (_, elm) => 
        <Tooltip title={elm.message}>
        <span className={elm.status==='executed'?'text-success':'text-danger'}>
          
          {elm.status==='executed'?
          <i class="bi bi-check-circle-fill"></i>:<i class="bi bi-file-earmark-x-fill"></i>}
          
          </span>
          </Tooltip>,
      },
    ];
  
    const dispatch = useDispatch();

    const readUploadFile =async (e) => {
      e.preventDefault();
      if (e.target.files) {
          const reader = new FileReader();
          reader.onload =async (e) => {
              const data = e.target.result;
              const workbook = xlsx.read(data, { type: "array" });
              const sheetName = workbook.SheetNames[0];
              const worksheet = workbook.Sheets[sheetName];
              const json = xlsx.utils.sheet_to_json(worksheet);
              
              
              setbasket([...basket,...json])

              for (let i = 0; i < json.length; i++) {
                const ele = json[i];
                let values = {
                  exchange:ele.exchange,
                  tradingsymbol:ele.tradingsymbol,
                  quantity:ele.quantity,
                  product:ele.product,
                  transaction_type:ele.transaction_type,
                  order_type:ele.order_type,
                  price:ele.price
                };
                let policy=ele?.policy
                delete values.policy;
                let variety=ele.variety
                delete values.variety;
                await basketPost(values,props?.title,variety,policy);
              }
              
              await basket_get()
              
              
          };
          reader.readAsArrayBuffer(e.target.files[0]);

      }
      
  }
    
  const handleAbort = () => {
    if (abortController) {
      abortController.abort();
      setAbortController(null); // Reset the AbortController
    }
  };

  useEffect(() => {
    // Cleanup function to abort if the component is unmounted
    return () => {
      handleAbort();
    };
  }, []);


  
    return (
      <>
         {/* {contextHolder} */}

         {/* <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/> */}

{contextHolder}



        <Button    onClick={showModal}>
          {props?.name}
        </Button>
        <Modal
          open={open}
          
          title={<div className="fs-3">
<div>{props?.title}</div>



          </div>}

          onOk={handleOk}
          width='fit-content'
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel}>
              Close
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={loading}
              onClick={handleOk}
            >
              Submit
            </Button>,
            <Button
            key="abort"
            danger
            type="primary"
            // loading={loading}
            onClick={handleAbort}
          >
            Abort
          </Button>
          ]}
        >
          <div className="position-relative">
          <div className="d-flex justify-content-between py-3">
<form>
    
    <input
        type="file"
        name="upload"
        id="upload"
        placeholder="Upload an excel file"
        onChange={readUploadFile}
    />
</form>
<div>
{/* <CSVLink
                      filename={"all_holdings.csv"}
                      data={basket}
                      className=" p-1 mx-2 btn btn-primary btn-sm  text-white"
                    >
                      <i className="bi bi-box-arrow-down"></i> Download csv
                    </CSVLink> */}
                    <Button type="primary" onClick={()=>exportToExcel(basket)}>Download</Button>
</div>
</div>
            <Search
              style={{
                position: "sticky",
                top: 0,
                zIndex: 1,
                width: "101%",
              }}
              placeholder="Search eg: infy bse, nifty fut, nifty weekly, gold mcx"
              onChange={(e) => onInstrumentSearch(e)}
              value={inputValue}
            />
  
            <ul
              className="omnisearch-results-sidebar-2 position-absolute shadow-sm m-0 p-0"
              style={{
                height: "50vh",
                width: "100%",
                zIndex: "1",
                backgroundColor: "white",
                overflowY: "scroll",
                overflowX: "hidden",
                display: "none",
              }}
            >
              {InstrumentData?.map((ele, i) => {
                return (
                  <>
                    <li
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#basketorder"
                      key={i}
                      className="search-result-item selected position-relative px-2 d-flex justify-content-between border-bottom  "
                      onClick={() => {
                        dispatch(showbasket(true));
                        dispatch(technicaldata({ data: ele, name: props.title }));
                      }}
                    >
                      <span className="symbol">
                        <span className="tradingsymbol">{ele.tradingsymbol}</span>
                      </span>
                      <span className="exchange">
                        <span className="fs-small text-muted">{ele.name}</span>
                        <span
                          className={
                            ele.exchange === "BSE"
                              ? "badge bg-primary ms-2"
                              : ele.exchange === "NSE"
                              ? "badge bg-danger ms-2"
                              : "badge bg-secondary ms-2"
                          }
                        >
                          {ele.exchange}
                        </span>
                      </span>
                    </li>
                  </>
                );
              })}
            </ul>
          </div>
          <div className="mt-4">
            {loading?<div className="text-center my-5">Executing order ...</div>:
            <>
            <Table columns={columns} dataSource={basket} />
            {/* <div className="row">
              <div className="col-3">
                <div>
                  Total Invested
                </div>
                <div>
                  
                </div>
                </div> 

            </div> */}
            </>
            }
          </div>
        </Modal>
      </>
    );
  };
  
  export default BasketModal2