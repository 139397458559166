import React, { useState, useEffect } from "react";
import {
  notification,
} from "antd";


import { useSelector, useDispatch } from "react-redux";
import { showalert } from "../../features/counterSlice";
import { addalertPost, instrumentsearchPost } from "../api/api";

const Createalert = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.counter.data);
  const show = useSelector((state) => state.counter.alert);
  const [InstrumentData, setInstrumentData] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [inputValue1, setInputValue1] = useState("");

  let obj2 = {
    name: "",
    lhs_instrument_token: 0,
    lhs_exchange: "",
    lhs_tradingsymbol: "",
    lhs_attribute: "last_price",
    operator: "<",
    rhs_type: "constant",
    rhs_constant: 0,
    rhs_exchange: "",
    rhs_instrument_token: 0,
    rhs_tradingsymbol: "",
    rhs_attribute: "",
  };

  useEffect(() => {
    
    setform({
      ...form,
      lhs_instrument_token: data?.data?.instrument_token,
      lhs_exchange: data?.data?.exchange,
      lhs_tradingsymbol: data?.data?.tradingsymbol,
      rhs_constant: data?.data?.last_price,
    });
    setInputValue(data?.data?.tradingsymbol + ":" + data?.data?.exchange);
  }, [data]);

  const [form, setform] = useState(obj2);
 

  const onInstrumentSearch = async (e) => {
    setInputValue(e.target.value);
    document.getElementsByClassName(
      "omnisearch-results-alert"
    )[0].style.visibility = "visible";
    const data = await instrumentsearchPost(e.target.value);
    setInstrumentData(data.data);
  };

  const onInstrumentSearch1 = async (e) => {
    setInputValue1(e.target.value);
    document.getElementsByClassName(
      "omnisearch-results-alert1"
    )[0].style.visibility = "visible";

    const data = await instrumentsearchPost(e.target.value);
    setInstrumentData(data.data);
  };

  const onFinish = async () => {
    const data = await addalertPost(form);
    if (data.status === 200) {
      let message = data.data;
      notification.success({
        message: "Success",
        description: message,
      });
    } else {
      let message = data.error;
      notification.error({
        message: "Failed",
        description: message,
      });
    }
  };

  const getInstrumentTokenData = (item) => {
    
    setform({
      ...form,
      lhs_exchange: item.exchange,
      lhs_tradingsymbol: item.tradingsymbol,
      lhs_instrument_token: item.instrument_token,
    });

    document.getElementsByClassName(
      "omnisearch-results-alert"
    )[0].style.visibility = "hidden";
    setInputValue(item.tradingsymbol + ":" + item.exchange);
  };
  const getInstrumentTokenData1 = (item) => {
    setform({
      ...form,
      rhs_exchange: item.exchange,
      rhs_tradingsymbol: item.tradingsymbol,
      rhs_instrument_token: Number(item.instrument_token),
    });

    document.getElementsByClassName(
      "omnisearch-results-alert1"
    )[0].style.visibility = "hidden";
    setInputValue1(item.tradingsymbol + ":" + item.exchange);
  };

  return (
    <>




<div className="modal fade "  id="alertmodal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog " >
    <div className="modal-content p-3  " style={{width:"700px"}}  >
  
          <div className="row">
            <div className="col">
              <h5>New alert</h5>
            </div>
            <div className="col text-end ">
              <a
                rel="noreferrer"
                target="_blank"
                href="https://zerodha.com/z-connect/tradezerodha/kite/introducing-gtt-good-till-triggered-orders"
              >
                <i className="bi bi-info-circle text-primary me-10"></i>Help
              </a>
            </div>
          </div>

          
            <input
              type="text"
              required
              placeholder="Alert name"
              className="w-100 p-2 border"
              value={form.name}
              onChange={(e) => setform({ ...form, name: e.target.value })}
            />
          

          <div className="row mt-4">
            <div className="col">
              
              <label className="text-muted" >Property</label>
                <select
                className="p-2 w-100 border"
                  value={form?.lhs_attribute}
                  onChange={(e) =>
                    setform({ ...form, lhs_attribute: e.target.value })
                  }
                >
                  <option value='last_price' >Last Traded Price</option>
                  <option value='ohlc.high' >High Price</option>
                  <option value='ohlc.low' >Low Price</option>
                  <option value='ohlc.open' >Open Price</option>
                  <option value='ohlc.close' >Close Price</option>
                  <option value='last_traded_quantity' >Last Traded Quantity</option>
                  <option value='average_traded_price' >Average Trade Price</option>
                  <option value='volume_traded' >Volume Traded</option>
                  <option value='total_buy_quantity' >Total Buy Quantity</option>
                  <option value='total_sell_quantity' >Total Sell Quantity</option>
                  <option value='oi' >Open Interest</option>
                  <option value='oi_day_high' >Open Interest Day High</option>
                  <option value='oi_day_low' >Open Interest Day Low</option>
                  

                </select>
              
              
            </div>

            <div className="col" style={{ position: "relative" }}>
              <label className="text-muted">Of</label>
              <input
              type="text"
              className="w-100 p-2 border"
                placeholder="Search eg: infy bse, nifty fut, nifty weekly, gold mcx"
                onChange={(e) => onInstrumentSearch(e)}
                value={inputValue}
              />
              <ul className="omnisearch-results-alert">
                {InstrumentData?.map((item, i) => {
                  return (
                    <li
                      key={i}
                      className="search-result-item selected"
                      onClick={() => getInstrumentTokenData(item)}
                    >
                      <span className="symbol">
                        <span className="tradingsymbol">
                          {item.tradingsymbol}
                        </span>
                      </span>
                      <span className="exchange">
                        <span className="company">{item.name}</span>
                        <span className="exchange-tag text-label text-label-outline NSE">
                          {item.exchange}
                        </span>
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>

          <div className="row my-4">
            <div className="col">
              <label className="text-muted">Is</label>
                <select
                  className="w-100 p-2 border"
                  value={form.operator}
                  onChange={(e) =>{
                    setform({ ...form, operator:e.target.value });console.log(e.target.value);}
                  }
                >
                  <option value='>' >Greater than (&#62;)</option>
                  <option value='>=' >Greater than or equal to (&#62;=)</option>
                  <option value='<' >Less than (&#60;)</option>
                  <option value='<=' >Less than or equal to (&#60;=)</option>
                  <option value='==' >Equal to (==)</option>
                  </select>
              
            </div>

            <div className="col d-flex align-items-end  " value={form.rhs_type}   onChange={(e) =>
                  e.target.value === "constant"
                    ? setform({
                        ...form,
                        rhs_type: e.target.value,
                        rhs_exchange: undefined,
                        rhs_instrument_token: undefined,
                        rhs_tradingsymbol: undefined,
                      })
                    : setform({
                        ...form,
                        rhs_type: e.target.value,
                        rhs_constant: undefined,
                      })
                } >
                  <span className="me-4">
            <input className="me-2" type="radio" id="html" name="rhs_type" value="constant"/>
            <label htmlFor="html">Value</label><br/>
            </span>
            <span>
            <input className="me-2" type="radio" id="css" name="rhs_type" value="instrument"/>
            <label htmlFor="css">instrument</label><br/>
            </span>
             
            </div>
          </div>

          {form.rhs_type === "constant" ? (
            <div className="row">
              <div className="col-4">
                
                  <input
                    value={form.rhs_constant}
                    className="w-100 border p-2 mb-1"
                    type="number"
                    onChange={(e) =>
                      setform({ ...form, rhs_constant: e.target.value })
                    }
                  />
                
                <div className="d-flex justify-content-start align-items-center">
                  
                    <input
                    type="number"
                      
                      
                      className=" border w-25 h-25"
                    />
                  
                  
                    <span className="fs-small text-muted ms-1">% of LastTradedPrice</span>
                  
                </div>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col">
              <label className="text-muted">Property</label>
                <select
                className="p-2 w-100 border"
                value={form.rhs_attribute}
                onChange={(e) =>
                  setform({ ...form, rhs_attribute: e.target.value })
                }
                >
                  <option value='last_price' >Last Traded Price</option>
                  <option value='ohlc.high' >High Price</option>
                  <option value='ohlc.low' >Low Price</option>
                  <option value='ohlc.open' >Open Price</option>
                  <option value='ohlc.close' >Close Price</option>
                  <option value='last_traded_quantity' >Last Traded Quantity</option>
                  <option value='average_traded_price' >Average Trade Price</option>
                  <option value='volume_traded' >Volume Traded</option>
                  <option value='total_buy_quantity' >Total Buy Quantity</option>
                  <option value='total_sell_quantity' >Total Sell Quantity</option>
                  <option value='oi' >Open Interest</option>
                  <option value='oi_day_high' >Open Interest Day High</option>
                  <option value='oi_day_low' >Open Interest Day Low</option>
                  

                </select>
                
              </div>

              <div className="col position-relative" >
                <div className="">Of</div>
                <input
                type="text"
                  placeholder="Search eg: infy bse, nifty fut, nifty weekly, gold mcx"
                  onChange={(e) => onInstrumentSearch1(e)}
                  value={inputValue1}
                  className='p-2 border w-100'
                />
                <ul className="omnisearch-results-alert1 position-absolute">
                  {InstrumentData?.map((item, i) => {
                    return (
                      <li
                        key={i}
                        className="search-result-item selected"
                        onClick={() => getInstrumentTokenData1(item)}
                      >
                        <span className="symbol">
                          <span className="tradingsymbol">
                            {item.tradingsymbol}
                          </span>
                        </span>
                        <span className="exchange">
                          <span className="company">{item.name}</span>
                          <span className="exchange-tag text-label text-label-outline NSE">
                            {item.exchange}
                          </span>
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          )}

          <div className="row my-4">
            <div>
              <button

                className="float-end btn btn-outline-primary"
                htmltype="submit"
                onClick={onFinish}
              >
                Create
              </button>

              <button
                data-bs-dismiss="modal"
                className="float-end mx-2 btn btn-outline-secondary"
                onClick={() => {
                  dispatch(showalert(false));
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        
    
      
    </div>
  </div>
</div>
     
    </>
  );
};

export default Createalert;
