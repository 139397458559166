import React, { useState, useEffect } from "react";
import Draggable from "react-draggable";

import { Tabs, Switch, Tooltip, Spin, InputNumber, Select, Space, Checkbox, Button } from "antd";
import { InfoCircleOutlined, ReloadOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { margin_data, showorder, websocketSend } from "../../features/counterSlice";
import { Radio } from 'antd';
import {getpolicy_name,zerodhaMargin,zerodhaNudge,zerodhaPlaceOrder} from "../api/api";
import { debounce } from "lodash";
import { notification } from 'antd';
const items2 = [
  {
    key: "regular",
    label: `Regular`,
  },
  {
    key: "co",
    label: `Cover`,
  },
  {
    key: "amo",
    label: `Amo`,
  },
  {
    key: "iceberg",
    label: `Iceberg`,
  },
];

const items3 = [
  {
    key: "regular",
    label: `Regular`,
  },

  {
    key: "amo",
    label: `Amo`,
  },
  {
    key: "iceberg",
    label: `Iceberg`,
  },
];


const CreateOrder = () => {
  const [api, contextHolder] = notification.useNotification();

  const openNotification = (message, description) => {
    api.info({
      message,
      description,
    });
  };



  const dispatch = useDispatch();
  const wsReceivedata = useSelector((state) => state.counter.websocket_receive);
  const [policy, setpolicy] = useState([]);
  const [policyname, setpolicyname] = useState("");
  const data = useSelector((state) => state.counter.data);

  const transaction = useSelector((state) => state.counter.orders_type);
  const [nudge, setnudge] = useState("");
  const [ltp, setltp] = useState(data?.data?.last_price);


  const [tradingsymbol, settradingsymbol] = useState(data?.data?.tradingsymbol);
  const [transaction_type, settransaction_type] = useState(transaction);
  const [exchange, setexchange] = useState(data?.data?.exchange ?? 'NSE');
  const [variety, setvariety] = useState(data?.data?.variety ?? "regular");
  const [product, setproduct] = useState(data?.data?.product ?? "CNC");
  const [quantity, setquantity] = useState(data?.data?.quantity ?? 1);
  const [price, setprice] = useState(data?.data?.average_price ?? 0);
  const [trigger_price, settriggerprice] = useState(data?.data?.trigger_price ?? 0);
  const [order_type, setorderType] = useState(data?.data?.order_type ?? "MARKET");
  const [validity, setvalidity] = useState(data?.data?.validity ?? "DAY");
  const [validityMinutes, setvalidityMinutes] = useState(0);
  const [disclosedquantity, setdisclosedquantity] = useState(0);
  const [stoploss, setstoploss] = useState(false);
  const [stoplosspercent, setstoplosspercent] = useState(0);
  const [target, settarget] = useState(false);
  const [targetpercent, settargetpercent] = useState(0);
  const [ismoreoption, setismoreoption] = useState(false);
  const [iceberg_legs, seticeberg_legs] = useState(2);


  const [ordermargin, setordermargin] = useState([]);


  useEffect(() => {
    const ltp = wsReceivedata?.find((d) => d.instrument_token == Number(data.data.instrument_token))?.last_price
    if (ltp) setltp(ltp)
  }, [wsReceivedata])

  const [marginloading, setmarginloading] = useState(false);

  useEffect(() => {
    fetchpolicy();
    let message = { a: "mode", v: ["quote", [Number(data?.data?.instument_token)]] }
    dispatch(websocketSend(message))
  }, [data]);



  const fetchpolicy = async () => {
    const data = await getpolicy_name();
    setpolicy(data.data);
    setpolicyname('general');
  };

  const fetchmargin = async () => {
    setmarginloading(true);
    const values = {
      exchange,
      tradingsymbol,
      transaction_type,
      variety,
      product,
      order_type,
      quantity,
      price,
      trigger_price,
    };

    const data = await zerodhaMargin(values)
    const nudge = await zerodhaNudge(values)

    if (nudge?.data[0][0]?.message) {
      const processedString = nudge?.data[0][0].message.replace(/\[([^\]]+)\]\(([^)]+)\)/g, '<a href="$2">$1</a>')
      const output = <p dangerouslySetInnerHTML={{ __html: processedString }} />
      setnudge(output)

    }

    setordermargin(data?.data?.[0] ?? [])
    setmarginloading(false);
  };

  const debouncedFetchMargin = debounce(fetchmargin, 1000);

  useEffect(() => {
    debouncedFetchMargin();
  }, [
    product,
    transaction_type,
    product,
    variety,
    order_type,
    price,
    exchange,
    quantity,
  ]);



  const onsubmit = async (e) => {
    try {
      e.preventDefault();
      const values = {
        exchange,
        tradingsymbol,
        quantity,
        product,
        transaction_type,
        order_type,
        price,
        trigger_price,
        validity,
        iceberg_legs,
        validity_ttl: validityMinutes,
        iceberg_quantity: Math.ceil(quantity / iceberg_legs),
        trigger_price,
        variety,
        policy: policyname,
        targetpercent,
        stoplosspercent
      }


      const response = await zerodhaPlaceOrder(values)
      if (response.data.status === 'error') openNotification(response.data.status, response.data.message)
      else openNotification(response.data.status, `Your order is placed with Order Id: ${response.data.order_id}`)


    } catch (error) {
      console.log(error);
    }
  }


  const orderTypeChange = (e) => {
    setorderType(e.target.value);
    if (e.target.value === "MARKET" || e.target.value === "LIMIT") {
      settriggerprice(0);
    }
  };

  const varietychange = (e) => {
    setvariety(e);
    if (e === "co") {
      settriggerprice(price);
      setproduct("MIS");
    } else {
      settriggerprice(0);
    }
  };



  return (
    <>
      {contextHolder}
      <Draggable>
        <div
          className=" border shadow-sm w-50 "
          style={{
            zIndex: 5,
            position: "absolute",
            background: "white"


          }}
        >
          <div className=" ">
            <div
              className="row gx-0 px-2  pt-3   text-white"
              style={
                transaction_type === "BUY"
                  ? { background: "#4184F3", cursor: "move" }
                  : { background: "#FF5722", cursor: "move" }
              }
            >
              <div className="col ">
                <span >
                  <b> {transaction_type}</b>
                </span>
                <span className=" ms-1">
                  <b> {tradingsymbol}</b>
                </span>
                <span className=" mx-1">
                  <b>{exchange}</b>
                </span>
                <b>X <span className="mx-1">{quantity}</span> Qty</b>
              </div>
              <div className="col text-end ">
                {nudge != "" &&
                  <Tooltip title={nudge}>
                    <span className="me-2"><img src="https://kite.zerodha.com/static/images/nudge-icon-transparent.svg" style={{ width: "20px" }} /></span>
                  </Tooltip>
                }

                <Tooltip placement="top" title="Toggle Buy / Sell">
                  <Switch
                    defaultChecked
                    size="small"
                    onChange={(checked) => settransaction_type(checked ? "BUY" : "SELL")}
                  />
                </Tooltip>
                <Tooltip placement="top" title="Help">
                  <a
                    className="mx-2"
                    rel="noreferrer"
                    target="_blank"
                    href="https://kite.trade/docs/kite/orders/#orders"
                  >
                    <InfoCircleOutlined style={{ color: "#fff" }} />
                  </a>
                </Tooltip>
              </div>
            </div>

            <div
              className="row gx-0 px-2 text-white pb-3 "
              style={
                transaction_type === "BUY"
                  ? { background: "#4184F3" }
                  : { background: "#FF5722" }
              }
            >

              <Radio.Group className="pt-3" onChange={(e) => {
                setexchange(e.target.value);
              }} value={exchange}>
                <Radio className="text-white" value='BSE'> BSE: ₹{ltp?.toLocaleString('en-IN')}</Radio>
                <Radio className="text-white" value='NSE'>NSE: ₹{ltp?.toLocaleString('en-IN')}</Radio>

              </Radio.Group>
            </div>

            <div className="p-2">
              <div className="row  ">
                <div className="col  px-2">
                  <Tabs
                    activeKey={variety}
                    onChange={(e) => varietychange(e)}
                    size="small"
                    items={exchange === "NSE" ? items2 : items3}
                  />
                </div>
              </div>

              <>
                <div className="row  gx-0  ">
                  <div className="col">

                    <Radio.Group onChange={(e) => setproduct(e.target.value)} value={product}>
                      <Tooltip
                        placement="top"
                        title="Margin Intraday Squareoff: Requires lower margin for equity. Has to be exited before market close."
                      >  <Radio disabled={variety === "co"} value="MIS">Intraday<span className="text-muted ">MIS</span></Radio></Tooltip>
                      {variety !== "co" && <Tooltip
                        placement="top"
                        title="CashNCarry: Longterm investment. Requires full upfront margin."
                      > <Radio value='CNC'>Longterm <span className="text-muted ">CNC</span></Radio></Tooltip>}
                    </Radio.Group>

                  </div>
                </div>
                <div className="row gx-0 ">
                  <div className="col  d-flex my-2 gap-x-2">
                    <div >
                      <label >
                        Qty.
                      </label>

                      <div>
                        <InputNumber className="w-100" size="large" min={1} defaultValue={quantity} onChange={(e) => setquantity(e)} />

                      </div>

                    </div>
                    <div className="mx-2"
                    >
                      <label >
                        Price
                      </label>
                      <div >

                        <InputNumber size="large"
                          className="w-100"
                          disabled={order_type === "MARKET" || order_type === "SL-M"}
                          min={1} defaultValue={price} onChange={(e) => setprice(e)} />
                      </div>
                    </div>

                    <div >
                      <label >
                        {variety === "co" ? "Stoploss trigger Price" : "Trigger Price"}
                      </label>
                      <div>


                        <InputNumber size="large"
                          className="w-100"
                          disabled={(order_type === "MARKET" ||
                            order_type === "LIMIT") &&
                            variety !== "co"}
                          min={1} defaultValue={trigger_price} onChange={(e) => settriggerprice(e)} />
                      </div>
                    </div>
                  </div>

                  <Radio.Group onChange={(e) => {
                    orderTypeChange(e)
                  }} value={order_type}>
                    <Radio value='MARKET'>MARKET</Radio>
                    <Radio value='LIMIT'>LIMIT</Radio>
                    <Radio value='SL'>SL</Radio>
                    <Radio value='SL-M'>SL-M</Radio>
                  </Radio.Group>
                </div>
                {variety === "iceberg" &&
                  <div className="row gx-0">
                    <div className="col">
                      <div className="ml-3 ">
                        <label>
                          Number of legs
                        </label>
                        <div className="d-flex flex-column">
                          <label >1 qty. per leg</label>
                          <InputNumber size="large"
                            min={1} defaultValue={iceberg_legs} onChange={(e) => seticeberg_legs(e)} />
                        </div>
                      </div>
                    </div>
                  </div>
                }
                <div className="row gx-0">
                  <div className="col-6">
                    <div >Policy</div>
                    <Select
                      defaultValue="general"
                      style={{ width: 200 }}
                      onChange={(e) => setpolicyname(e)}
                      options={policy.map((d) => { return { value: `t:${d.name}:${d.tag_id}`, label: d.tag_name } })}
                    />

                  </div>
                </div>

                <div className="row gx-0 border-bottom py-2 ">
                  <div className="col-6"></div>

                  <div
                    className="col-6 text-end"
                    onClick={() => setismoreoption(!ismoreoption)}
                  >
                    <span className="me-2 " style={{ cursor: "pointer" }}>
                      {ismoreoption ? "Hide option" : "Show option"}
                    </span>
                    <span>
                      <i
                        className={
                          ismoreoption
                            ? "bi bi-chevron-up"
                            : "bi bi-chevron-down"
                        }
                      ></i>
                    </span>
                  </div>
                </div>

                {ismoreoption === true && variety !== "co" && (
                  <>
                    <div className="row gx-0 py-2 border-bottom ">
                      <div
                        className="col-4 flex-column d-flex"

                      >
                        <label>Validity</label>


                        <Radio.Group onChange={(e) => setvalidity(e.target.value)} value={validity}>
                          <Space direction="vertical">
                            <Tooltip
                              placement="top"
                              title="Valid throughout the day until executed"
                            > <Radio value='DAY'>DAY</Radio></Tooltip>
                            <Tooltip
                              placement="top"
                              title="Cancel if not executed immediately"
                            > <Radio value='TTL'>TTL</Radio></Tooltip>
                            <Tooltip
                              placement="top"
                              title="Cancel if not executed within N minutes"
                            >  <Radio value='IOC'>IOC</Radio></Tooltip>
                          </Space>
                        </Radio.Group>

                      </div>

                      <div className="col-4 ">
                        <Select
                          defaultValue={1}
                          style={{ width: "100%" }}
                          size="large"
                          disabled={validity !== "IOC"}
                          onChange={(e) => setvalidityMinutes(e)}
                          options={[
                            { value: 1, label: "1 minutes" },
                            { value: 2, label: "2 minutes" },
                            { value: 3, label: "3 minutes" },
                            { value: 4, label: "4 minutes" },
                            { value: 5, label: "5 minutes" },
                            { value: 10, label: "10 minutes" },
                            { value: 15, label: "15 minutes" },
                            { value: 30, label: "30 minutes" },
                            { value: 45, label: "45 minutes" },
                            { value: 90, label: "90 minutes" },
                            { value: 120, label: "120 minutes" },
                          ]}
                        />

                      </div>

                      <div className="col-4">
                        <div >
                          <label>
                            Disclosed qty.
                          </label>



                          <InputNumber size="large" min={1} disabled={validity !== "DAY" || variety === "co"} defaultValue={disclosedquantity} onChange={(e) => setdisclosedquantity(e)} />
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {product === "CNC" && variety == "regular" && (
                  <>
                    <div className="row gx-0 align-items-center py-2 border-bottom ">
                      <div className="col-2">
                        <Tooltip
                          placement="top"
                          title="Automatically create a GTT for the position on order completion"
                        >
                          <img
                            style={{ width: "50px", height: "20px" }}
                            src="./../../assets/icons/gtt-logo.png"
                            alt="gtt"
                          />
                        </Tooltip>
                      </div>
                      <div className="col-4 text-center d-flex align-items-center justify-content-center">

                        <Checkbox onChange={(e) => {
                          setstoploss(!stoploss);
                        }}></Checkbox>
                        <span >Stopploss</span>

                        <InputNumber min={1} disabled={!stoploss} defaultValue={quantity} onChange={(e) => setstoplosspercent(e)} />
                        %
                      </div>
                      <div className="col-4 text-center d-flex align-items-center justify-content-center">
                        <Checkbox onChange={(e) => {
                          settarget(!target);
                        }}></Checkbox>

                        <span className="  ">Target</span>

                        <InputNumber min={1} disabled={!target} defaultValue={quantity} onChange={(e) => settargetpercent(e)} />
                        {" "}
                        %
                      </div>
                      <div className="col text-end">
                        <Tooltip
                          placement="top"
                          title="Automatically create a GTT for the position on order completion"
                        >
                          <a
                            target={"_blank"}
                            href="https://zrd.sh/gtt"
                          >
                            <span >Learn more</span>
                          </a>
                        </Tooltip>
                      </div>
                    </div>
                  </>
                )}
              </>



              <div className="row gx-0 px-2 py-3 bg-light">
                <div className="col">
                  <small>
                    <b className="mx-2 label ">Margin:</b>
                    <Tooltip
                      placement="top"
                      title="Margin calculation explained"
                    >
                      <a
                        href="https://support.zerodha.com/category/trading-and-markets/kite-web-and-mobile/articles/margins-on-kite-order-window"
                        target="_blank"
                      >
                        <span style={{ color: "#4184F3" }}>
                          {ordermargin?.charges?.total?.toFixed(2) ?? "N/A"}
                        </span>
                      </a>
                    </Tooltip>
                    <span className="ms-2"> {ordermargin?.leverage > 1
                      ? "(" + ordermargin?.leverage.toFixed(2) + "x)"
                      : null}</span>
                    <b className="mx-2 label">Charges</b>
                    <Tooltip placement="top" title="View charges & taxes">
                      <span
                        type="button"
                        onClick={() => dispatch(margin_data(ordermargin))}
                        data-bs-toggle="modal"
                        data-bs-target="#charges"
                        className="text-primary"
                      >
                        {ordermargin?.charges?.total
                          ? "₹" + ordermargin?.charges?.total?.toFixed(2)
                          : "N/A"}
                      </span>
                    </Tooltip>

                    <ReloadOutlined
                      className="mx-2 text-primary"
                      onClick={() => fetchmargin()}
                    />
                    {marginloading ? <Spin size="small" /> : null}
                  </small>
                </div>
                <div className="col  text-end">
                  <Button
                    type="primary"
                    danger={transaction_type === "SELL"}
                    size="large"

                    onClick={onsubmit}

                  >
                    {transaction_type}
                  </Button>

                  <Button
                    className="ms-2"
                    size="large"
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(showorder(false));
                    }}

                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Draggable>
    </>
  );
};

export default CreateOrder;
