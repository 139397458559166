import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { ArrowRightOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { ltpPost, modifygttMarketPost, newgttMarketPost } from "../api/api";
import { Button, InputNumber, Modal, Radio, notification } from "antd";

const Creategtt = (props) => {
  const [api, contextHolder] = notification.useNotification();

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const showModal = () => {
    setOpen(true);
  };
  
  const handleCancel = () => {
    setOpen(false);
  };
  // const dispatch = useDispatch();
  // const data = useSelector((state) => state.counter.data);
  const data = props.data;
  console.log(data);
  // const show = useSelector((state) => state.counter.show_gtt);
  const [modify, setmodify] = useState(false);

  const [form, setform] = useState({
    trigger_type: props?.data?.trigger_type ?? "single",
    transaction_type: props?.data?.transaction_type ?? "BUY",
    tradingsymbol: props?.data?.tradingsymbol ?? "",
    exchange: props?.data?.exchange ?? 'NSE', 
    trigger_values_0: 0,
    trigger_values_1: 0,
    triggerprice: "",
    last_price: 0,
    stop_quantity: 1,
    target_quantity: 1,
    product: props?.data?.product ?? 'CNC',
    order_type: props?.data?.order_type ?? 'LIMIT',
    stop_price: 0,
    target_price: 0,
  });

  // sell oco

  //   condition: {"exchange":"NSE","tradingsymbol":"HDFCLIFE","trigger_values":[650.75,719.25],"last_price":685.65}


  // orders: [{"exchange":"NSE","tradingsymbol":"HDFCLIFE","transaction_type":"SELL","quantity":1,"price":650.75,"order_type":"LIMIT","product":"CNC"},{"exchange":"NSE","tradingsymbol":"HDFCLIFE","transaction_type":"SELL","quantity":1,"price":719.25,"order_type":"LIMIT","product":"CNC"}]

  // buy single

  // condition: {"exchange":"NSE","tradingsymbol":"HDFCLIFE","trigger_values":[720.15],"last_price":686}
  // orders: [{"exchange":"NSE","tradingsymbol":"HDFCLIFE","transaction_type":"BUY","quantity":1,"price":720.15,"order_type":"LIMIT","product":"CNC"}]
  // type: single
  // expires_at: 2024-12-12 00:00:00

  // tradingsymbol , transaction_type,quantity,upper price, lower price , 

  // useEffect(() => {
  //   setmodify(props?.data.modifygtt);
  //   if (props?.data.modifygtt === true) {
  //     setform({
  //       ...form,
  //       transaction_type: props?.data.transaction_type,
  //       trigger_type: props?.data.trigger_type,
  //       exchange: props?.data.exchange,
  //       trigger_values_0: props?.data.trigger_values_0,
  //       trigger_values_1: props?.data.trigger_values_1,
  //       stop_quantity: props?.data.stop_quantity,
  //       target_quantity: props?.data.target_quantity,
  //       stop_price: props?.data.stop_price,
  //       target_price: props?.data.target_price,
  //       tradingsymbol: props?.data.tradingsymbol,
  //       trigger_id: props?.data.trigger_id,
  //       last_price: props?.data.last_price,
  //     });
  //   } else {
  //     setform({
  //       ...form,
  //       transaction_type: "BUY",
  //       tradingsymbol: props?.data.tradingsymbol,
  //       exchange: props?.data.exchange,
  //       trigger_values_0: 0,
  //       trigger_values_1: 0,
  //       triggerprice: 0,
  //       last_price: 0,
  //       stop_quantity: props?.data.quantity,
  //       target_quantity: props?.data.quantity,
  //       product: props?.data.product,
  //       order_type: props?.data.order_type,
  //     });
  //   }
  // }, [data]);

  // useEffect(()=>{
  //   fetchltp()
  // },[data])

  // const fetchltp = async () => {
  //   const data2 = await ltpPost(
  //     props?.data.tradingsymbol,
  //     props?.data.exchange
  //   );
  //   let ltp =
  //     data2?.data[`${props?.data.exchange}:${props?.data.tradingsymbol}`]
  //       ?.last_price;
  //   setform({ ...form, last_price: ltp });
  // };

  const onFinish = async () => {
    let send = {
      trigger_type: form.trigger_type,
      tradingsymbol: form.tradingsymbol,
      exchange: form.exchange,
      trigger_values: [form.trigger_values_0, form.trigger_values_1],
      last_price: form.last_price,
      orders: [
        {
          transaction_type: form.transaction_type,
          quantity: form.target_quantity,
          product: "CNC",
          order_type: "LIMIT",
          price: form.trigger_values_0,
        },
        {
          transaction_type: form.transaction_type,
          quantity: form.stop_quantity,
          product: "CNC",
          order_type: "LIMIT",
          price: form.trigger_values_1,
        },
      ],
    };

    let data;

    const openNotification = (title,description,status) => {
      api[status]({
        title:title,
        description: description,
      });
    };

    // if (modify === true) {
    //   data = await modifygttMarketPost(send, form.trigger_id);

    //   openNotification(
    //     `gtt modified with trigger id:${props?.data.trigger_id}`
    //   );
    // } else {

      data = await newgttMarketPost(send);
      if(data?.error)openNotification( "GTT created Error", `Something went wrong` ,"error");
      else openNotification( "GTT created", `Gtt created with trigger :${props?.data.trigger_id}` ,"success");
    // }
  };

  return (  
    <>
      {/* <div
        className="modal fade"
        id="creategtt22"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content p-3" > */}
          <Button type="primary" onClick={showModal}>
        Create GTT
      </Button>
      <Modal
        open={open}
        className="w-50"
        title={
          <div className="d-flex align-items-center justify-content-between">
            <div>
            <span > {props?.data?.tradingsymbol}</span>
           <span className="text-muted "> {props?.data?.last_price}</span>
            </div>
            <div className="me-3">
            <a
                  className="text-primary me-2"
                  rel="noreferrer"
                  target="_blank"
                  href="https://zerodha.com/z-connect/tradezerodha/kite/introducing-gtt-good-till-triggered-orders"
                >
                  <i className="bi bi-info-circle"></i>
                </a>
                <img

style={{ width: "50px", height: "20px" }}
src="./../../assets/icons/gtt-logo.png"
alt="gtt"
/>
            </div>
         
          
          </div>
        }
        onCancel={handleCancel}
        
        
        
        footer={[
          <div className="d-flex">
          <small>
           By placing, I agree to the terms and accept that trigger
           executions are not guaranteed. This trigger expires on
         </small>
         <Button size="large" key="back" onClick={handleCancel}>
            Cancel
          </Button>
          <Button size="large" key="submit" type="primary" loading={loading} onClick={onFinish}>
            Submit
          </Button>
         </div>,
         
           
          
        ]}
      >
            {contextHolder}


            <div className="row">
              <div className="col-4">
                <div className="pb-3">Transaction type</div>
                <Radio.Group onChange={(e) =>
                  setform({ ...form, transaction_type: e.target.value })
                } value={form.transaction_type}>
                  <Radio value='BUY'>BUY</Radio>
                  <Radio value='SELL'>SELL</Radio>

                </Radio.Group>

              </div>

              <div className="col-8">
                <div>Trigger type</div>

                <Radio.Group onChange={(e) =>
                  setform({ ...form, trigger_type: e.target.value })
                } value={form.trigger_type}>
                  <Radio value='single'>single</Radio>
                  <Radio value='two-leg' disabled={form.transaction_type === 'BUY'}>Two-leg</Radio>

                </Radio.Group>


                <p>
                  <small>
                    {form.trigger_type === "single"
                      ? `The order is placed when the Last Traded Price (LTP) crosses
                the trigger price. Can be used to enter or exit a position.`
                      : `  One Cancels Other: Either the stoploss or the target order
                is placed when the Last Traded Price (LTP) crosses the
                respective trigger. Can be used to set target and stoploss
                for a position/holding.`}
                  </small>
                </p>
              </div>
            </div>



            {form.trigger_type === "two-leg" && (
              <>
                <div className="row">
                  <div className="col-12">Stopploss</div>
                  <div className="col">

                    <div >
                      <div>
                        Trigger Price
                      </div>

                      <InputNumber size="large" min={1}  defaultValue={form.trigger_values_0}
                        onChange={(e) => setform({ ...form, trigger_values_0: e })} />
                    </div>
                    <div className=" mt-1  ">


                      <InputNumber size="small" min={1}  defaultValue={5} onChange={(e) => {
                        setform({
                          ...form,
                          trigger_values_0: Number(
                            form.last_price +
                            (e * form.last_price) / 100
                          ),
                        });
                      }} />
                      <span className=" ms-1">% of LTP</span>
                    </div>
                  </div>

                  <div className="col  text-muted ">
                    Place Order <ArrowRightOutlined />
                  </div>

                  <div className="col">
                    <div>
                      <Radio.Group   >
                        <Radio value='CNC' disabled>CNC</Radio>
                        <Radio value='LIMIT' disabled>LIMIT</Radio>

                      </Radio.Group>
                    </div>

                    <div className="d-flex">
                      <div>
                        <div>Qty</div>
                        <InputNumber size="large" min={1}  defaultValue={form.stop_quantity} onChange={(e) =>
                          setform({ ...form, stop_quantity: e })
                        } />
                      </div>
                      <div>
                        <div>Price</div>
                        <InputNumber size="large" min={1}  defaultValue={form.stop_price} onChange={(e) =>
                          setform({ ...form, stop_price: e })
                        } />
                      </div>
                    </div>

                  </div>

                </div>
              </>
            )}


              
                <div className="row">
                  <div className="col-12">Target</div>
                  <div className="col">

                    <div >
                      <div>
                        Trigger Price
                      </div>

                      <InputNumber size="large" min={1}  defaultValue={form.trigger_values_1}
                       onChange={(e) =>setform({ ...form, trigger_values_1: e })} />
                    </div>
                    <div className=" mt-1  ">


                      <InputNumber size="small" min={1}  defaultValue={5} onChange={(e) => {
                      setform({
                        ...form,
                        trigger_values_1: Number(
                          form.last_price -
                          (e.target.value * form.last_price) / 100
                        ),
                      });
                    }} />
                      <span className=" ms-1">% of LTP</span>
                    </div>
                  </div>

                  <div className="col  text-muted ">
                    Place Order <ArrowRightOutlined />
                  </div>

                  <div className="col">
                    <div>
                      <Radio.Group   >
                        <Radio value='CNC' disabled>CNC</Radio>
                        <Radio value='LIMIT' disabled>LIMIT</Radio>

                      </Radio.Group>
                    </div>

                    <div className="d-flex">
                      <div>
                        <div>Qty</div>
                        <InputNumber size="large" min={1}  defaultValue={form.target_quantity} onChange={(e) =>
                      setform({ ...form, target_quantity: e })
                    }/>
                      </div>
                      <div>
                        <div>Price</div>
                        <InputNumber size="large" min={1}  defaultValue={form.target_price} onChange={(e) =>
                        setform({ ...form, target_price: e })
                      } />
                      </div>
                    </div>

                  </div>

                </div>
            


            {/* <div className="row ">
              <div className="col ">
                <small>
                  By placing, I agree to the terms and accept that trigger
                  executions are not guaranteed. This trigger expires on{" "}
                  
                </small>
              </div>
              <div className="col text-end">
                <button
                  className="btn btn-outline-primary mx-2 "
                  onClick={() => onFinish()}
                >
                  {form.transaction_type}
                </button>

                <button
                  className="btn btn-outline-secondary"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </div> */}


            </Modal>
          {/* </div>
        </div>
      </div> */}

      {/* <Modal width={680} open={show} footer={[]}> */}

      {/* </Modal> */}
    </>
  );
};

export default Creategtt;
