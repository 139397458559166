import GTT from "./GTT";



import Basket from "./Basket";
import Orders2 from "./Orders2";

import React from "react";

import Auction from "./Auction";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  NavLink,
} from "react-router-dom";
import Policy from "./Policy";
import Haircutting from "./Haircutting";
import { Tabs } from "antd";

function Orders() {



  const items = [
    {
      key: 'orders',
      label: <NavLink to=''>Orders</NavLink>,
      
    },
    {
      key: 'gtt',
      label: <NavLink to='gtt'>gtt</NavLink>,
      
    },
    {
      key: 'basket',
      label: <NavLink to='basket'>basket</NavLink>,
      
    },
    {
      key: 'auction',
      label: <NavLink to='auction'>auction</NavLink>,
      
    },
    {
      key: 'tag',
      label: <NavLink to='tag'>tag</NavLink>,
      
    },
    {
      key: 'haircutting',
      label: <NavLink to='haircutting'>haircutting</NavLink>,
      
    },
    
  ];

  return (
   
    <>
  <Tabs defaultActiveKey="orders" items={items}  />

     
<div className="my-5" >
            <Routes>
              <Route exact path="" element={<Orders2 />} />
              <Route exact path="gtt" element={<GTT />} />
              <Route exact path="basket" element={<Basket />} />
              
              <Route exact path="auction" element={<Auction />} />
              <Route exact path="tag" element={<Policy />} />
              <Route exact path="haircutting" element={<Haircutting />} />
              
            </Routes>
            </div>

            </>




  );
}

export default Orders;
