import React, { useEffect, useState } from "react";
import { zerodhaGetAuction } from "../../api/api";
import { Empty, Table } from "antd";
// import { motion } from "framer-motion";
function Auction() {
  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(false);
  useEffect(() => {
    
    getdata();
  }, []);
  let getdata = async () => {
    setloading(true)
    let data = await zerodhaGetAuction();
    data?.data?.forEach((element,i )=> {
      element.key=i
    });

    setdata(data?.data);
    setloading(false)

  };

  const columns = [
    {
      title: "Symbol",
      dataIndex: "tradingsymbol",
      sorter: (a, b) => a.tradingsymbol.localeCompare(b.tradingsymbol),
      render: (_, elm) => elm.tradingsymbol,
    },
    {
      title: "Qty",
      dataIndex: "quantity",
      sorter: (a, b) =>a.quantity-b.quantity ,
      render: (_, elm) => elm.quantity,
    },

    {
      title: "Ltp",
      dataIndex: "last_price",
      sorter: (a, b) =>a.last_price-b.last_price ,
      render: (_, elm) => elm.last_price,
    },
    {
      title: "Avg.",
      dataIndex: "average_price",
      sorter: (a, b) =>a.average_price-b.average_price ,
      render: (_, elm) => elm?.average_price?.toFixed(),
    },
    {
      title: "P&l",
      dataIndex: "pnl",
      sorter: (a, b) => a.pnl-b.pnl,
      render: (_, elm) => elm?.pnl?.toFixed(2),
    },
    {
      title: "Auction number",
      dataIndex: "auction_number",
      sorter: (a, b) =>a.auction_number-b.auction_number ,
      render: (_, elm) => elm?.auction_number,
    },
   
  ];





  return <div>

<h5 >Auction({data?.length})</h5>
  <div>
 {data?.length >0 ? <Table
              columns={columns}
           loading={loading}
              dataSource={data}
            /> : <Empty/>}
            </div>


  </div>;
}

export default Auction;
