import React, { useEffect } from 'react'
import useWebSocket from 'react-use-websocket';
import { APPKEY } from '../BaseURL';
// import { format } from "date-fns";
import { useSelector, useDispatch } from "react-redux";
import { websocketReceive } from '../../features/counterSlice';
import dateFormat from "dateformat";


let NseCD = 3,
  BseCD = 6,
  Indices = 9;

let triggers = {
  connect: [],
  ticks: [],
  disconnect: [],
  error: [],
  close: [],
  reconnect: [],
  noreconnect: [],
  message: [],
  order_update: [],
};
let modeFull = "full", // Full quote including market depth. 164 bytes.
  modeQuote = "quote", // Quote excluding market depth. 52 bytes.
  modeLTP = "ltp";


function WebsocketConnection() {
    const dispatch = useDispatch();
    const senddata = useSelector((state) => state.counter.websocket_send);
    // const socketUrl = `wss://ws.kite.trade?api_key=${APPKEY}&access_token=${localStorage.getItem(
    //     "accessdata"
    //   )}`;

    const socketUrl=JSON.parse(localStorage.getItem("websocket"))

    // console.log(socketUrl);
    // `wss://ws.zerodha.com/?api_key=kitefront&user_id=GMG829&enctoken=CfF5VHThB6UquX5JcZ0RFBRInsZZgupKR9Be9KREePhw32LBQ17DBFtj%2BF880BAcgeahV5Bexrk3k1r%2FYXNk0Js7rmKMH5%2F17s3xstuBr%2B8Aj5UcOajWYA%3D%3D&uid=1703147856062&user-agent=kite3-web&version=3.0.0`
    
    const { sendMessage, lastMessage, getWebSocket } = useWebSocket(socketUrl);

      useEffect(()=>{
        console.log(socketUrl);  
            setTimeout(() => {
                getWebSocket().binaryType = 'arraybuffer';
            }, 3000);
      },[])

      
useEffect(()=>{
setTimeout(() => {
    
   let  message = { a: "mode", v: ["quote", [265,256265]] };
   sendMessage(JSON.stringify(message));
}, 2000);
},[])

useEffect(()=>{
  
     sendMessage(JSON.stringify(senddata));
  
  },[senddata])




useEffect(() => {
    if (lastMessage !== null) {
      if (lastMessage?.data instanceof ArrayBuffer) {
        trigger("message", [lastMessage?.data]);

        if (lastMessage?.data?.byteLength > 2) {
          var d = parseBinary(lastMessage?.data);
          
          
            dispatch(websocketReceive(d));

            
        
          
        }
      } else {
        parseTextMessage(lastMessage?.data);
      }
    }
  }, [lastMessage]);




  function parseTextMessage(data) {
    try {
      data = JSON.parse(data);
    } catch (e) {
      return;
    }

    if (data.type === "order") {
      trigger("order_update", [data.data]);
    }
  }
  function trigger(e, args) {
    if (!triggers[e]) return;
    for (let n = 0; n < triggers[e]?.length; n++) {
      triggers[e][n].apply(triggers[e][n], args ? args : []);
    }
  }

  function splitPackets(bin) {
    // number of packets
    let num = buf2long(bin.slice(0, 2)),
      j = 2,
      packets = [];

    for (let i = 0; i < num; i++) {
      // first two bytes is the packet length
      let size = buf2long(bin.slice(j, j + 2)),
        packet = bin.slice(j + 2, j + 2 + size);

      packets.push(packet);

      j += 2 + size;
    }

    return packets;
  }

  function buf2long(buf) {
    let b = new Uint8Array(buf),
      val = 0,
      len = b?.length;

    for (let i = 0, j = len - 1; i < len; i++, j--) {
      val += b[j] << (i * 8);
    }

    return val;
  }

  function parseBinary(binpacks) {
    let packets = splitPackets(binpacks),
      ticks = [];

    for (let n = 0; n < packets?.length; n++) {
      let bin = packets[n],
        instrument_token = buf2long(bin.slice(0, 4)),
        segment = instrument_token & 0xff;

      let tradable = true;
      if (segment === Indices) tradable = false;

      // Add price divisor based on segment
      let divisor = 100.0;
      if (segment === NseCD) {
        divisor = 10000000.0;
      } else if (segment === BseCD) {
        divisor = 10000.0;
      }

      // Parse LTP
      if (bin.byteLength === 8) {
        ticks.push({
          tradable: tradable,
          mode: modeLTP,
          instrument_token: instrument_token,
          last_price: buf2long(bin.slice(4, 8)) / divisor,
        });
        // Parse indices quote and full mode
      } else if (bin.byteLength === 28 || bin.byteLength === 32) {
        let mode = modeQuote;
        if (bin.byteLength === 32) mode = modeFull;

        let tick = {
          tradable: tradable,
          mode: mode,
          instrument_token: instrument_token,
          last_price: buf2long(bin.slice(4, 8)) / divisor,
          ohlc: {
            high: buf2long(bin.slice(8, 12)) / divisor,
            low: buf2long(bin.slice(12, 16)) / divisor,
            open: buf2long(bin.slice(16, 20)) / divisor,
            close: buf2long(bin.slice(20, 24)) / divisor,
          },
          change: buf2long(bin.slice(24, 28)),
        };

        // Compute the change price using close price and last price
        if (tick.ohlc.close !== 0) {
          tick.change =
            ((tick.last_price - tick.ohlc.close) * 100) / tick.ohlc.close;
        }

        // Full mode with timestamp in seconds
        if (bin.byteLength === 32) {
          tick.exchange_timestamp = null;
          let timestamp = buf2long(bin.slice(28, 32));
          if (timestamp)
            tick.exchange_timestamp = 
            dateFormat(new Date(timestamp * 1000), "dd-mm-yyyy hh:mm:ss")
            // format(
            //   new Date(timestamp * 1000),
            //   "yyyy-MM-dd kk:mm:ss"
            // );
        }

        ticks.push(tick);
      } else if (bin.byteLength === 44 || bin.byteLength === 184) {
        let mode = modeQuote;
        if (bin.byteLength === 184) {
          mode = modeFull;
        }

        let tick = {
          tradable: tradable,
          mode: mode,
          instrument_token: instrument_token,
          last_price: buf2long(bin.slice(4, 8)) / divisor,
          last_traded_quantity: buf2long(bin.slice(8, 12)),
          average_traded_price: buf2long(bin.slice(12, 16)) / divisor,
          volume_traded: buf2long(bin.slice(16, 20)),
          total_buy_quantity: buf2long(bin.slice(20, 24)),
          total_sell_quantity: buf2long(bin.slice(24, 28)),
          ohlc: {
            open: buf2long(bin.slice(28, 32)) / divisor,
            high: buf2long(bin.slice(32, 36)) / divisor,
            low: buf2long(bin.slice(36, 40)) / divisor,
            close: buf2long(bin.slice(40, 44)) / divisor,
          },
        };
        // console.log(tick);
        // Compute the change price using close price and last price
        if (tick.ohlc.close !== 0) {
          tick.change =
            ((tick.last_price - tick.ohlc.close) * 100) / tick.ohlc.close;
        }

        // Parse full mode
        if (bin.byteLength === 184) {
          // Parse last trade time
          tick.last_trade_time = null;
          let last_trade_time = buf2long(bin.slice(44, 48));
          if (last_trade_time)
            tick.last_trade_time =
            dateFormat(new Date(last_trade_time * 1000), "dd-mm-yyyy hh:mm:ss")
            // format(
            //   new Date(last_trade_time * 1000),
            //   "yyyy-MM-dd kk:mm:ss"
            // );

          // Parse timestamp
          tick.exchange_timestamp = null;
          let timestamp = buf2long(bin.slice(60, 64));
          if (timestamp)
            tick.exchange_timestamp =
            dateFormat(new Date(timestamp * 1000), "dd-mm-yyyy hh:mm:ss")
          //  format(
          //     new Date(timestamp * 1000),
          //     "yyyy-MM-dd kk:mm:ss"
          //   );

          // Parse OI
          tick.oi = buf2long(bin.slice(48, 52));
          tick.oi_day_high = buf2long(bin.slice(52, 56));
          tick.oi_day_low = buf2long(bin.slice(56, 60));
          tick.depth = {
            buy: [],
            sell: [],
          };

          let s = 0,
            depth = bin.slice(64, 184);
          for (let i = 0; i < 10; i++) {
            s = i * 12;
            tick.depth[i < 5 ? "buy" : "sell"].push({
              quantity: buf2long(depth.slice(s, s + 4)),
              price: buf2long(depth.slice(s + 4, s + 8)) / divisor,
              orders: buf2long(depth.slice(s + 8, s + 10)),
            });
          }
        }

        ticks.push(tick);
      }
    }

    return ticks;
  }




  
}

export default WebsocketConnection
