import React, {  useState} from "react";




import { Button, DatePicker, Input, Tag } from "antd";

import { Table } from "antd";
import { tradebook } from "./api/api";
import dateFormat from "dateformat";

import { exportToExcel } from "../../libs/exportToExcel";
import {
  useQuery,

} from '@tanstack/react-query'

const { RangePicker } = DatePicker;

function Contrade() {
  
  
  const [query, setquery] = useState("");
  
  
  // const [value, setvalue] = useState({
  //   current: 0,
  //   invested: 0,
  //   pl: 0,
  // });
  // useEffect(() => {
    
  //   getdata();
  //   // policy_data()
  // }, []);

  // const policy_data=async()=>{
  //   let data=await getpolicy()
  //   setpolicydata(data?.data)
    
  // }



  // const getdata = async () => {
  //   let data = await tradebook();
  //   return data.data
  // };

  const {data,isLoading} = useQuery({ queryKey: ['tradebookfetch'], queryFn: tradebook })

  const tableColumns = [
    {
      title: "Symbol.",
      dataIndex: "tradingsymbol",
      sorter: (a, b) => a.tradingsymbol.localeCompare(b.tradingsymbol),
      render: (_, elm) => (
        <>
          <div className="d-flex justify-content-between align-items-end">
            <span>{elm.tradingsymbol}</span>
            <small className=" text-muted">{elm.exchange}</small>
          </div>
        </>
      ),
    },
    {
      title: "Trade Date",
      dataIndex: "order_timestamp",
      sorter: (a, b) => new Date(a.order_execution_time) - new Date(b.order_execution_time),
      render: (_, elm) => dateFormat(elm?.order_execution_time, "dd-mm-yyyy"),
    },
    {
      title: "Order id.",
      dataIndex: "order_id",
      sorter: (a, b) => a.order_id - b.order_id,
    },
    {
      title: "Trade id.",
      dataIndex: "trade_id",
      sorter: (a, b) => a.trade_id - b.trade_id,

    },

    {
      title: "Type.",
      dataIndex: "transaction_type",
      sorter: (a, b) => a.transaction_type - b.transaction_type,
      render: (_, elm) => (
         <Tag color={elm.transaction_type=='buy'?'green':'red'}>{elm.transaction_type?.toUpperCase()}</Tag>
      ),
    },
    {
      title: "Qty.",
      dataIndex: "quantity",
      sorter: (a, b) => a.quantity - b.quantity,
    },
    {
      title: "Price",
      dataIndex: "average_price",
      sorter: (a, b) => a.average_price - b.average_price,
      render: (_, elm) => elm.average_price?.toFixed(2)
    },
    
    {
      title: "Policy",
      dataIndex: "policy",
      sorter: (a, b) => a.policy.localeCompare(b.policy),
    },
    
  ];


  
  return (
    <div className="">
      <div className="py-4 border-bottom ">
        <h5 >
          <span className="me-2">
            <i className="bi bi-clipboard-check"></i>
          </span>
          Tradebook
        </h5>
      </div>

      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center" >
        <div>
          <div className="d-flex position-relative">
            
            <Input placeholder="Symbol" onChange={(e)=>setquery(e.target.value)}/>
          </div>
        </div>
        <div>
          
           {/* <Select
           className="mx-2"
      defaultValue={policydata?.[0]?.policy_name}
      style={{
        width: 120,
      }}
      onChange={(e)=>filterpolicy(e.target.value)}
      options={policydata?.map((ele)=>{
        return  {
          value: ele.policy_name,
          label: ele.policy_name,
        }
      })}
    /> */}
        </div>
        <div className="my-3">
          <RangePicker  onChange={(val) => {
        console.log(val);
      }} />
        </div>
        <div className="ms-2">
          
          <Button type="primary"><i className=" bi bi-arrow-right "></i></Button>
        </div>
</div>

<div>

                      <Button onClick={()=>exportToExcel(data)} type="primary">Download</Button>
</div>


      </div>
      
        <div>
          <Table columns={tableColumns} loading={isLoading  } bordered dataSource={data?.data} />
        </div>
      

      
    </div>
  );
}

export default Contrade;
