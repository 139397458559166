import { BASEURL } from "../BaseURL";



const userdataPost = async () => {
  const myHeaders = new Headers();
  const token = localStorage.getItem("requesttoken");

  let accessdata = localStorage.getItem("accessdata");
  let value = JSON.parse(JSON.parse(localStorage.getItem("login_status")));
  const zhcookie=JSON.parse(localStorage.getItem("zhcookie"))
  const zAuthorixation=JSON.parse(localStorage.getItem("zAuthorixation"))

  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", token);
  const getdata = await fetch(BASEURL + "getuserdata", {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({
      accessToken: accessdata,
      value: value,
      broker: localStorage.getItem("broker"),
      zhcookie,
      zAuthorixation

    }),
  });

  let data = await getdata.json();
  return data;
};

//########################## positions ######################################

const positionsPost = async () => {
  const myHeaders = new Headers();
  const token = localStorage.getItem("requesttoken");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", token);
  // let value = JSON.parse(localStorage.getItem("login_status"));
  // let accessdata = localStorage.getItem("accessdata");
  const zhcookie=JSON.parse(localStorage.getItem("zhcookie"))
  const zAuthorixation=JSON.parse(localStorage.getItem("zAuthorixation"))

  const getdata = await fetch(BASEURL + "get-positions", {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({
      // accessToken: accessdata,
      // value: value,
      zhcookie,zAuthorixation,
      broker: localStorage.getItem("broker"),
    }),
  });

  const data = await getdata.json();
  return data;
};

//###########################  ORDERS ###########################


//###############################  profile ##################################
const getprofilePost = async () => {
  const myHeaders = new Headers();
  const token = localStorage.getItem("requesttoken");

  let value = JSON.parse(localStorage.getItem("login_status"));

  let accessdata = localStorage.getItem("accessdata");
  const zhcookie=JSON.parse(localStorage.getItem("zhcookie"))
  const zAuthorixation=JSON.parse(localStorage.getItem("zAuthorixation"))

  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", token);
  const getdata = await fetch(BASEURL + "getprofiledata", {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({
      accessToken: accessdata,
      value: value,
      broker: localStorage.getItem("broker"),
      zhcookie,zAuthorixation
      
    }),
  });

  const data = await getdata.json();
  return data;
};

//####################################   market overview #############################

const getMarketoverview = async () => {
  let value = JSON.parse(localStorage.getItem("login_status"));
  if (value === true) {
    const myHeaders = new Headers();

    const token = localStorage.getItem("requesttoken");

    let accessdata = localStorage.getItem("accessdata");

    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);
    const getdata = await fetch(BASEURL + "market-overview", {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        accessToken: accessdata,
        value: value,
      }),
    });

    const data = await getdata.json();
    return data;
  } else {
    return [{ data: [] }];
  }
};


const zerodhaGetBulletin = async () => {
  try {
    

    const myHeaders = new Headers();
    const token = localStorage.getItem("requesttoken");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);
    const zAuthorixation=JSON.parse(localStorage.getItem('zAuthorixation'))
    const zhcookie=JSON.parse(localStorage.getItem('zhcookie'))
    myHeaders.append("zerodahLiveCookie", zhcookie);
    myHeaders.append("zerodhaLiveToken", zAuthorixation);
    

    const getdata = await fetch(BASEURL + "bulletin", {
      method: "GET",
      headers: myHeaders,
    });

    const data = await getdata.json();
    
    return data;
  } catch (error) {
    console.log(error);
  }
};



//##################### gtt post and delete #############################
//get gtt data


//delete
const deletegttPost = async (trigger_id) => {
  const myHeaders = new Headers();
  const token = localStorage.getItem("requesttoken");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", token);

  let accessdata = localStorage.getItem("accessdata");

  const getdata = await fetch(BASEURL + "place-gtt", {
    method: "DELETE",
    headers: myHeaders,
    body: JSON.stringify({
      accessToken: accessdata,
      trigger_id: trigger_id,
    }),
  });

  const data = await getdata.json();
  return data;
};

// create new gtt
const newgttMarketPost = async (send) => {
  const myHeaders = new Headers();
  const token = localStorage.getItem("requesttoken");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", token);

  let accessdata = localStorage.getItem("accessdata");

  const getdata = await fetch(BASEURL + "place-gtt", {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({
      accessToken: accessdata,
      send,
    }),
  });

  const data = await getdata.json();
  return data;
};

//modify gtt

const modifygttMarketPost = async (send, trigger_id) => {
  const myHeaders = new Headers();
  const token = localStorage.getItem("requesttoken");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", token);

  let accessdata = localStorage.getItem("accessdata");

  const getdata = await fetch(BASEURL + "place-gtt", {
    method: "PUT",
    headers: myHeaders,
    body: JSON.stringify({
      accessToken: accessdata,
      send,
      trigger_id,
    }),
  });

  const data = await getdata.json();
  return data;
};

//############## market watch ##########################

// add new market watch


//delete market watch
const deleteMarket= async(parentId,childId)=>{
  const myHeaders = new Headers();
  const token = localStorage.getItem("requesttoken");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", token);
  let cookie=JSON.parse(localStorage.getItem('zhcookie'))
  const z_public_token=JSON.parse(localStorage.getItem("z_public_token"))
  myHeaders.append("zhcookie", cookie);
  myHeaders.append("token", z_public_token);

  const getdata = await fetch(BASEURL + "addmarketwatch", {
    method: "DELETE",
    headers: myHeaders,
    body: JSON.stringify({
      parentId,childId
,
      // broker: localStorage.getItem("broker"),
    }),
  });
  const data = await getdata.json();
  return data;
}

//get market watch

const getMarketwatchdata = async () => {
  
  const myHeaders = new Headers();
  const token = localStorage.getItem("requesttoken");
  const broker = localStorage.getItem("broker");
  let z_public_token=JSON.parse(localStorage.getItem('z_public_token'))
  const zhcookie=JSON.parse(localStorage.getItem("zhcookie"))

  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", token);
  myHeaders.append("zAuthorization", z_public_token);
  myHeaders.append("zcookie",zhcookie);
  
  
  const getdata = await fetch(BASEURL + `addmarketwatch`, {
    method: "GET",
    headers: myHeaders,
    broker: broker,
  });

  const data = await getdata?.json();
  return data
}

const addMarketwatchdata = async (tradingsymbol,exchange,watchId) => {
  
  const myHeaders = new Headers();
  const token = localStorage.getItem("requesttoken");
  const broker = localStorage.getItem("broker");
  let z_public_token=JSON.parse(localStorage.getItem('z_public_token'))
  const zhcookie=JSON.parse(localStorage.getItem("zhcookie"))

  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", token);
  myHeaders.append("zAuthorization", z_public_token);
  myHeaders.append("zcookie",zhcookie);
  
  
  const getdata = await fetch(BASEURL + `addmarketwatch`, {
    method: "POST",
    headers: myHeaders,
    broker: broker,
    body: JSON.stringify({
      tradingsymbol,exchange,watchId
    }),
  });

  const data2 = await getdata?.json();
  return data2
}

const instrumentsearchPost = async (e) => {
  const myHeaders = new Headers();
  const token = localStorage.getItem("requesttoken");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", token);
  const getdata = await fetch(BASEURL + `get-Instruments?name=${e}`, {
    method: "GET",
    headers: myHeaders,
    
  });

  const data = await getdata.json();

  return data;
};

/// ###################  alert #####################

const addalertPost = async (values) => {
  const myHeaders = new Headers();
  const token = localStorage.getItem("requesttoken");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", token);
  const getdata = await fetch(BASEURL + "alert", {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({
      values,
    }),
  });
  const data = await getdata.json();

  return data;

};


// delete order

const deleteorderPost = async (id,variety) => {
  try {
    

    const myHeaders = new Headers();
    const token = localStorage.getItem("requesttoken");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);
    let accessdata = localStorage.getItem("accessdata");

    const getdata = await fetch(BASEURL + "place-order", {
      method: "DELETE",
      headers: myHeaders,
      body: JSON.stringify({
       id,
       variety,
        accessToken: accessdata,
      }),
    });

    const data = await getdata.json();
    
    return data;
  } catch (error) {
    console.log(error);
  }
};

// modify order

const ltpPost = async (tradingsymbol, exchange,array) => {
  const myHeaders = new Headers();
  const token = localStorage.getItem("requesttoken");

  let accessdata = localStorage.getItem("accessdata");

  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", token);
  const getdata = await fetch(BASEURL + "get-ltp", {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({
      tradingsymbol: tradingsymbol,
      exchange: exchange,
      accesstoken: accessdata,
      array,
      broker: localStorage.getItem("broker"),
    }),
  });

  const data = await getdata.json();
  return data;
};





//policy

//get

// policy name

const getpolicy_name = async () => {
  const myHeaders = new Headers();
  const token = localStorage.getItem("requesttoken");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", token);
  

  const getdata = await fetch(BASEURL + `policy-name`, {
    method: "GET",
    headers: myHeaders,
  });

  let data = await getdata.json();
  return data;
};
// policy reference


//detele


//modify

// const modifypolicy = async (quantity,trade_id,policy,split,original,id,policyfrom,tradingsymbol,exchange,date,price) => {
//   const myHeaders = new Headers();
//   const token = localStorage.getItem("requesttoken");
//   myHeaders.append("Content-Type", "application/json");
//   myHeaders.append("Authorization", token);

//   const getdata = await fetch(BASEURL + "policy", {
//     method: "PUT",
//     headers: myHeaders,
//     body: JSON.stringify({
//       quantity,trade_id,policy,split,original,policyfrom,id,tradingsymbol,exchange,date,price,
//       broker: localStorage.getItem("broker"),
//     }),
//   });
//   let data = await getdata.json();
//   return data;
// };

// insert new policy

const newpolicy = async (name) => {
  const myHeaders = new Headers();
  const token = localStorage.getItem("requesttoken");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", token);

  const getdata = await fetch(BASEURL + "policy", {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({
      name: name,
    }),
  });
  let data = await getdata.json();
  return data;
};




///############### basket ################

//post insert new basket

const basketPost = async (send, name,variety,policy) => {
  const myHeaders = new Headers();
  const token = localStorage.getItem("requesttoken");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", token);
  const getdata = await fetch(BASEURL + "basket", {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({
      list: send,
      name: name,
      broker: localStorage.getItem("broker"),
      variety,

      policy
    }),
  });

  const data = await getdata.json();
  return data;
};

///get request for basket

const basketGet = async (name) => {
  const myHeaders = new Headers();
  const token = localStorage.getItem("requesttoken");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", token);
  const getdata = await fetch(BASEURL + `basket?name=${name}`, {
    method: "GET",
    headers: myHeaders,
  });

  const data = await getdata.json();
  return data;
};

///Delete request for basket

const basketDelete = async (name) => {
  const myHeaders = new Headers();
  const token = localStorage.getItem("requesttoken");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", token);
  const getdata = await fetch(BASEURL + "basket", {
    method: "DELETE",
    headers: myHeaders,
    body: JSON.stringify({
      name: name,
      broker: localStorage.getItem("broker"),
    }),
  });

  const data = await getdata.json();
  return data;
};

///place basket order

const placeBasket = async (name) => {
  const myHeaders = new Headers();
  const token = localStorage.getItem("requesttoken");
  let accessToken = localStorage.getItem("accessdata");
  const zhcookie=JSON.parse(localStorage.getItem("zhcookie"))
  const zAuthorixation=JSON.parse(localStorage.getItem("zAuthorixation"))
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", token);
  const getdata = await fetch(BASEURL + "place-basket", {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({
      name: name,
      broker: localStorage.getItem("broker"),
      accessToken,
      zhcookie,
      zAuthorixation
    }),
  });

  const data = await getdata.json();
  return data;
};

///bulletin



// notification api


/// alert notification api 
 











const uploadPolicyToZerodah=async(array)=>{
  const myHeaders = new Headers();
  const token = localStorage.getItem("requesttoken");
  
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", token);
  const cookie=JSON.parse(localStorage.getItem("hcookie"))
  const zXrftoken=JSON.parse(localStorage.getItem("z_public_token"))
  myHeaders.append("hcookie", cookie);
  myHeaders.append("zXrftoken", zXrftoken);
  const getdata = await fetch(BASEURL + `upload-tag-zerodah`, {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({
      data:array
    }),
  
  });
  let data=getdata.json()
  return data
}




const getunofficialholding=async()=>{
  const myHeaders = new Headers();
  const token = localStorage.getItem("requesttoken");
  const zhcookie=JSON.parse(localStorage.getItem("zhcookie"))
  const zAuthorixation=JSON.parse(localStorage.getItem("zAuthorixation"))
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", token);
  const getdata = await fetch(BASEURL + `holdings`, {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({
      zhcookie,zAuthorixation
      
    }),
    
  });
  let data=getdata.json()
  return data
}

const getunofficialtrades=async()=>{
  const myHeaders = new Headers();
  const token = localStorage.getItem("requesttoken");
  const zhcookie=JSON.parse(localStorage.getItem("zhcookie"))
  const zAuthorixation=JSON.parse(localStorage.getItem("zAuthorixation"))
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", token);
  const getdata = await fetch(BASEURL + `trades`, {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({
      zhcookie,zAuthorixation
      
    }),
    
  });
  let data=getdata.json()
  return data
}


const updatetradebook=async()=>{
  const myHeaders = new Headers();
  const token = localStorage.getItem("requesttoken");
  const zhcookie=JSON.parse(localStorage.getItem("zhcookie"))
  const zAuthorixation=JSON.parse(localStorage.getItem("zAuthorixation"))
  const cookie=JSON.parse(localStorage.getItem("hcookie"))
  const zXrftoken=JSON.parse(localStorage.getItem("z_public_token"))
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", token);
  const getdata = await fetch(BASEURL + `update-tradebook`, {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({
      zhcookie,zAuthorixation,cookie,zXrftoken
      
    }),
    
  });
  let data=getdata.json()
  return data
}













// #################################### ZERODHA LIVE API #############################################
const zerodhaMargin=async(values)=>{
  const myHeaders = new Headers();
  const token = localStorage.getItem("requesttoken");
  let zAuthorixation=JSON.parse(localStorage.getItem('zAuthorixation'))
  let zhcookie=JSON.parse(localStorage.getItem('zhcookie'))
  myHeaders.append("zerodahLiveCookie", zhcookie);
  myHeaders.append("zerodhaLiveToken", zAuthorixation);
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", token);
  const getdata = await fetch(BASEURL + `zerodah-margin`, {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({
      values
    }),
  });
  let data=getdata.json()
  return data
}

const zerodhaNudge=async(values)=>{
  const myHeaders = new Headers();
  const token = localStorage.getItem("requesttoken");
  let zAuthorixation=JSON.parse(localStorage.getItem('zAuthorixation'))
  let zhcookie=JSON.parse(localStorage.getItem('zhcookie'))
  myHeaders.append("zerodahLiveCookie", zhcookie);
  myHeaders.append("zerodhaLiveToken", zAuthorixation);

  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", token);
  const getdata = await fetch(BASEURL + `nudge`, {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({
      values
    }),
    
  });
  let data=getdata.json()
  return data
}


const zerodhaPlaceOrder = async (values) => {
  try {
    

    const myHeaders = new Headers();
    const token = localStorage.getItem("requesttoken");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);
    const zAuthorixation=JSON.parse(localStorage.getItem('zAuthorixation'))
    const zhcookie=JSON.parse(localStorage.getItem('zhcookie'))
    myHeaders.append("zerodahLiveCookie", zhcookie);
    myHeaders.append("zerodhaLiveToken", zAuthorixation);
    

    const getdata = await fetch(BASEURL + "place-order", {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        values
      }),
    });

    const data = await getdata.json();
    
    return data;
  } catch (error) {
    console.log(error);
  }
};

const zerodhaOrder = async () => {
  try {
    

    const myHeaders = new Headers();
    const token = localStorage.getItem("requesttoken");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);
    const zAuthorixation=JSON.parse(localStorage.getItem('zAuthorixation'))
    const zhcookie=JSON.parse(localStorage.getItem('zhcookie'))
    myHeaders.append("zerodahLiveCookie", zhcookie);
    myHeaders.append("zerodhaLiveToken", zAuthorixation);
    

    const getdata = await fetch(BASEURL + "orders", {
      method: "GET",
      headers: myHeaders,
    });

    const data = await getdata.json();
    
    return data;
  } catch (error) {
    console.log(error);
  }
};

const contractNote = async (values) => {
  try {
    

    const myHeaders = new Headers();
    const token = localStorage.getItem("requesttoken");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);
    const zAuthorixation=JSON.parse(localStorage.getItem('zAuthorixation'))
    const zhcookie=JSON.parse(localStorage.getItem('zhcookie'))
    myHeaders.append("zerodahLiveCookie", zhcookie);
    myHeaders.append("zerodhaLiveToken", zAuthorixation);
    

    const getdata = await fetch(BASEURL + "contractnote", {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        values
      }),
    });

    const data = await getdata.json();
    
    return data;
  } catch (error) {
    console.log(error);
  }
};
const zerodhaGetGtt = async () => {
  try {
    

    const myHeaders = new Headers();
    const token = localStorage.getItem("requesttoken");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);
    const zAuthorixation=JSON.parse(localStorage.getItem('zAuthorixation'))
    const zhcookie=JSON.parse(localStorage.getItem('zhcookie'))
    myHeaders.append("zerodahLiveCookie", zhcookie);
    myHeaders.append("zerodhaLiveToken", zAuthorixation);
    

    const getdata = await fetch(BASEURL + "zerodah-get-gtt", {
      method: "GET",
      headers: myHeaders,
    });

    const data = await getdata.json();
    
    return data;
  } catch (error) {
    console.log(error);
  }
};


const zerodhaGetAuction = async () => {
  try {
    

    const myHeaders = new Headers();
    const token = localStorage.getItem("requesttoken");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);
    const zAuthorixation=JSON.parse(localStorage.getItem('zAuthorixation'))
    const zhcookie=JSON.parse(localStorage.getItem('zhcookie'))
    myHeaders.append("zerodahLiveCookie", zhcookie);
    myHeaders.append("zerodhaLiveToken", zAuthorixation);
    

    const getdata = await fetch(BASEURL + "auction", {
      method: "GET",
      headers: myHeaders,
    });

    const data = await getdata.json();
    
    return data;
  } catch (error) {
    console.log(error);
  }
};




// ######################### ZERODHA CONSOLE API ##################################

const getupdateunofficiabreakdown=async(id)=>{
  const myHeaders = new Headers();
  const token = localStorage.getItem("requesttoken");
  let z_public_token=JSON.parse(localStorage.getItem('z_public_token'))
  let hcookie=JSON.parse(localStorage.getItem('hcookie'))
  myHeaders.append("consoleToken", z_public_token);
  myHeaders.append("consoleCookie", hcookie);

  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", token);
  const getdata = await fetch(BASEURL + `console-break-update`, {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({
      
      z_public_token,
      hcookie
    }),
  });
  let data=getdata.json()
  return data
}

const getConsoleBreakdown=async(id)=>{
  const myHeaders = new Headers();
  const token = localStorage.getItem("requesttoken");
  
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", token);
  const getdata = await fetch(BASEURL + `console-breakdown`, {
    method: "GET",
    headers: myHeaders,
    
  });
  let data=getdata.json()
  return data
}

// temporary
const saveWsToDb=async(value)=>{
  const myHeaders = new Headers();
  const token = localStorage.getItem("requesttoken");
  
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", token);
  const getdata = await fetch(BASEURL + `save-ws-db`, {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({
      value
    }),
    
  });
  let data=getdata.json()
  return data
}






export {
  
  userdataPost,
  positionsPost,
  
  getprofilePost,
  getMarketoverview,
  
  
  deletegttPost,
  
  instrumentsearchPost,
  addalertPost,
  // placeorderPost,

  ltpPost,
  
  newpolicy,
  newgttMarketPost,
  modifygttMarketPost,
  basketPost,
  basketGet,
  basketDelete,
  placeBasket,
  deleteMarket,
  getMarketwatchdata,
  deleteorderPost,
  
  uploadPolicyToZerodah,
  getunofficialholding,
  
  getupdateunofficiabreakdown,
  getunofficialtrades,
  
  updatetradebook,
  
  getpolicy_name,
  // this is temporary api for saving ws url for fetching morning indices

  saveWsToDb,


  zerodhaMargin,
  zerodhaNudge,
  zerodhaOrder,
  zerodhaPlaceOrder,
  contractNote,
  getConsoleBreakdown,
  zerodhaGetGtt,
  zerodhaGetAuction,
  addMarketwatchdata,
  zerodhaGetBulletin
};
