import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";


import Mainhome from "./zerodha/Mainhome";

import Home2 from "./Home/Home2";

import Dashboard from "./zerodha/console/Dashboard";

import Getaccesstoken from "./zerodha/routes/Getaccesstoken";

import GetaccesstokenUpstox from "./upstox/Getaccesstoken_upstox";
import MainHomeUpstox from "./upstox/MainHome_upstox";
// import Morning from "./componunts/tempComponent/Morning";
import Company from "./Home/Company";
import Getaccesstoken_fyres from "./fyers/Getaccesstoken_fyers";
import Morning from "./zerodha/Morning";

function App() {
  
  


  return (
    <>
      <div>
        <div className="position-absolute"></div>
        <Router>
          <Routes>
            <Route exact path="/*" element={<Mainhome />} />
            <Route exact path="/home/*" element={<Home2 />} />
            <Route exact path="/console/*" element={<Dashboard />} />
            <Route exact path="/morning/*" element={<Morning />} />
            <Route exact path="/company/:id" element={<Company/>} />
            <Route exact path="/getaccesstoken/" element={<Getaccesstoken />} />
            <Route
              path="/getaccesstoken-fyers/"
              // eslint-disable-next-line react/jsx-pascal-case
              element={<Getaccesstoken_fyres/>}
            />

            <Route
              exact
              path="/getaccesstoken-upstox/"
              element={<GetaccesstokenUpstox />}
            />
            <Route exact path="/upstox/*" element={<MainHomeUpstox />} />
          </Routes>
        </Router>
      </div>
    </>
  );
}

export default App;
