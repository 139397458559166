import React, { useEffect, useState } from "react";
import { holdingSummary, holdingSummaryBreakdown } from "./api/api";
import { Table } from "antd";
import groupBy from "lodash/groupBy";
import forOwn from "lodash/forOwn";

import * as xlsx from "xlsx";

import ComparisonChart from "./ComparisionChart";

function HoldingSummary() {
  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(false);
  const [tabledata, settabledata] = useState([]);
  // const [holding, setholding] = useState([]);
  // const [trades, settrades] = useState([]);

  const fetchdata = async () => {
    setloading(true)
    let data = await holdingSummary();
    settabledata(data.data)
    
    let isinGroup =groupBy(data?.data, "date");

    const final=[]

    forOwn(isinGroup, function (value, key) {
      const current=value.map((j)=>j.quantity*j.ltp).reduce((a,b)=>a+b,0)
      const invested=value.map((j)=>j.quantity*j.average_price).reduce((a,b)=>a+b,0)
      const obj={
        key,
        date:key,
        total_Openqty:value.map((j)=>j.quantity).reduce((a,b)=>a+b,0),
        invested,
        current,
        quantity:value.map((j)=>j.deduct_quantity).reduce((a,b)=>a+b,0),
        realised:value.map((j)=>j.realised).reduce((a,b)=>a+b,0),
        unrealised:value.map((j)=>j.quantity*(j.ltp-j.average_price)).reduce((a,b)=>a+b,0)
      }

      final.push(obj)

    })

    
    


    
    setdata(final);
    
    setloading(false)
  };

  useEffect(() => {
    fetchdata();
  }, []);

  const tableColumns = [
    {
      title: "Symbol",
      dataIndex: "tradingsymbol",
      sorter: (a, b) => a.tradingsymbol.localeCompare(b.tradingsymbol),
    },
    {
      title: "ISIN",
      dataIndex: "isin",
      sorter: (a, b) => a.isin.localeCompare(b.isin),
    },
    
    {
      title: "Qty",
      dataIndex: "quantity",
      sorter: (a, b) => a.quantity - b.quantity,
      // render: (_, elm) => elm.ltp?.toLocaleString('en-IN'),
    },

    {
      title: "Price",
      dataIndex: "average_price",
      sorter: (a, b) => a.average_price - b.average_price,
      render: (_, elm) => elm.average_price?.toLocaleString("en-IN"),
    },
    {
      title: "Ltp",
      dataIndex: "ltp",
      sorter: (a, b) => a.ltp - b.ltp,
      render: (_, elm) => elm.ltp?.toLocaleString("en-IN"),
    },
    

    {
      title: "Buy value",
      dataIndex: "buyValue",
      sorter: (a, b) => a.buyValue - b.buyValue,
      render: (_, elm) => elm.buyValue?.toLocaleString("en-IN"),
    },
    {
      title: "Sell Value",
      dataIndex: "sellValue",
      sorter: (a, b) => a.sellValue - b.sellValue,
      render: (_, elm) => elm.sellValue?.toLocaleString("en-IN"),
    },
    
    {
      title: "Realised",
      dataIndex: "realised",
      sorter: (a, b) => a.realised - b.realised,
      render: (_, elm) => (
        <div
          className={
            Number(elm.realised) > 0
              ? "fw-bold text-success"
              : Number(elm.realised) < 0
              ? "fw-bold text-danger"
              : ""
          }
        >
          {elm.realised?.toLocaleString("en-IN")}
        </div>
      ),
    },
    {
      title: "Openqty",
      dataIndex: "total_Openqty",
      sorter: (a, b) => a.total_Openqty - b.total_Openqty,
    },
    {
      title: "Open invested",
      dataIndex: "Open_invested",
      sorter: (a, b) => (a.total_Openqty*a.average_price) - (b.total_Openqty*b.average_price),
      render: (_, elm) => (elm.total_Openqty*elm.average_price).toFixed(2)

    },
    {
      title: "Open current",
      dataIndex: "Open_current",
      sorter: (a, b) => (a.total_Openqty*a.ltp) - (b.total_Openqty*b.ltp),
      render: (_, elm) => (elm.total_Openqty*elm.ltp).toFixed(2)

    },
    {
      title: "Unrealised",
      dataIndex: "unrealised",
      sorter: (a, b) => a.unrealised - b.unrealised,
   
    },

    {
      title: "Policy",
      dataIndex: "policy",
      // sorter: (a, b) => a.ltp - b.ltp,
      // render: (_, elm) => dateFormat(elm.ltpdate, "dd-mm-yyyy"),
    },
  ];


  const expandedRowRender=  (b)=>{
    const holding2 = tabledata.filter((a)=>a.date== b.date)
    

    return (

       <Table
          dataSource={holding2}
          columns={tableColumns}
          pagination={false}
        />
      
        
    );
  }



  const tableColumns2 = [
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => a.date.localeCompare(b.date),
      // render: (_, elm) => dateFormat(elm.date, "dd-mm-yyyy"),
    },
    {
      title: "total_Openqty",
      dataIndex: "total_Openqty",
    },

    {
      title: "Invested",
      dataIndex: "invested",
      sorter: (a, b) => a.invested - b.invested,
      render: (_, elm) => Number(elm.invested)?.toLocaleString("en-IN"),
    },
    {
      title: "Current",
      dataIndex: "current",
      sorter: (a, b) => a.current - b.current,
      render: (_, elm) => Number(elm.current)?.toLocaleString("en-IN"),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
    },
    {
      title: "Realised",
      dataIndex: "realised",
      sorter: (a, b) => a.realised - b.realised,
      
    },
    {
      title: "Unrealise",
      dataIndex: "unrealised",
      sorter: (a, b) => a.unrealised - b.unrealised,
     
    },
  ];

  const downloadExcel = async () => {
    const ws = xlsx.utils.aoa_to_sheet([[]]);
    data.forEach((a)=>delete a.key)
    xlsx.utils.sheet_add_json(ws, data, {
      dateNF: "dd-mm-yyyy",
      cellDates: true,
    });
    const wb = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, "Data");

    xlsx.writeFile(wb, "HoldingSummary.xlsx");
  };

  return (
    <div className="my-5">
      {loading ?<div className="d-flex align-items-center justify-content-between">
        <img src="https://loading.io/icon/cdogg5" alt="loading"/>
      </div>:
      <>
      <div>
        {data?.length > 0 && (
          <div>
            <ComparisonChart data={data} />
          </div>
        )}
      </div>
      <div>
        <button onClick={downloadExcel}>Download</button>
      </div>
      <Table
        dataSource={data}
        expandable={{
          expandedRowRender,
          
        }}
        columns={tableColumns2}
        
        sticky={{
          offsetHeader: 55,
        }}
        pagination={false}
      />
      </>
      }
    </div>
  );
}

export default HoldingSummary;
