import React, { useEffect, useState } from 'react'
import { getpolicy_name } from '../../api/api';
import { Table, Tag } from 'antd';

function Policy() {
  const[data,setdata]=useState([])
  const[loading,setloading]=useState(false)
  useEffect(()=>{
    getpolicy2()
  },[])

  const columns=[
    {
      title: "Tag Id",
      dataIndex: "tag_id",
    },
    {
      title: "Tag name",
      dataIndex: "tag_name",
    },
    {
      title: "Category",
      dataIndex: "category",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Description",
      dataIndex: "description",
    },
    {
      title: "Colour",
      dataIndex: "colour",
      render: (_, elm) => <span ><Tag style={{width:"25px",height:"25px"}} color={elm.colour}></Tag></span>
    }
    
  ]

  const getpolicy2=async()=>{
    setloading(true)
    let data = await getpolicy_name();
   
    setdata(data.data)
    setloading(false)
  }
  return (
    <div>
      <div className='d-flex justify-content-between'>
        <h4 >Tag ({data?.length})</h4>
      <div><button type="button" class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#newpolicy">
  New Policy
</button>
</div>
      </div>
      <div>

      <Table loading={loading} columns={columns} dataSource={data} />

      </div>
    </div>
  )
}

export default Policy
