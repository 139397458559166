import React, { useState, useEffect } from "react";
import { Button, notification } from "antd";
import { PieChartFilled, CopyOutlined } from "@ant-design/icons";
import { userdataPost } from "../api/api";
import {
  useQuery,
  
} from '@tanstack/react-query'


function Funds() {
  

  const getuserdata = async () => {
    
    const data = await userdataPost();
    
    return data?.data?.equity
    
  };

  const {data,isLoading:loading} = useQuery({ queryKey: ['funds'], queryFn: getuserdata})

  
  const onopen = () => {
    window.open(
      `https://cashier.zerodha.com/?type=login`,
      "_blank",
      "location=yes,height=750,width=700,directories=no,titlebar=no,toolbar=no,location=center,status=no,menubar=no,scrollbars=no,resizable=no,"
    );
  };
  return (
    <>
  
      <div
      className="p-3 ">
        <div className="p-1 m-1 d-flex justify-content-end ">
          <Button  onClick={onopen} type="primary">
            Add Funds
          </Button>
          <Button className="ms-2" type="primary" danger>
          <a
            target="_blank"
            href="https://console.zerodha.com/funds/overview/?src=kiteweb"
          >
            Withdraw
          </a>
          </Button>
        </div>
        {loading? (
          <div
            className="w-100 d-flex justify-content-center align-items-center "
            // style={{ height: "80vh" }}
          >
            <div className="spinner-border " role="status"></div>
          </div>
        ):
      <div   className="row mb-5">
          <div className="col-6 ">
            <div className="p-3">
              <h4 className="  ">
                <PieChartFilled /> Equity
              </h4>
            </div>
            <div>
              <div className=" p-3 border shadow-sm">
                <div className="d-flex  justify-content-between  my-3 ">
                  <span className=" text-muted  " >Available margin</span>
                  <h4 className="mx-5  text-primary  ">
                    {data?.net?.toFixed(2)}
                  </h4>
                </div>

                <div className="d-flex  justify-content-between  my-3 ">
                  <span className="text-muted ">Used margin </span>
                  <h4 className="mx-5  ">
                    {data?.utilised?.debits?.toFixed(2)}
                  </h4>
                </div>

                <div className="d-flex  justify-content-between  mt-3 pb-3 border-bottom ">
                  <span className="text-muted  ">Available cash </span>
                  <h4 className="mx-5  ">
                    {data?.available?.live_balance?.toFixed(2)}
                  </h4>
                </div>

                <div className="d-flex  justify-content-between  my-3 ">
                  <span className="text-muted">Opening balance </span>
                  <span className="  mx-5  ">
                    {data?.available?.opening_balance?.toFixed(2)}
                  </span>
                </div>

                <div className="d-flex  justify-content-between  my-3 ">
                  <span className="text-muted">Payin</span>
                  <span className=" mx-5 ">
                    {data?.available?.intraday_payin?.toFixed(2)}
                  </span>
                </div>

                <div className="d-flex  justify-content-between  my-3 ">
                  <span className="text-muted">Payout</span>
                  <span className=" mx-5 ">
                    {data?.utilised?.payout?.toFixed(2)}
                  </span>
                </div>

                <div className="d-flex  justify-content-between  my-3 ">
                  <span className="text-muted">SPAN</span>
                  <span className=" mx-5 ">
                    {data?.utilised?.span?.toFixed(2)}
                  </span>
                </div>

                <div className="d-flex  justify-content-between  my-3 ">
                  <span className="text-muted">Delivery margin </span>
                  <span className=" mx-5 ">
                    {data?.utilised?.delivery?.toFixed(2)}
                  </span>
                </div>

                <div className="d-flex  justify-content-between  my-3 ">
                  <span className="text-muted">Exposure </span>
                  <span className="  mx-5">
                    {data?.utilised?.exposure?.toFixed(2)}
                  </span>
                </div>

                <div className="d-flex  justify-content-between  my-3 ">
                  <span className="text-muted">Options premium</span>
                  <span className="  mx-5 ">
                    {data?.utilised?.option_premium?.toFixed(2)}
                  </span>
                </div>

                <div className="d-flex  justify-content-between  my-3 ">
                  <span className="text-muted">Collateral (Liquid funds) </span>
                  <span className=" mx-5 ">
                    {data?.utilised?.liquid_collateral?.toFixed(2)}
                  </span>
                </div>

                <div className="d-flex  justify-content-between  my-3 ">
                  <span className="text-muted">Collateral (Equity)</span>
                  <span className="  mx-5 ">
                    {data?.available?.collateral?.toFixed(2)}
                  </span>
                </div>

                <div className="d-flex  justify-content-between  my-3 ">
                  <span className="text-muted">Total collateral </span>
                  <span className="  mx-5 ">
                    {data?.utilised?.stock_collateral?.toFixed(2)}
                  </span>
                </div>
                {/* {value === true ? (
                  <div className="d-flex  justify-content-between  my-3 ">
                    <span className="text-muted">Access Token</span>
                    <div className="mx-5 d-flex">
                      <span className="  mx-5 ">********************</span>
                      <span>
                        <Button onClick={copytext}>
                          <CopyOutlined />
                        </Button>
                      </span>
                    </div>
                  </div>
                ) : null} */}
              </div>
            </div>
          </div>
          <div className="col-6"></div>
        </div>}
      </div>
    </>
  );
}

export default Funds;
