import React from 'react'

import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  useNavigate,
  NavLink
} from "react-router-dom";

import GTT from './ordersmenu/GTT';
import Orders from './ordersmenu/Orders';

function Ordersmain() {
  
  return (
   <>
    <ul className="  d-flex mt-3 m-0 p-0 mx-0 fs-med  px-4 border-bottom pb-3">
  <li className="">
    <NavLink className={({ isActive }) =>
                      isActive ? "active text-orange py-3 border-bottom border-orange px-4 " : " text-default py-3 px-4 "
                    } aria-current="page" to="">Orders</NavLink>
  </li>
  <li className="">
    <NavLink  className={({ isActive }) =>
                      isActive ? "active text-orange p-3 border-bottom border-orange  px-4 " : "p-3 text-default px-4 "
                    }  to="gtt">GTT</NavLink>
  </li>
  </ul>
  <div className='page-content'>
    <Routes>
              <Route exact path="" element={<Orders/>} />
              <Route exact path="gtt" element={<GTT />} />
    </Routes>
    </div>
   </>
  )
}

export default Ordersmain
