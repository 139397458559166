import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BASEURL } from "../BaseURL";

const Getaccesstoken = (props) => {
  const navigate = useNavigate();
  const [searchParams, setsearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("request_token") !== "") {
      getuserdata(searchParams.get("request_token"));
    }
  }, []);

  const getuserdata = async (token) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);
    const getdata = await fetch(BASEURL + "getaccesstoken", {
      method: "POST",
      headers: myHeaders,
    });

    const data = await getdata.json();
    if (data.status === 200) {
      if (data.access_token !== "") {
        localStorage.setItem("accessdata", data.access_token);
        localStorage.setItem("broker", JSON.stringify("zerodha"));
        navigate("/", { replace: true });
      } else {
      }
    }
  };
};

export default Getaccesstoken;
