import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';
import HighchartsAccessibility from 'highcharts/modules/accessibility';
import HighchartsFullScreen from 'highcharts/modules/full-screen';
import HighchartsReact from 'highcharts-react-official';
import { Select } from 'antd';
import { flatten, forOwn, groupBy } from 'lodash';


HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);
HighchartsAccessibility(Highcharts);
HighchartsFullScreen(Highcharts);

const Chart = ({ data,rawdata }) => {

  // const [dataProps,setDataProps]=useState([])
  const [option,setoption]=useState([])
  const [series,setseries]=useState([
    {
      name: 'Invested',
      data: data?.map((item) => [new Date(item.key).getTime(), Number(item.invested)]),
    },
    {
      name: 'Current',
      data: data?.map((item) => [new Date(item.key).getTime(), Number(item.current)]),
    },
    {
      name: 'Realised',
      data: data?.map((item) => [new Date(item.key).getTime(), Number(item.realised)]),
    },
    {
      name: 'Unrealised',
      data: data?.map((item) => [new Date(item.key).getTime(), Number(item.unrealised)]),
    },
  ])



  useEffect(()=>{
    let isinGroup = groupBy(rawdata, "policy");

    const options = []
    

    forOwn(isinGroup, function (value, key) {
      
      const obj= {
        value: key,
        label: key,
      }
      options.push(obj)
    })
    setoption(options)
    // setDataProps(data)
  },[])

  const options = {
    title: {
      text: '',
    },
    chart: {
      type: 'spline',
      zoomType: 'x',
    },
    tooltip: {
        shared: true,
        // style: {
        //   color: "white",
        // },
        valueDecimals: 0,
      },
    accessibility: {
        enabled: true,
        
      },
    exporting: {
      enabled: true, // Enable the exporting module
      filename: 'my-chart', // Set the default filename for downloads
      buttons: {
        contextButton: {
          menuItems: [
            'downloadPNG',
            'downloadJPEG',
            'downloadPDF',
            'downloadSVG',
            'downloadCSV', // Export data to CSV
            'downloadXLS', // Export data to XLS (Excel)
            'separator',
            'printChart', // Print the chart
            'openInCloud', // Open the chart in Highcharts Cloud
            'viewFullscreen', // Full Screen option
          ],
        },
      },
    },
    xAxis: {
      type: 'datetime',
      title: {
        text: 'Date',
      },
    
    },
    yAxis: {
      title: {
        text: 'Value',
      },
    
    },
    
    
    series: series,
  };

  const handleChange = (value) => {
    
    if(value=="summary") {
      setseries([
        {
          name: 'Invested',
          data: data?.map((item) => [new Date(item.key).getTime(), Number(item.invested)]),
        },
        {
          name: 'Current',
          data: data?.map((item) => [new Date(item.key).getTime(), Number(item.current)]),
        },
        {
          name: 'Realised',
          data: data?.map((item) => [new Date(item.key).getTime(), Number(item.realised)]),
        },
        {
          name: 'Unrealised',
          data: data?.map((item) => [new Date(item.key).getTime(), Number(item.unrealised)]),
        },
      ])
    }

    else if(value=="all"){
      const final2=[]
      let isinGroup = groupBy(rawdata, "policy");
      forOwn(isinGroup, function (value, key) {

        const arr=[
          {
            name: `${key} Invested`,
            data: value?.map((item) => [new Date(item.date).getTime(), Number(item.average_price*item.quantity)]),
          },
          {
            name: `${key} Current`,
            data: value?.map((item) => [new Date(item.date).getTime(), Number(item.ltp * item.quantity)]),
          },
          {
            name: `${key} Realised`,
            data: value?.map((item) => [new Date(item.date).getTime(), Number(item.buyValue- item.sellValue)]),
          },
          {
            name: `${key} Unrealised`,
            data: value?.map((item) => [new Date(item.date).getTime(), Number((item.ltp-item.average_price)*item.quantity)]),
          },
        ]

        final2.push(arr)
      })
      setseries(flatten(final2))
    }

    else {
      const final=[]
      
      const policydata=rawdata.filter((f)=>f.policy==value)
      let isinGroup = groupBy(policydata, "date");

    
    

    

    forOwn(isinGroup, function (value, key) {
        const obj={
          key,
          invested:value.map((g)=>g.average_price * g.quantity).reduce((a,b)=>a+b,0),
          current:value.map((g)=>g.ltp * g.quantity).reduce((a,b)=>a+b,0),
          realised:value.map((g)=>g.sellValue-g.buyValue).reduce((a,b)=>a+b,0),
          unrealised:value.map((g)=>g.quantity * (g.ltp-g.average_price)).reduce((a,b)=>a+b,0)
        }
        
        final.push(obj)
    })

    setseries([
      {
        name: 'Invested',
        data: final?.map((item) => [new Date(item.key).getTime(), Number(item.invested)]),
      },
      {
        name: 'Current',
        data: final?.map((item) => [new Date(item.key).getTime(), Number(item.current)]),
      },
      {
        name: 'Realised',
        data: final?.map((item) => [new Date(item.key).getTime(), Number(item.realised)]),
      },
      {
        name: 'Unrealised',
        data: final?.map((item) => [new Date(item.key).getTime(), Number(item.unrealised)]),
      },
    ])
    
    
    }

    
    
    
  };

  return (
    <>
          <Select
      defaultValue="summary"
      style={{
        width: 300,
      }}
      onChange={handleChange}
      options={[{
        value: 'summary',
        label: 'Summary',
      },{
        value: 'all',
        label: 'All',
      },
      ...option ]}
    />
    
    <HighchartsReact highcharts={Highcharts} options={options} />
    </>
  );
};

export default Chart;































