import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  show: false,
  data:{},
  fundamentals:false,
  alert:false,
  ordersshow:false,
  orders_type:"BUY",
  show_marketwatch:false,
  show_info:false,
  show_gtt:false,
  show_basket:false,
  modalask:"",
  margindata:[],
  theme:false,
  policyarray:[],
  policy_break:[],
  bulletinArray:[]
  
}

export const technicalsSlice = createSlice({
  name: 'technicals',
  initialState,
  reducers: {
    showtechnical: (state,action) => {
      state.show =action.payload
    },
    technicaldata: (state,action) => {
      state.data=action.payload
    },
    bulletin: (state,action) => {
      state.bulletinArray=action.payload
    },

    showfundamental: (state, action) => {
      state.fundamentals=action.payload
    },
    showalert: (state, action) => {
      state.alert=action.payload
    },
    showorder: (state, action) => {
      state.ordersshow=action.payload
    },
    ordertype: (state, action) => {
      state.orders_type=action.payload
    },
    showmarketwatch: (state, action) => {
      state.show_marketwatch=action.payload
    },
    showinfo: (state, action) => {
      state.show_info=action.payload
    },
    getholdingdata: (state, action) => {
      state.holdingsdata=action.payload
    },
    showgtt: (state, action) => {
      state.show_gtt=action.payload
    },
    showbasket: (state, action) => {
      state.show_basket=action.payload
    },
    showmodal: (state, action) => {
      state.modalask=action.payload
    },
    margin_data: (state, action) => {
      state.margindata=action.payload
    },
    darkmode: (state, action) => {
      state.theme=action.payload
    },
    darkmode: (state, action) => {
      state.theme=action.payload
    },
    policydata: (state, action) => {
      state.policyarray=action.payload
    },
    policybreakdata: (state, action) => {
      state.policy_break=action.payload
    },
    websocketSend: (state, action) => {
      state.websocket_send=action.payload
    },
    websocketReceive: (state, action) => {
      state.websocket_receive=action.payload
    },
    
  },
})

// Action creators are generated for each case reducer function
export const { showtechnical, technicaldata, showfundamental 
  ,showalert,showorder,ordertype,showmarketwatch,showinfo,
  getholdingdata,showgtt,showbasket,showmodal,margin_data,darkmode,basket,policydata,policybreakdata,websocketSend,websocketReceive,bulletin} = technicalsSlice.actions

export default technicalsSlice.reducer