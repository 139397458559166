import React from "react";
import Navbar from "./component/Navbar";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Contrade from "./tradebook";
import Conholdings from "./holdings";
import Consoledash from "./Home";
import Pl from "./Pl";
import Consell from "./taxpnl";
// import Events from "./Events";

import HoldingSummary from "./HoldingSummary";
// import IndicesChart from "./IndicesChart";
import HoldingSummaryPolicy from "./HoldingSummaryPolicy";
import IndividualStock from "./IndividualStock";
// import HoldingSummary from "./HoldingSummary copy";

function Dashboard() {
  return (
    <>
      
        <Navbar />
      

      <div className="mx-4">
        <Routes>
          <Route exact path="/" element={<Consoledash />} />
          <Route exact path="/holdings" element={<Conholdings />} />
          <Route exact path="/tradebook" element={<Contrade />} />
          <Route exact path="/pnl" element={<Pl />} />
          <Route exact path="/taxpl" element={<Consell />} />
          {/* <Route exact path="/events" element={<Events />} /> */}
          {/* <Route exact path="/difference" element={<Difference/>} /> */}
          <Route exact path="/holding-summary" element={<HoldingSummary/>} />
          <Route exact path="/holding-summary-policy" element={<HoldingSummaryPolicy/>} />
          <Route exact path="/holding-summary-policy/:slug" element={<IndividualStock/>} />
          {/* <Route exact path="/indices-chart" element={<IndicesChart/>} /> */}
        </Routes>
      </div>
    </>
  );
}

export default Dashboard;
