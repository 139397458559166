import React, { useEffect ,useState} from "react";




import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  
  
} from "react-router-dom";
import { NavLink} from "react-router-dom";
import Performance from "./Weekhigh52/Performance";
// import { useSelector } from "react-redux";
import { Segmented } from "antd";
// import Performance2 from "./Weekhigh52/Performance2";


function Week52high() {
  const [value, setValue] = useState();
  let location = useLocation();

useEffect(()=>{
setValue(location.pathname.split("/").slice(-1)[0] === 'home' ? 'performance':location.pathname.split("/").slice(-1)[0])
},[location])

  return (
    <>
      
      
      <Segmented
      value={value} onChange={(e)=>{setValue(e);console.log(e);}}
      options={[
        
        {
          label: <span><NavLink to="">Performance</NavLink></span>,
          value: 'performance',
          key:1
        },
        {
          label: <span><NavLink to="price">Price</NavLink></span>,
          value: 'price',
          key:2
        },
        {
          label:<span> <NavLink to="technical">Technical</NavLink></span>,
          value: 'technical',
          key:3
        },
        {
          label:<span> <NavLink to="fundamental">Fundamental</NavLink></span>,
          value: 'fundamental',
          key:4
        },
        
      ]}
     />
      <div className="py-3">
      <Routes>
        <Route exact path="" element={<Performance />} />
        {/* <Route exact path="performance2" element={<Performance2 />} /> */}
        <Route exact path="price" element={<Price />} />
        <Route exact path="technical" element={<Technical />} />
        <Route exact path="fundamental" element={<Fundamental />} />
      </Routes>
      </div>
    </>
  )
}

export default Week52high;

// ##################### Price ########################

function Price() {
  return <div>pricefff</div>;
}



// ##################### Technical ########################

function Technical() {
  return <div>priceaaaa</div>;
}

// ##################### Fundamental ########################

function Fundamental() {
  return <div>price</div>;
}
