import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import addTreemapModule from "highcharts/modules/treemap";
import { Tooltip } from "antd";


// import Treemapchart from "../shared-components/Treemapchart";
import { userdataPost, getMarketoverview, getunofficialholding } from "../api/api";
import formatLargeNumber from "../../libs/helperFunction/formatLargeNumber";
import {
  useQuery,
  
} from '@tanstack/react-query'


addTreemapModule(Highcharts);

function Home() {


  const [margindata, setmargindata] = useState("");
  const [loading, setLoading] = useState("");
  const [holdingdata, setholdingdata] = useState([]);
  // const [holdingtotal, setholdingtotal] = useState(0);
  // const [pnl, setpnl] = useState(0);
  const [overviewchartOptions, setoverviewchartOptions] = useState([]);





  // useEffect(() => {
  //   const fetchData = async () => {
  //     setLoading(true)
  //     // const data = await userdataPost();
  //     setmargindata(data?.data?.equity);
  //     let holdingData = await getunofficialholding();
  //   setholdingdata(holdingData.data)
  //   setLoading(false)
  
  //   };
  //   // getuserdata();
  //   fetchData()
  //   getmarketoverview();
  //   // getholdings()
  // }, []);

  const fetchData = async () => {
    setLoading(true)
    const data = await userdataPost();
    setmargindata(data?.data?.equity);
    let holdingData = await getunofficialholding();
  setholdingdata(holdingData.data)
  setLoading(false)

  };

  const query = useQuery({ queryKey: ['todos'], queryFn: fetchData })


  

  const getholdings = async () => {

    
    // setholdingtotal(data?.data?.map((element) => Number(element?.average_price) * Number(element?.quantity))?.reduce((a, b) => a + b, 0)?.toFixed(2))
    // setpnl(data?.data?.map((ele) => Number(ele.pnl))?.reduce((a, b) => a + b, 0)?.toFixed(2))

  }

  const holdingtotal=holdingdata?.map((element) => Number(element?.average_price) * Number(element?.quantity))?.reduce((a, b) => a + b, 0)?.toFixed(2)
  const pnl=holdingdata?.map((ele) => Number(ele.pnl))?.reduce((a, b) => a + b, 0)?.toFixed(2)


  const getmarketoverview = async () => {
    const data = await getMarketoverview();
    if (data.status === 200) {
      let yAxis = [];
      let data2 = [];
      data?.data?.forEach((ele) => {
        if (new Date(ele.timestamp)?.getDate() % 10 === 0) {
          data2.push(ele);
        }
      });
      data2?.forEach((element) => {
        yAxis.push([
          new Date(element.timestamp).getTime(),
          element.close_price,
        ]);
      });

      setoverviewchartOptions({
        plotLines: [
          {
            color: "#4184f3",
          },
        ],

        tooltip: {
          style: {
            color: "white",
          },
        },

        chart: {
          type: "spline",

          height: "190px",
        },
        legend: {
          align: "left",
          verticalAlign: "top",
          x: 0,
          y: 20,
          floating: true,

          symbolRadius: 0,
        },

        credits: {
          enabled: false,
        },
        title: {
          text: "",
        },
        yAxis: {
          tickInterval: 30,
          visible: true,
          title: {
            text: null,
          },
          labels: {
            enabled: false,
          },
        },

        xAxis: {
          labels: {
            format: "{value:%b-%Y}",
            style: {
              fontSize: "0.75rem",
              color: "#9b9b9b",
            },
          },
          tickLength: 0,
        },

        plotOptions: {
          series: {
            color: "#4184f3",
          },
        },

        series: [
          {
            name: "NIFTY 50",
            data: yAxis,

            marker: {
              enabled: false,
            },
            lineWidth: 2,
          },
        ],
      });
    }
  };

  return (
    <>
      <div className="allpadding">

        <div className="" >
          <div className="border-bottom  ">
            <h1 className="box-title py-2">Hi, Jinendra</h1>
          </div>
          <div className="row my-4 pb-5 pt-4 border-bottom  ">
            <div className="col-12 col-xl-6   "
            >
              <div className="secondarytitle mb-4">

                <span>
                  <i className="bi bi-pie-chart"></i>
                </span>
                <span className="ms-10 "></span> Equity

              </div>
              <div className=" d-flex">
                <div className="w-50 text-start " >
                  <div className="border-end">

                    <Tooltip placement="bottom" title={margindata?.net}>
                      <div
                        style={{
                          fontSize: "42px",
                          fontWeight: "300",
                          lineHeight: "1.3",
                        }}
                        className={
                          margindata?.net > 0 ? " " : " text-danger"
                        }
                      >
                        {formatLargeNumber(margindata?.net)}
                      </div>
                    </Tooltip>
                    <div >Margin Available</div>

                  </div>
                </div>

                <div>
                  <ul className="   my-2">
                    <li>
                      <div>
                        <span className="px-1 ">Margin Used:</span>
                        <span className="">
                          {formatLargeNumber(Number(margindata?.utilised?.debits))}
                        </span>
                      </div>
                    </li>
                  </ul>
                  <ul className="  my-2">
                    <li className="p-0">
                      <div>
                        <span className="px-1 ">Opening balance:</span>
                        <span className="">
                          {formatLargeNumber(margindata?.available?.opening_balance)}
                        </span>
                      </div>
                    </li>
                  </ul>
                  <ul className=" my-2 ">
                    <li className="p-0 ">
                      <div className="pl-2">
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://console.zerodha.com/funds/statement?segment=equity&src=kiteweb"
                        >
                          <span className="me-2">
                            <i className="bi bi-eye"></i>
                          </span>
                          View statement
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>


            <div
              className="col-6"
            >

              <div className="position-absolute w-100 h-100   d-flex justify-content-center align-items-center flex-column" style={{ zIndex: 1 }}>

                <div>You don't have a commodity account </div>

                <a
                  target="_blank"
                  className="btn btn-sm btn-primary"
                  href="https://console.zerodha.com/account/segment-activation"
                >Activate</a>
              </div>

              <div className="opacity-25">

                <div className="secondarytitle mb-4">

                  <span>
                    <i className="bi bi-pie-chart"></i>
                  </span>
                  <span className="ms-10 "></span> Commodity

                </div>
                <div className=" d-flex">
                  <div className="w-50 text-start " >
                    <div className="">


                      <div className={margindata?.net > 0 && "text-danger"}>
                        0
                      </div>

                      <div>Margin Available</div>

                    </div>
                  </div>

                  <div>
                    <ul className="   my-2">
                      <li>
                        <div>
                          <span className="px-1 ">Margin Used:</span>
                          <span className="">
                            0
                          </span>
                        </div>
                      </li>
                    </ul>
                    <ul className="  my-2">
                      <li className="p-0">
                        <div>
                          <span className="px-1 ">Opening balance:</span>
                          <span className="">
                            0
                          </span>
                        </div>
                      </li>
                    </ul>
                    <ul className=" my-2 ">
                      <li className="p-0 ">
                        <div className="pl-2   text-blue ">

                          <span className="me-2">
                            <i className="bi bi-eye"></i>
                          </span>
                          View statement

                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>







          </div>
        </div>


        <div

          className="my-4 py-4"

        >

          <div >
            
              

              <h5> <i className="bi bi-briefcase"></i> Holdings ({holdingdata?.length})</h5>
            
          </div>
          <div className="row my-4">
            <div className="  col-12 col-xl-8 d-flex ">
              <div className="d-flex w-100" >
                <div className="border-end w-50">

                  <Tooltip placement="bottom" title={pnl}>
                    <span
                      style={{
                        fontSize: "42px",
                        fontWeight: "300",
                        lineHeight: "1.3",
                      }}
                      className={pnl > 0 ? "text-success " : "text-danger"}
                    >

                      {formatLargeNumber(pnl)}
                    </span>
                    <span className={`ms-1 ${pnl>0 ? ' text-success' :'text-danger'}`}>{((Number(pnl) / Number(holdingtotal)) *100).toFixed(2)}%</span>

                  </Tooltip>
                  <div>
                    <p >P&L</p>
                  </div>
                </div>
                <div className="w-50 text-center">
                  <Tooltip
                    placement="bottom"
                    title={Number(holdingtotal) + Number(pnl)}
                  >
                    <div>
                      <span >Current value:</span>
                      <span className="ms-1">
                        {formatLargeNumber(Number(holdingtotal) + Number(pnl))}
                      </span>
                    </div>
                  </Tooltip>

                  <Tooltip placement="bottom" title={holdingtotal}>
                    <div className=" ">
                      <span className="  px-1">Investment:</span>
                      <span className="">
                        {formatLargeNumber(holdingtotal)}
                      </span>
                    </div>
                  </Tooltip>
                </div>
              </div>
            </div>

          </div>

          <div className="row">
            <div className="col-12">
              {/* {holdingdata?.length > 0 && <Treemapchart data={holdingdata} />} */}
            </div>
          </div>
        </div>


        <div className="row mb-5 px-4 pb-5">
          <div className="col-md-6 col-12 ">
            <div className="secondarytitle">
              <span>
                <i className="bi bi-graph-up"></i>
              </span>
              <span className="mx-2"> Market Overview</span>
            </div>
            <HighchartsReact
              highcharts={Highcharts}
              options={overviewchartOptions}
            />
          </div>
        </div>

      </div>
    </>
  );
}

export default Home;
