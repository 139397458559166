import React, { useState, useEffect } from "react";
import {  Tooltip, Input, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { SearchOutlined  } from '@ant-design/icons';
import {
  useQuery,
  
} from '@tanstack/react-query'


import { BASEURL} from "../BaseURL";


import {
  technicaldata,
  showorder,
  ordertype,
  websocketSend,
  websocketReceive,
} from "../../features/counterSlice";


import { useNavigate } from "react-router-dom";
import {
  addMarketwatchdata,
  deleteMarket,
  getMarketwatchdata,
  // holdingsPost,
  instrumentsearchPost,
} from "../api/api";



const Sidebar = () => {
  const dispatch = useDispatch();
  const wsReceivedata = useSelector((state) => state.counter.websocket_receive);
  const [InstrumentData, setInstrumentData] = useState([]);
  const [parentId, setparentId] = useState(0);
  
  const [mouseOverId, setmouseOverId] = useState(undefined);
  const [marketdepthArray, setmarketdepthArray] = useState([]);
  const [searchListId, setsearchListId] = useState(undefined);
  const [watchId, setWatchId] = useState(undefined);

  
  
  const [marketwatchdata, setmarketwatchdata] = useState([]);
  const [instloading, setinstloading] = useState(false);
  

  
  const [data, setData] = useState([]);

  
  // const [holding, setholding] = useState([]);

  useEffect(() => {
    marketwatchdata?.forEach((ele) => {
      let value = wsReceivedata?.find(
        (e) => ele.instrument_token == e.instrument_token
      );
      if (value?.last_price) {
        ele.last_price = value.last_price;
        ele.change=value.change ?? 0
      }
    });
  }, [wsReceivedata,marketwatchdata]);


  useEffect(()=>{
    let tokens = marketwatchdata?.map((item) => Number(item.instrument_token));

    var message = { a: "mode", v: ["quote", tokens] };

    dispatch(websocketSend(message));

    return ()=>{
      let  message = { a: "unsubscribe", v:tokens }
      dispatch(websocketSend(message))
    }


  },[marketwatchdata])


  const navigate = useNavigate();

  // const getholdingdata = async () => {
  //   let data = await holdingsPost();
  //   setholding(data.holdingdata);
  // };

  const getChart = (item) => {
    navigate(
      `/chart/web/ciq/${item.exchange}/${item.tradingsymbol}/${item.instrument_token}`,
      { replace: true }
    );
  };

  // useEffect(() => {
  //   // getholdingdata();
  //   fetchmarketwatchdata();
  // }, []);

  const fetchmarketwatchdata = async () => {
    const data = await getMarketwatchdata();
    setmarketwatchdata(data?.data?.data?.[0]?.items?.reverse());
    setData(data?.data?.data)
    
  };


  const query = useQuery({ queryKey: ['marketwatch'], queryFn: fetchmarketwatchdata})

  const showmarketdepth = async (item) => {
    
    
    const findId=marketdepthArray.find((ele)=>ele==item.id)
    if(findId){
      const indexToRemove = marketdepthArray.indexOf(item.id);
      marketdepthArray.splice(indexToRemove, 1);
      
      
    }else{
      marketdepthArray.push(item.id)
      const message = { a: "mode", v: ["full", [Number(item.instrument_token)]] };
  
      dispatch(websocketSend(message));
    }
  };

  

  

  const changepage=(name,id)=>{
    setWatchId(id)
    setmarketwatchdata(data.find((ele)=>ele.name==name ).items)
    // setparentId()
  }


  const fetchInstrumenttoken = async (item) => {
    const myHeaders = new Headers();
    const token = localStorage.getItem("requesttoken");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);
    const getdata = await fetch(BASEURL + "addmarketwatch", {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        item,
        broker: localStorage.getItem("broker"),
      }),
    });

    const data = await getdata.json();

    document.getElementsByClassName(
      "omnisearch-results-sidebar"
    )[0].style.visibility = "hidden";
    
    if (data) {
      fetchmarketwatchdata();
    }
    setinstloading(false);
  };

 

  const onInstrumentSearch = async (e) => {
    
    document.addEventListener("keydown", ({ key }) => {
      if (key === "Escape") {
        
        setInstrumentData([])
      }
    });
    
    const data = await instrumentsearchPost(e.target.value.replaceAll(" ", ""));
  setInstrumentData(data.data);
  };

  

  

  return (
    <>
      <div className="position-relative" 
      style={{height:'calc(100% - 50px)'}}>
        
        <Input size="large" placeholder="Search eg:infy bse ,nifty fut, nifty weekly,gold mcx" prefix={<SearchOutlined />} onChange={(e) => onInstrumentSearch(e)} /> 
        {InstrumentData.length>0 && (
          <ul className="p-0 m-0">
            {InstrumentData?.map((elm, i) => {
              
              return (
                <>
                  <li
                    onClick={async()=>{await addMarketwatchdata(elm.tradingsymbol,elm.exchange,watchId
                      );fetchmarketwatchdata()}}
                    key={i}
                    onMouseEnter={() => setsearchListId(i)
                    
                  }
                    onMouseLeave={() =>setsearchListId(i)
                    
                  }
                    className={` ${searchListId === i && 'bg-light'} py-2 border-bottom px-0 d-flex justify-content-between  position-relative d-flex  `}
                    
                  >
                    <span className="ms-2">{elm.tradingsymbol}</span>

                    <span>
                      <span className=" me-2 text-muted">
                        {elm.name}
                      </span>
                      <span
                        className={
                          elm.exchange === "BSE"
                            ? "badge text-bg-primary"
                            : elm.exchange === "NSE"
                            ? "badge text-bg-danger"
                            : "badge text-bg-secondary"
                        }
                      >
                        {elm.exchange}
                      </span>
                    </span>
{searchListId === i &&
                    <div
                      className="position-absolute end-0"
                    >
                      <Tooltip placement="top" title="Buy (B)">
                        <Button
                          type="primary"
                          size="small"

                          
                          onClick={() => {
                            dispatch(technicaldata({ data: elm }));
                            dispatch(ordertype("BUY"));
                            dispatch(showorder(true));
                          }}
                        >
                          B
                        </Button>
                      </Tooltip>

                      <Tooltip placement="top" title="Sell (S)">
                        <Button
                          size="small"
                          type="primary"
                          className="mx-2"
                          danger
                          onClick={() => {
                            dispatch(technicaldata({ data: elm }));
                            dispatch(ordertype("SELL"));
                            dispatch(showorder(true));
                          }}
                        >
                          S
                        </Button>
                      </Tooltip>

                      <Tooltip placement="top" title="Chart(C)">
                        <Button 
                        size="small"
                        onClick={() => getChart(elm)}>
                          <i className="bi bi-graph-up"></i>
                        </Button>
                      </Tooltip>

                      <Tooltip placement="top" title="Market depth">
                        <Button
                          size="small"
                          data-bs-toggle="modal"
                          data-bs-target="#marketdepth"
                          className="mx-2"
                          onClick={() => {
                            dispatch(technicaldata({ data: elm }));
                          }}
                        >
                          <i className="bi bi-collection "></i>
                        </Button>
                      </Tooltip>
                    </div>
            }
                  </li>
                </>
              );
            })}
          </ul>
        ) }

        {instloading ? (
          <>
            <div className="d-flex justify-content-center mt-3">
              <div className="spinner-border" role="status"></div>
            </div>
          </>
        ) : (
          <>
            <div>
              

              {marketwatchdata?.length>0 ? marketwatchdata?.map((tick)=>{
                

                  const tokendata=wsReceivedata?.find((ele)=>ele.instrument_token==tick.instrument_token)

                return<> <div onMouseLeave={()=>setmouseOverId(undefined)} onMouseOver={()=>setmouseOverId(tick.id)} className={` ${mouseOverId==tick.id && 'bg-light '}  position-relative d-flex w-100 items-center border-bottom justify-content-between`} key={tick.id} style={{padding:"12px",fontSize:".8125rem"}}>
                  <div className="d-flex justify-content-between" >
                    <div><span className={tick?.change>0?'text-success':'text-danger'}>{tick.tradingsymbol}</span><small className=" text-muted">{tick.exchange ?? 0.0}</small></div>
                    <div>{tick.holding_quantity}<span>{tick.holding_quantity>0?<i class="bi bi-briefcase"></i>:null}</span></div>
                  </div>
                  <div className="d-flex">
                    <div>
                      <div className="d-flex">
                        <span className="px-2 opacity-50">{(((tick?.change ?? 0) * (tick?.last_price ?? 0)) / 100)?.toFixed(2)}</span>
                        <span className="px-2 opacity-75  ">{tick.change?.toFixed(2)}<small>%</small></span>
                      </div>
                    </div>
                    <div className="d-flex">
                        <span className="px-2"><i className={`bi bi-chevron-compact-${tick?.change>0?'up text-success':'down text-danger'}`}></i></span>
                        <span className={`text-end ${tick?.change>0?'text-success':'text-danger'}`} style={{minWidth:"60px"}}>{tick.last_price}</span>
                    </div>

                  </div>

                  {(tick.id==mouseOverId)?
                  
                  <div className={`position-absolute end-0  `}>
                     <Tooltip placement="top" title="Buy (B)">
            <Button
              size="small"
              primary
              type="primary"
              className=" px-2"
              onClick={() => {
                dispatch(technicaldata({ data: {...tick} }));
                dispatch(ordertype("BUY"));
                dispatch(showorder(true));
              }}
            >
              B
            </Button>
          </Tooltip>

          <Tooltip placement="top" title="Sell (S)">
            <Button
            danger
            type="primary"
            className="ms-2 px-2"
              size="small"
              onClick={() => {
                dispatch(technicaldata({ data: {...tick} }));
                dispatch(ordertype("SELL"));
                dispatch(showorder(true));
              }}
            >
              S
            </Button>
          </Tooltip>

          <Tooltip placement="top" title="Chart(C)">
            <Button 
            size="small"
            className="ms-2 px-2"
            onClick={() => getChart(tick)}>
              <i className="bi bi-graph-up"></i>
            </Button>
          </Tooltip>

          <Tooltip placement="top" title="Market Depth (D)">
            <Button
              size="small"
              className="ms-2 px-2"
              onClick={() => {
                dispatch(technicaldata({ data: {...tick} }));
                showmarketdepth(tick);
              }}
            >
              <i className="bi bi-collection "></i>
            </Button>
          </Tooltip>

          <Tooltip placement="top" title="Delete (del)">
            <Button
            className="ms-2 px-2"
            size="small"
              
              onClick={async () => {
                await deleteMarket(parentId,tick.id);
                fetchmarketwatchdata();
              }}
            >
              <i className="bi bi-archive"></i>
            </Button>
          </Tooltip>
                  </div>
                  :null}

                  

                </div>

                {(tick.id==marketdepthArray.find((ele)=>ele==tick.id))?
                  <div>
                  <div className="row pt-10 m-0  ">
                    <table className="w-50">
                      <thead>
                        <tr>
                          <th>
                            <span className=" text-muted">BID</span>
                          </th>
                          <th className="text-end  text-muted">ORDERS</th>
                          <th className="text-end  text-muted">QTY</th>
                        </tr>
                      </thead>
  
                      <tbody className="text-primary">
                        {tokendata?.depth?.buy?.map((item, i) => {
                          return (
                            <>
                              <tr key={i}>
                                <td>{item.price}</td>
                                <td className="text-end">{item.orders}</td>
                                <td className="text-end">{item.quantity}</td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
  
                      <tfoot className="text-primary">
                        <tr>
                          <td className="text-start p-0">Total</td>
                          <td></td>
                          <td className="text-end pe-0">
                            {tokendata?.total_buy_quantity}
                          </td>
                        </tr>
                      </tfoot>
                    </table>
  
                    <table className="w-50">
                      <thead>
                        <tr>
                          <th>
                            <span className="text-end  text-muted">
                              OFFER
                            </span>
                          </th>
                          <th className="text-end  text-muted">ORDERS</th>
                          <th className="text-end  text-muted">QTY.</th>
                        </tr>
                      </thead>
  
                      <tbody className="text-danger">
                        {tokendata?.depth?.sell?.map((item, i) => {
                          return (
                            <>
                              <tr key={i}>
                                <td >{item.price}</td>
                                <td className="text-end">{item.orders}</td>
                                <td className="text-end">{item.quantity}</td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
  
                      <tfoot className="text-danger">
                        <tr>
                          <td className="text-start p-0">Total</td>
                          <td></td>
                          <td className="text-end pe-0">
                            {tokendata?.total_sell_quantity}
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                  <div className="px-1 py-1">
                    <div className="row">
                      <div className="w-50">
                        <label >Open</label>
                        <div >{tokendata?.ohlc?.open}</div>
                      </div>
                      <div className="w-50">
                        <label >High</label>
                        <div >{tokendata?.ohlc?.high}</div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="w-50">
                        <label >Low</label>
                        <div >{tokendata?.ohlc?.low}</div>
                      </div>
                      <div className="w-50">
                        <label >Prev. Close</label>
                        <div >{tokendata?.ohlc?.close}</div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="w-50">
                        <label >Volume</label>
                        <span >
                          {tokendata?.volume_traded}
                        </span>
                      </div>
                      <div className="w-50">
                        <label >Avg. price</label>
                        <div >
                          {tokendata?.average_traded_price}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="w-50">
                        <label >LTQ</label>
                        <span >
                          {tokendata?.last_traded_quantity}
                        </span>
                      </div>
                      <div className="w-50">
                        <label >LTT</label>
                        <span >
                          {tokendata?.last_trade_time}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                  :null}    

</>


              }):
              <div className="py-3" >
                    <image src='/marketwatch.svg'/>
            <h3 className=" text-center">Nothing here</h3>
            <p className="text-center opacity-75">Use the search bar to add instruments.</p>
            
                </div>
              
              }
            </div>
            <div className="border shadow-sm bg-white  left-0 p-0 position-fixed bottom-0">
              <ul className="d-flex p-0 m-0">
                {data?.map((market,i)=>{
                  return <Tooltip title={market.name}> <li className="py-2 px-3 border-end" onClick={()=>{changepage(market.name,market.id);setparentId(market.id)}} >{i+1}</li></Tooltip>
                })}
              </ul>
            </div>
            </>
           
          
        )}
      </div>
    </>
  );
};

export default Sidebar;
