// import React from 'react'
import { data } from 'autoprefixer';
import { useGetOrderQuery ,useGetTradeQuery} from '../../../../features/upstoxapi';


// function Orders() {
//     const { data, error, isLoading, refetch } = useGetOrderQuery();
//   const { data:trade, error:tradeerror, isLoading:tradeloading, refetch:traderefetch } = useGetTradeQuery();
//   console.log(data);
//   return (
//     <>
    
//    jlkhljk
//     </>
//   )
// }

// export default Orders
import React, { useEffect } from 'react'
import { useState } from 'react';

function Orders2() {
    const [executed, setexecuted] = useState([]);
        const { data, error, isLoading, refetch } = useGetOrderQuery();
      const { data:trade, error:tradeerror, isLoading:tradeloading, refetch:traderefetch } = useGetTradeQuery();
//  console.log(trade);
 useEffect(()=>{
    let executed=data?.data?.data?.filter((ele)=>ele.status==='complete')
    setexecuted(executed)

 },[isLoading])
 setTimeout(() => {
    console.log(executed);
 }, 3000);
      return (
    <div>
      <section>
        <div className='d-flex justify-content-between align-items-center mb-20'>
          <h3 className='fs-1125rem m-0 '>Executed orders (2)</h3>
          <div className='d-flex align-items-center'>
          <div className='me-2 position-relative '><span className='position-absolute p-2  fs-small'><i class="bi bi-search"></i></span><input className='fs-med smallsearch border ' placeholder='Search' type='text '/></div>
          <div className='fs-small'><a href=''>Contract note</a></div>
          <div className='fs-small mx-2'><a href=''><span className='me-1'><i class="bi bi-stop-circle-fill"></i></span>View history</a></div>
          <div className='fs-small'><a href=''>Download</a></div>
          </div>
        </div>
      <table class="table border-top">
  <thead>
    <tr>
      <th>Time</th>
      <th>Type</th>
      <th>Instrument</th>
      <th>Product</th>
      <th>Qty</th>
      <th className='border-end'>Avg. price</th>
      <th className='text-end'>Status</th>
    </tr>
  </thead>
  <tbody>
    {executed?.map((ele,i)=>{
       return <tr key={i}>
      <th >{ele.order_timestamp}</th>
      <th >{ele.transaction_type}</th>
      <th >{ele.tradingsymbol}</th>
      <th >{ele.product}</th>
      <th >{ele.quantity}</th>
      <th className='border-end' >{ele.average_price
}</th>
      <th  className='text-end'>{ele.status}</th>
      
    </tr>
    
    })}
    
 
  </tbody>
</table>
</section>
    </div>
  )
}

export default Orders2








