import React from 'react'
import { useParams } from 'react-router-dom';   
import { taxpnl, trade_calculate, tradebook } from './api/api';
import {
  useQuery,

} from '@tanstack/react-query'
import { Statistic, Table, Tag } from 'antd';
import dateFormat from "dateformat";
import { Tabs } from 'antd';


function IndividualStock() {
    const { slug } = useParams();
    // const [taxpl, settaxpl] = useState([])
    const fetchTaxPl=async()=>{
        const data = await taxpnl("","",slug)
        return data.data
    }
    const {data:taxpl,isLoading} = useQuery({ queryKey: ['individualStock'], queryFn: fetchTaxPl })
    const {data:breakdown,isLoading2} = useQuery({ queryKey: ['individualStockBreakdown',slug], queryFn: trade_calculate })
    const {data:trades,isLoading3} = useQuery({ queryKey: ['individualStocktrades',slug], queryFn: tradebook })
    
    const sellColumn = [
      {
        title: "Date",
        dataIndex: "buy_date",
        sorter: (a, b) => a.buy_date - b.buy_date,
        render:(_,elm)=>dateFormat(elm?.buy_date, "dd-mm-yyyy")
      },
        {
          title: "Instrument",
          dataIndex: "tradingsymbol",
          sorter: (a, b) =>a.tradingsymbol.localeCompare(b.tradingsymbol),
          render: (_, elm) => (
            <>
            <div className='d-flex justify-content-between align-items-center'>
              <div>{elm.tradingsymbol}</div>
              <div className='text-muted fs-small'>{elm.exchange}</div>
              </div>
            </>
          ),
        },
        {
          title: "Qty",
          dataIndex: "deduct_quantity",
          sorter: (a, b) => a.deduct_quantity- b.deduct_quantity,
          
        },
        // {
        //   title: "buy_trade_id.",
        //   dataIndex: "buy_trade_id",
        //   sorter: (a, b) => a.buy_trade_id - b.buy_trade_id,
        // },
        // {
        //   title: "sell_trade_id",
        //   dataIndex: "sell_trade_id",
        //   sorter: (a, b) => a.sell_trade_id - b.sell_trade_id,
          
        // },
        // {
        //   title: "buy_quantity",
        //   dataIndex: "buy_quantity",
        //   sorter: (a, b) => a.buy_quantity - b.buy_quantity,
          
        // },
        {
          title: "buy_price",
          dataIndex: "buy_price",
          sorter: (a, b) => a.buy_price -b.buy_price,
          render: (_, elm) => elm.buy_price.toFixed(2)
          
        },
        // {
        //   title: "sell_quantity",
        //   dataIndex: "sell_quantity",
        //   sorter: (a, b) => a.sell_quantity - b.sell_quantity,
        // },
        {
          title: "sell_price",
          dataIndex: "sell_price",
          sorter: (a, b) => a.sell_price- b.sell_price,
          
        },
        
        {
          title: "P&L",
          dataIndex: "pl",
          sorter: (a, b) => a.pl - b.pl,
          render: (_, elm) => (
            <span className={elm.pl > 0 ? "text-success" : "text-danger"}>
              {elm.pl?.toFixed(2)}
            </span>
          ),
        },
       
      ];

      const tradeColumns = [
        {
          title: "Symbol.",
          dataIndex: "tradingsymbol",
          sorter: (a, b) => a.tradingsymbol.localeCompare(b.tradingsymbol),
          render: (_, elm) => (
            <>
              <div className="d-flex justify-content-between align-items-end">
                <span>{elm.tradingsymbol}</span>
                <span className="fs-small text-muted">{elm.exchange}</span>
              </div>
            </>
          ),
        },
        {
          title: "Trade Date",
          dataIndex: "order_timestamp",
          sorter: (a, b) => new Date(a.order_execution_time) - new Date(b.order_execution_time),
          render: (_, elm) => dateFormat(elm?.order_execution_time, "dd-mm-yyyy"),
        },
        {
          title: "Order id.",
          dataIndex: "order_id",
          sorter: (a, b) => a.order_id - b.order_id,
        },
        {
          title: "Trade id.",
          dataIndex: "trade_id",
          sorter: (a, b) => a.trade_id - b.trade_id,
    
        },
    
        {
          title: "Type.",
          dataIndex: "transaction_type",
          sorter: (a, b) => a.transaction_type - b.transaction_type,
          render: (_, elm) => (
             <Tag color={elm.transaction_type=='buy'?'green':'red'}>{elm.transaction_type?.toUpperCase()}</Tag>
          ),
        },
        {
          title: "Qty.",
          dataIndex: "quantity",
          sorter: (a, b) => a.quantity - b.quantity,
        },
        {
          title: "Price",
          dataIndex: "average_price",
          sorter: (a, b) => a.average_price - b.average_price,
          render: (_, elm) => elm.average_price.toFixed(2)
        },
        // {
        //   title: "Ltp",
        //   dataIndex: "ltp",
        //   sorter: async (a, b) => a.ltp - b.ltp,
        // },
        {
          title: "Policy",
          dataIndex: "policy",
          sorter: (a, b) => a.policy.localeCompare(b.policy),
        },
        
      ];

  return (
    <div className="container my-3">
    <h3> Stock Detail: {taxpl?.[0]?.tradingsymbol} - {slug}</h3>
    <div>
    <Tabs
    defaultActiveKey="1"
    items={[
      {
        label: 'Tax P&L',
        key: '1',
        children: <> 
        <div className='d-flex justify-content-center align-items-center'>
         <Statistic  title="Realised" value={taxpl?.map((ele)=>ele.pl).reduce((a,b)=>a+b,0).toFixed(2)} />
         <Statistic title="Invested" className='mx-3' value={taxpl?.map((ele)=>ele.deduct_quantity*ele.buy_price).reduce((a,b)=>a+b,0).toFixed(2)} />
         
         </div>
        <Table loading={isLoading} dataSource={taxpl} columns={sellColumn} /></>,
      },
      {
        label: 'Tradebook',
        key: '2',
        children: <Table loading={isLoading3} dataSource={trades?.data ?? []} columns={tradeColumns} />,
        // disabled: true,
      },
      {
        label: 'Breakdown',
        key: '3',
        children: 
        <>
        <div className='d-flex justify-content-center align-items-center'>
         <Statistic  title="Current" value={0} />
         <Statistic title="Invested" className='mx-3' value={breakdown?.data?.map((ele)=>ele.quantity*ele.average_price).reduce((a,b)=>a+b,0).toFixed(2)} />
         
         </div>
        <Table loading={isLoading2} dataSource={breakdown?.data ?? []} columns={tradeColumns} />,
</>
      },
    ]}
  />
    </div>
   
 </div>
  )
}

export default IndividualStock