import React, { useState } from "react";
import { instrumentsearchPost } from "../api/api";
import { useSelector, useDispatch } from "react-redux";
import { technicaldata ,showgtt} from "../../features/counterSlice";

function SearchModal() {
  const modal = useSelector((state) => state.counter.modalask);
  const [InstrumentData, setInstrumentData] = useState([]);
  const onInstrumentSearch = async (e) => {
    let data = await instrumentsearchPost(e.target.value);
    setInstrumentData(data.data);
  };
  const dispatch = useDispatch();
  return (
    <>
      <div
        className="modal fade"
        id="searchmodal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content position relative p-4 ">
            <div className="d-flex border">
              <span className="border-0 p-2">
                <i className="bi bi-search"></i>
              </span>
              <input
                type="text"
                onChange={(e) => onInstrumentSearch(e)}
                className="py-2  border-0 w-100 "
                placeholder="Search eg: infy bse, nifty fut, nifty weekly, gold mcx"
              />
            </div>

            {InstrumentData.length > 0 ? (
              <div className="position-relative bg-white">
                <ul className="omnisearch-results bg-white">
                  {InstrumentData?.map((item, i) => {
                    return (
                      <li
                        key={i}
                        className="search-result-item selected"
                        type='button'
                        data-bs-toggle="modal" data-bs-target={modal}
                        onClick={() => {
                          dispatch(technicaldata({ data: item }));
                          dispatch(showgtt(true));
                        }}
                      >
                        <span className="symbol">
                          <span className="tradingsymbol">
                            {item?.tradingsymbol}
                          </span>
                        </span>
                        <span className="exchange">
                          <span className="company">{item?.name}</span>
                          <span className="exchange-tag text-label text-label-outline NSE">
                            {item.exchange}
                          </span>
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            ) : null}
            <div className="modal-body">...</div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="button" className="btn btn-primary">
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SearchModal;
