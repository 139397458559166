import React from 'react'
import {
    BrowserRouter as Router,
    Route,
    Routes,
    useLocation,
    useNavigate,
  } from "react-router-dom";
  import {  NavLink, Link } from "react-router-dom";
import Navbar from './component/Navbar';
// import Performance from './component/Performance';
// import Trending from './component/maintab/Trending';
import Week52high from './component/maintab/Week52high';
// import Week52low from './component/maintab/Week52low';
// import Mostactive from './component/maintab/Mostactive';
// import Topgainers from './component/maintab/Topgainers';
// import Toplosers from './component/maintab/Toplosers';
import { useSelector } from 'react-redux';
// import '../App.css';
import { Tabs } from 'antd';
import { Typography } from 'antd';
const { Title } = Typography;


function Home2() {
  const mode = useSelector((state) => state.counter.theme);
  const onChange = (key) => {
    console.log(key);
  };
  const items = [
    {
      key: 'Performance',
      label: <NavLink to=''>52 Week high</NavLink>,
      // children: 'Content of Tab Pane 1',
    },
    {
      key: 'weekhigh',
      label: <NavLink to='weekhigh'>Weekhigh</NavLink>,
      // children: 'Content of Tab Pane 2',
    },
    {
      key: 'weeklow',
      label: <NavLink to='weeklow'>weeklow</NavLink>,
      // children: 'Content of Tab Pane 2',
    },
    {
      key: 'mostactive',
      label: <NavLink to='mostactive'>mostactive</NavLink>,
      // children: 'Content of Tab Pane 2',
    },
    {
      key: 'topgainers',
      label: <NavLink to='topgainers'>topgainers</NavLink>,
      // children: 'Content of Tab Pane 2',
    },
    {
      key: 'toplosers',
      label: <NavLink to='toplosers'>toplosers</NavLink>,
      // children: 'Content of Tab Pane 2',
    },
    
  ];
  return (
    <>
      <Navbar/>
      <div>
      
      
      
      <div className='text-center mt-3'>
      {/* <h2 className='text-center py-3  fs-4'>Trending Shares in India: Performance</h2> */}
      <Title  level={2}>Trending Shares in India: Performance</Title>
  {/* <div className='my-2' > */}


  {/* <div class="container d-flex justify-content-center">
  <ul className={mode?"justify-content-center bg-dark text-white list-inline":"justify-content-center list-inline"}>
    <li class="list-inline-item p-2">
    <NavLink className={({ isActive }) =>
                        isActive ? `active ${mode?'bg-dark text-white border-white':'text-orange border-orange'} py-3 border-bottom   px-4` : ` ${mode?"text-white":"text-default"} py-3  px-4 `
                      } to="">52 Week High</NavLink>
    </li>
    <li class="list-inline-item p-2">
    <NavLink className={({ isActive }) =>
                        isActive ? `active  py-3 border-bottom ${mode?'bg-dark text-white border-white':'text-orange border-orange'}   px-4` : ` ${mode?"text-white":"text-default"}  py-3  px-4`
                      } to='weekhigh'>Trending Stocks</NavLink>
    </li>
    <li class="list-inline-item p-2">
    <NavLink className={({ isActive }) =>
                        isActive ? `active  py-3 border-bottom ${mode?'bg-dark text-white border-white':'text-orange border-orange'}   px-4` : ` ${mode?"text-white":"text-default"} py-3  px-4`
                      } to='weeklow'>52 Week Low</NavLink>
    </li>
    <li class="list-inline-item p-2">
    <NavLink className={({ isActive }) =>
                        isActive ? `active  py-3 border-bottom ${mode?'bg-dark text-white border-white':'text-orange border-orange'}  px-4` : `${mode?"text-white":"text-default"}  py-3  px-4`
                      } to='mostactive'>Most Active</NavLink>
    </li>
    <li class="list-inline-item p-2">
    <NavLink className={({ isActive }) =>
                        isActive ? `active  py-3 border-bottom ${mode?'bg-dark text-white border-white':'text-orange border-orange'}  px-4` : `${mode?"text-white":"text-default"}  py-3  px-4`
                      } to='topgainers'>Top Gainers</NavLink>
    </li>
    <li class="list-inline-item p-2">
    <NavLink className={({ isActive }) =>
                        isActive ? `active  py-3 border-bottom  ${mode?'bg-dark text-white border-white':'text-orange border-orange'} px-4` : `${mode?"text-white":"text-default"}  py-3  px-4`
                      } to='toplosers'>Top Losers</NavLink>
    </li>
  </ul>
</div> */}

      {/* <ul className={mode?"d-flex align-items-center justify-content-center bg-dark text-white":"d-flex align-items-center  justify-content-center list-inline"}>
    <li >
     
    </li>
    <li >
      
    </li>
    <li >
      
    </li>
    <li >
     
    </li>
    <li >
     
    </li>
    <li >
      
    </li>
  </ul> */}
  <div className='d-flex justify-content-center'>
  <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
  </div>
  {/* </div> */}


      <div className='pt-4 pb-5'>
      <Routes>
              <Route exact path="/*" element={<Week52high/>} />
              {/* <Route exact path="/company/:id" element={<Company/>} /> */}
              {/* <Route exact path="weekhigh/*" element={<Trending/>} />
              <Route exact path="weeklow" element={<Week52low/>} />
              <Route exact path="mostactive" element={<Mostactive/>} />
              <Route exact path="topgainers" element={<Topgainers/>} />
              <Route exact path="toplosers" element={<Toplosers/>} /> */}
            
            </Routes>
            </div>

  {/*  body * */}


  </div>
      </div>
    </>)
}

export default Home2
