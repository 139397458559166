import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  getConsoleBreakdown,
  getpolicy_name,
  getunofficialholding,
  getupdateunofficiabreakdown,
  updatetradebook,
  uploadPolicyToZerodah,

} from "../api/api";

import { Button, Empty, Select, Skeleton, Table, Upload, message, notification } from "antd";

import { UploadOutlined } from '@ant-design/icons';

import dateFormat from "dateformat";
import { forOwn, groupBy } from "lodash";

import { websocketSend } from "../../features/counterSlice";
import {
  useQuery,

} from '@tanstack/react-query'



import DropdownOrderMenu from "../components/DropdownOrderMenu";
import { exportToExcel, excelToJson } from "../../libs/exportToExcel";
import xirr from "xirr"



function Holdings2() {
  const dispatch = useDispatch();
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (title, description, state) => {
    api[state]?.({
      message: title,
      description: description,
      duration: 0,
    });
  };

  // ---------------------
  const [breakdown, setbreakdown] = useState([]);
  // ----------------

  const [holdingdata, setholdingdata] = useState([]);
  const [allbreakdown, setallbreakdown] = useState([]);
  const [holdingdownload, setholdingdownload] = useState("holdings");


  const [holdingdatabkp, setholdingdatabkp] = useState([]);
  const [allbreakdownbkp, setallbreakdownbkp] = useState([]);
  const [policyname, setpolicyname] = useState([]);
  const [showpolicyname, setshowpolicyname] = useState(false);

  const [dloading, setdloading] = useState(false);
  const [updateloading, setupdateloading] = useState(false);
  const wsReceivedata = useSelector((state) => state.counter.websocket_receive);

  const options = [
    {
      value: "holdings",
      label: "Holdings",
    },
    {
      value: "breakdown_with_breakdown",
      label: "Holdings with breakdown",
    },
    {
      value: "breakdown",
      label: "Breakdown",
    },
    {
      value: "breakdown_holding",
      label: "Breakdown Holding",
    }
  ]

  const optionsAllPolicy = [
    {
      value: "all",
      label: "All stock",
    },
    {
      value: "policy",
      label: "Policy",
    },
  ]

  const optionsPolicy = policyname.map((e) => {
    return {
      value: e.tag_name,
      label: e.tag_name,
    }
  })

  const fetchBreakdown = async () => {
    setdloading(true)
    const breakdownData = await getConsoleBreakdown()

    const holding = await getunofficialholding();

    holding?.data?.forEach((ele, i) => {

      
      ele.key = i
      const filterData=breakdownData?.data?.filter((f) => f.isin == ele.isin)
      
      const xirrArray=filterData.map((ele2)=>{return {amount: ele2.quantity*ele2.price, when: new Date(ele2.order_execution_time)}}) 
      
      const totalXirrQty=filterData.map((g)=>g.quantity).reduce((a,b)=>a+b,0)
      
      xirrArray.push({amount: -totalXirrQty*ele.last_price, when: new Date()})

      let xirrValue
      
      try {
        xirrValue= xirr(xirrArray)
      } catch (error) {
        xirrValue= 0
      }

      ele.xirr=xirrValue
      
      filterData.forEach((g) => {
        g.instrument_token = ele.instrument_token
        g.last_price = ele.last_price
        g.day_change = ele.day_change
        g.day_change_percentage = ele.day_change_percentage
      })
    })

    setbreakdown(breakdownData?.data)
    setholdingdatabkp(holding?.data)
    console.log(holding.data);
    setholdingdata(holding?.data)
    let data = await getpolicy_name();
    setpolicyname(data?.data);
    setdloading(false)
  }

  useEffect(() => {
    
    return () => {
      let intrument = holdingdata?.map((ele) => ele.instrument_token)
      let message = { a: "unsubscribe", v: intrument }
      dispatch(websocketSend(message))
    }
  }, [])

  const query = useQuery({ queryKey: ['holdings'], queryFn: fetchBreakdown })
 

  useCallback(() => {
    holdingdata.forEach((ele) => {
      let ltp = wsReceivedata?.find((e) => ele.instrument_token == e.instrument_token)?.last_price
      let change = wsReceivedata?.find((e) => ele.instrument_token == e.instrument_token)?.change
      if (ltp) {
        ele.last_price = ltp
        ele.day_change_percentage = change
        ele.day_change = ltp - (ltp / (1 + (change / 100)))
      }
    })
  }, [wsReceivedata, holdingdata])

  const exportFile = async () => {
    let downloaddata = [];

    holdingdata.forEach((ele) => {


      if (holdingdownload === "breakdown_with_breakdown" || holdingdownload === "holdings") {
        let obj = {
          Date: "-",
          Time: "-",
          Instrument: ele.tradingsymbol,
          Exchange: ele.exchange,
          isin: ele.isin,
          Qty: ele.quantity + (ele.t1_quantity ?? 0),
          "Buy price": ele.average_price,
          Ltp: ele.last_price,
          Invested: ele.average_price * (ele.quantity + (ele.t1_quantity ?? 0)),
          "Current Value": ele.last_price * (ele.quantity + (ele.t1_quantity ?? 0)),
          "Total P&L amount": ((ele?.last_price - ele?.average_price) * ele?.quantity),
          "Total change/share amt": ((ele.last_price - ele.average_price) * ele.quantity),
          "total chg %": ((ele.pnl * 100) / (ele.average_price * ele.quantity)),
          "Day chg. amt": (ele.quantity * ele.day_change),
          "Day chg./share": ele?.day_change,
          "Day chg.%": ele.day_change_percentage,
          xirr: ele.xirr,
          Realised: ele.realised2
        };
        downloaddata.push(obj);
      }
      if (
        holdingdownload === "breakdown" ||
        holdingdownload === "breakdown_with_breakdown"
      ) {
        let filterdata = allbreakdown.filter(
          (ele2) => ele2.isin == ele.isin
        );


        filterdata.forEach((element) => {
          let obj = {
            Date: { t: "d", v: new Date(element.order_execution_time) },
            Time: dateFormat(element.order_execution_time, "HH:MM"),
            Instrument: ele.tradingsymbol,
            Exchange: element.exchange,
            Qty: element.quantity,
            "Buy price": element.price,
            Ltp: ele.last_price,
            Invested: element.average_price * element.quantity,
            "Current Value": element.last_price * element.quantity,
            "P&L price": (ele.last_price - element.average_price) * element.quantity,

            "Net change": ele.last_price - element.average_price,
            "Net change %": (ele.last_price - element.average_price) / (element.average_price),
            "Trade id": element.trade_id,
            Policy: element.policy,
          };

          downloaddata.push(obj);
        });

        downloaddata.push({});
      }

      if (holdingdownload === "breakdown_holding") {
        let filterdata = allbreakdown.filter(
          (ele2) => ele.isin == ele2.isin
        );
        let total_qty = filterdata
          ?.map((ele2) => ele2.quantity)
          ?.reduce((a, b) => a + b, 0);
        let invested2 = filterdata
          .map((ele2) => ele2.quantity * ele2.average_price)
          .reduce((a, b) => a + b, 0)?.toFixed(2);
        let current2 = filterdata
          .map((ele2) => ele2.quantity * ele.last_price)
          .reduce((a, b) => a + b, 0)?.toFixed(2);
        let buy_avg =
          ((filterdata
            ?.map((ele2) => ele2.quantity * ele2.average_price)
            .reduce((a, b) => a + b, 0)) / total_qty).toFixed(2);
        let pnl = (filterdata
          .map((ele2) => ele2.quantity * (ele.last_price - ele2.average_price))
          .reduce((a, b) => a + b, 0)).toFixed(2);


        let obj = {
          Date: "Breakdownholding",
          Time: "-",
          Symbol: filterdata[0]?.tradingsymbol,
          Exchange: filterdata[0]?.exchange,
          Quantity: { t: "n", v: total_qty },
          "Buy price": { t: "n", v: buy_avg },

          LTP: { t: "n", v: ele.last_price },
          invested: {
            t: "n",
            v: invested2,
          },
          "Current Value": {
            t: "n",
            v: current2,
          },
          "P&L price": {
            t: "n",
            v: pnl,
          },
          "Day Change": { t: "n", v: Number(ele.day_change * ele.quantity)?.toFixed(2) },
          "Day Change %": {
            t: "n",
            v: Number(ele.day_change_percentage)?.toFixed(2),
          },
          "Net change": {
            t: "n",
            v: Number(ele.pnl / (ele.quantity + (ele.t1_quantity ?? 0))).toFixed(2),
          },
          "Net change %": {
            t: "n",
            v: (((ele.last_price - ele.average_price) / ele.average_price) * 100).toFixed(2),
          },
          "Quantity diff": {
            t: "n",
            v: ele.quantity + (ele.t1_quantity ?? 0) - total_qty,
          },
          "Invested diff": {
            t: "n",
            v: (((ele.quantity + (ele.t1_quantity ?? 0)) * ele.average_price) - invested2).toFixed(2),
          },

          "Current diff": {
            t: "n",
            v: (((ele.quantity + (ele.t1_quantity ?? 0)) * ele.last_price) - current2).toFixed(2),
          },
          isin: ele.isin,
        };
        downloaddata.push(obj);
      }
    });

    await exportToExcel(downloaddata)

  };

  const invested = holdingdata?.map((ele) => (ele.quantity + (ele.t1_quantity ?? 0)) * ele.average_price)
    ?.reduce((a, b) => a + b, 0)
    .toFixed(2).toString().split('.')

  const current = holdingdata?.map((ele) => Number(ele.last_price ?? 0) * ((Number(ele.quantity) ?? 0) + Number(ele.t1_quantity ?? 0)))
    ?.reduce((a, b) => a + b, 0)
    ?.toFixed(2).toString().split('.')

  const dayPL = holdingdata
    ?.map((ele) => ((ele.day_change ?? 0) * (ele.quantity + (ele.t1_quantity ?? 0))))
    ?.reduce((a, b) => a + b, 0)
    .toFixed(2).toString().split('.')


  const totalPL = holdingdata
    ?.map((ele) =>
      ((ele.last_price ?? 0) - ele.average_price ?? 0) *
      ((ele.quantity ?? 0) + (ele?.t1_quantity ?? 0)))
    ?.reduce((a, b) => a + b, 0)
    .toFixed(2).toString().split('.')

  const tableColumns2 = [
    {
      title: "Instrument",
      dataIndex: "tradingsymbol",
      width: 200,
      sorter: (a, b) => a.tradingsymbol.localeCompare(b.tradingsymbol),
      render: (_, elm) => (
        <div className="d-flex justify-content-between ">
          <div className="text-left">{elm.tradingsymbol}</div>
          <div>{elm.t1_quantity > 0 ? `T1:${elm.t1_quantity}` : ""}</div>
          <div className="d-flex text-wrap">
            <div>
              <DropdownOrderMenu data={elm} />
            </div>
          </div>
        </div>
      ),

    },
    {
      title: "Qty.",
      dataIndex: "quantity",
      sorter: (a, b) => a?.quantity - b?.quantity,
      render: (_, elm) => <span className="text-nowrap">{elm?.quantity?.toFixed(2) ?? "N/A"}</span>,
    },
    {
      title: "Avg. cost",
      dataIndex: "average_price",
      sorter: (a, b) => a.average_price - b.average_price,
      render: (_, elm) => <span className="text-nowrap">{elm?.average_price?.toFixed(2)}</span>,
    },
    {
      title: "LTP",
      dataIndex: "last_price",
      sorter: (a, b) => a.last_price - b.last_price,
      render: (_, elm) => <span className="text-nowrap">{elm?.last_price?.toFixed(2) ?? 'N/A'}</span>,
    },
    {
      title: "Invested",
      dataIndex: "curval2",
      sorter: (a, b) =>
        a.average_price * a.quantity - b.average_price * b.quantity,
      render: (_, elm) => <span className="text-nowrap">{(elm?.average_price * elm?.quantity)?.toFixed(2)}</span>,
    },
    {
      title: "Cur. val",
      dataIndex: "curval",
      sorter: (a, b) => a.last_price * a.quantity - b.last_price * b.quantity,
      render: (_, elm) => <span className="text-nowrap">{(elm?.last_price * elm?.quantity)?.toFixed(2)}</span>,
    },
    {
      title: "Total P&L amount",
      dataIndex: "pl",
      sorter: (a, b) => a?.pnl - b?.pnl,
      render: (_, elm) => (
        <span className={elm.pnl > 0 ? "text-success text-nowrap" : "text-danger text-nowrap"}>
          {((elm?.last_price - elm?.average_price) * elm?.quantity)?.toFixed(2)}
        </span>
      ),
    },
    {
      title: "total /share change amt ",
      dataIndex: "netcharge",
      sorter: (a, b) => ((a.last_price - a.average_price) * a.quantity) - ((b.last_price - b.average_price) * b.quantity),


      render: (_, elm) => (
        <span className={elm?.last_price - elm?.average_price > 0 ? "text-success text-nowrap" : "text-danger text-nowrap"}>
          {((elm?.last_price - elm?.average_price) * elm?.quantity).toFixed(2)}
        </span>
      ),
    },
    {
      title: "total chg %",
      dataIndex: "netcharge",
      sorter: (a, b) =>
        (a.pnl * 100) / (a.average_price * a.quantity) -
        (b.pnl * 100) / (b.average_price * b.quantity),
      render: (_, elm) => (
        <span className={elm.pnl > 0 ? "text-success" : "text-danger"}>
          {elm.average_price != 0 ? ((elm.pnl * 100) / (elm.average_price * elm.quantity))?.toFixed(2) : 0}
        </span>
      ),
    },
    
    {
      title: "Day chg. amt",
      dataIndex: "daycharge",
      sorter: (a, b) => a.quantity * a.day_change - b.quantity * b.day_change,
      render: (_, elm) => (
        <span className={`text-nowrap ${elm.day_change > 0 ? "text-success" : "text-danger"}`}>
          {(elm.quantity * elm.day_change)?.toFixed(2)}
        </span>
      ),
    },
    {
      title: "Day chg./share",
      dataIndex: "daycharge",
      sorter: (a, b) => a.quantity * a.day_change - b.quantity * b.day_change,
      render: (_, elm) => (
        <span className={`text-nowrap ${elm.day_change > 0 ? "text-success" : "text-danger"}`}>
          {elm?.day_change?.toFixed(2)}
        </span>
      ),
    },
    {
      title: "Day chg.%",
      dataIndex: "daycharge_percent",
      sorter: (a, b) => a.day_change_percentage - b.day_change_percentage,
      render: (_, elm) => (
        <span className={`text-nowrap ${elm.day_change_percentage > 0 ? "text-success" : "text-danger"}`}>
          {(elm.day_change_percentage)?.toFixed(2)}%
        </span>
      ),
    },
    {
      title: "XIRR",
      dataIndex: "xirr",
      sorter: (a, b) => a.xirr - b.xirr,
      render: (_, elm) => <span className={elm.xirr > 0 ? 'text-success' : 'text-danger'}>{elm.xirr?.toFixed(2)}</span>


    },
    {
      title: "Realised",
      dataIndex: "realised2",
      sorter: (a, b) => a.realised2 - b.realised2,


    },
    

  ];

  const tableColumns = [
    {
      title: "Date",
      dataIndex: "order_execution_time",
      sorter: (a, b) => new Date(a.order_execution_time) - new Date(b.order_execution_time),
      render: (_, elm) => <span className="text-nowrap"> {dateFormat(elm?.order_execution_time, "dd-mm-yyyy")}</span>,
    },

    {
      title: "Qty.",
      dataIndex: "quantity",
      sorter: (a, b) => a.quantity - b.quantity,
      render: (_, elm) => <span className="text-nowrap">{elm.quantity?.toFixed(2)}</span>,
    },
    {
      title: "Price",
      dataIndex: "price",
      sorter: (a, b) => a.price - b.price,
      render: (_, elm) => <span className="text-nowrap">{elm.price?.toFixed(2)}</span>,
    },
    {
      title: "LTP",
      dataIndex: "last_price",
      sorter: (a, b) => a.last_price - b.last_price,
      render: (_, elm) => <span className="text-nowrap">{elm.last_price?.toFixed(2)}</span>,
    },

    {
      title: "P&L",
      sorter: (a, b) => ((a.last_price - a.price) * a.quantity) - ((b.last_price - b.price) * b.quantity),
      render: (_, elm) =>
        <span className="text-nowrap">{((elm.last_price - elm.price) * elm.quantity)?.toFixed(2)}</span>,
    },

    {
      title: "Policy ",
      dataIndex: "policy",
    },

  ];


  const expandedRowRender = (a) => {

    const data = breakdown.filter((ele) => ele.isin == a.isin)

    return (
      <>
        <Table
          bordered
          dataSource={data} columns={tableColumns}
          sticky={{ offsetHeader: 0 }}
        />
      </>
    );
  };

  const changepolicyname = async (e) => {
    

    const policyBreakdown = breakdown.filter((ele) => ele.policy === e);

    let group = groupBy(policyBreakdown, "isin");
    let array2 = [];


    forOwn(group, function (value, key) {

      const quantity = value.map((ele2) => Number(ele2.quantity) ?? 0)?.reduce((a, b) => a + b, 0)
      const average_price = value.map((ele2) => ele2.price * ele2.quantity)?.reduce((a, b) => a + b, 0) / quantity
      const pnl = value.map((ele) => (Number(ele.last_price) - Number(ele.price)) * Number(ele.quantity))
        ?.reduce((a, b) => a + b, 0)
      const xirrArray = value.map((d) => { return { amount: d.quantity * d.price, when: new Date(d.order_timestamp) } })

      xirrArray.push({ amount: -value.map((d) => d.quantity).reduce((a, b) => a + b, 0) * value[0].last_price, when: new Date() })
      let xirrvalue
      try {
        xirrvalue = (xirr(xirrArray) * 100).toFixed(2)
      } catch (error) {
        xirrvalue = 0
      }

      const obj = {
        tradingsymbol: value[0].tradingsymbol,
        quantity,
        average_price,
        last_price: value[0].last_price,
        pnl,
        instrument_token: value[0].instrument_token,
        day_change: value[0].day_change,
        day_change_percentage: value[0].day_change_percentage,
        isin: key,
        key,
        xirr: xirrvalue
      };
      array2.push(obj);



    });



    setholdingdata(array2);
    setallbreakdown(policyBreakdown);
  };

  const changepolicy = async (e) => {
    console.log(e);
    if (e === "all") {
      setshowpolicyname(false);
      setholdingdata(holdingdatabkp);
      setallbreakdown(allbreakdownbkp);
    }

    else setshowpolicyname(true);
  };



  const customRequest = async ({ file, onSuccess, onError }) => {
    try {
      const result = await excelToJson(file)
      const data = result.map((ele) => {
        return {
          target_id: `${ele.target_id}`,
          instrument_id: ele.instrument_id,
          added_tag_ids: ele.added_tag_ids,
          target_type: ele.target_type
        }
      })

      await uploadPolicyToZerodah(data);
      openNotification("Success", "Tag has been modified in zerodha , Please update breakdown after few minutes", "success")

    } catch (error) {
      openNotification("Error", "Some error occured while defining tag in zerodah", "error")
    }
  };

  const handleChange = (info) => {
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const updateHolding = async () => {
    try {
      const result = await updatetradebook()
      if (result.data == 'ok') openNotification("Success", "Holding has been updated", "success")
      else openNotification("Error", "Something went wrong , Please try again", "error")
    } catch (error) {
      console.log(error);
    }
  }

  return (

    <div className=" allpadding mb-5 pb-5 ">

      {contextHolder}


      <div className="d-flex justify-content-between pb-2">
        <h4>Holdings({holdingdata?.length})</h4>
        <div className="d-flex align-items-center ">
          <div>
            <Button

              onClick={async () => {
                setupdateloading(true);
                let data = await getupdateunofficiabreakdown();
                if (data.status == 200) {
                  openNotification("Success", "Breakdown Has been updated , You can now start filtering using tag/policy", "success")

                }
                else openNotification("Error", "Some Error occur ,Please retry", "error")

                setupdateloading(false);

              }}
            >
              {updateloading ? "Updating..." : "Update breakdown"}
            </Button>

            <Button className="mx-2" onClick={updateHolding}>
              Update holding
            </Button>
          </div>

          <Select
            defaultValue="all"
            onChange={(e) => { changepolicy(e); }}
            style={{ width: 200 }}
            options={optionsAllPolicy}
          />
          {showpolicyname &&
            <Select
              defaultValue={optionsPolicy[0].value}
              onChange={(e) => { changepolicyname(e); }}
              style={{ width: 200 }}
              options={optionsPolicy}
            />


          }
        </div>
      </div>
      
      <div className="d-flex justify-content-between align-items-end border-top border-bottom py-3">
        <div>

          <Upload
            customRequest={customRequest}
            showUploadList={false}
            onChange={handleChange}
          >
            <Button icon={<UploadOutlined />}>Click to Upload Tag in zerodah</Button>
          </Upload>
        </div>

        <div className="d-flex ">
          <Select

            defaultValue="holdings"
            onChange={(e) => { setholdingdownload(e); }}
            style={{ width: 200 }}
            options={options}
          />

          <Button className="ms-2" loading={dloading} type="primary" onClick={exportFile}>
            Download
          </Button>
        </div>
      </div>

      {holdingdata?.length>0?

<>
      <div>


        
        <Table
          bordered
          scroll={{
            x: 1400,
            
          }}
          loading={dloading}
          pagination={false}
          columns={tableColumns2}
          size="middle"

          expandable={{
            expandedRowRender,

          }}
          locale={
            holdingdata?.length === 0 && <Skeleton active={true} />
          }
          dataSource={holdingdata}

          sticky={{
            offsetHeader: 0,
          }}
        />
        {holdingdata?.length == 0 && <Empty />}
        
        </div>
      <div>
        <div className="p-3 d-flex justify-content-between my-5 flex-column flex-md-row ">

          <div className="my-3 my-lg-0  ">
            <div >Total investment</div>
            {dloading ? <Skeleton.Input active={true} /> :
              <div className={`d-flex  align-items-center `}>
                <div>
                  <h2 >
                    {Number(invested?.[0] ?? 0)?.toLocaleString('en-IN')}
                  </h2>
                </div>

                <div>
                  <span>.{invested?.[1] ?? 0}</span>
                </div>
              </div>
            }
          </div>

          <div className="my-3 my-lg-0  ">
            <div >Current value</div>
            {dloading ? <Skeleton.Input active={true} /> :
              <div className={`d-flex  align-items-center `}>
                <div>
                  <h2 >
                    {Number(current?.[0])?.toLocaleString('en-IN')}
                  </h2>
                </div>

                <div>
                  <span>.{current?.[1]}</span>
                </div>
              </div>
            }
          </div>


          <div className="my-3 my-lg-0  ">

            <div >Day's P&L</div>
            {dloading ? <Skeleton.Input active={true} /> : <>
              <div className={`d-flex  align-items-center ${Number(dayPL?.[0]) > 0 ? 'text-success' : 'text-danger'} `}>
                <div>
                  <h2 className={`${Number(dayPL?.[0] ?? 0) > 0 ? 'text-success' : 'text-danger'} `}>
                    {Number(dayPL?.[0])?.toLocaleString('en-IN')}
                  </h2>
                </div>

                <div>
                  <span>.{dayPL?.[1]}</span>
                </div>
              </div>
              <div className={`${Number(dayPL?.[0]) > 0 ? 'text-success' : 'text-danger'}`}>
                ({Number(dayPL?.[0]) > 0 ? "+" : " "} {((Number(dayPL?.[0]) / Number(current?.[0])) * 100).toFixed(2)}%)
              </div>
            </>
            }
          </div>

          <div className="my-3 my-lg-0  ">
            <div >Total P&L</div>
            {dloading ? <Skeleton.Input active={true} /> : <>
              <div className={`d-flex  align-items-center ${Number(totalPL?.[0]) > 0 ? 'text-success' : 'text-danger'} `}>
                <div>
                  <h2 className={`${Number(totalPL?.[0]) > 0 ? 'text-success' : 'text-danger'} `}>
                    {Number(totalPL?.[0])?.toLocaleString('en-IN')}
                  </h2>
                </div>

                <div>
                  <span>.{totalPL?.[1]}</span>
                </div>
              </div>
              <div className={`${Number(totalPL?.[0]) > 0 ? 'text-success' : 'text-danger'}`}>
                ({Number(totalPL?.[0]) > 0 ? "+" : "-"} {((Number(totalPL?.[0]) / Number(invested?.[0])) * 100).toFixed(2)}%)
              </div>
            </>}
          </div>
        </div>
      </div>
      </>
:
<>
 <h4>Holdings</h4>
<Empty/>
</>
}


    </div>
  );
}

export default Holdings2;
