
// import { BASEURL } from "../../zerodha/BaseURL"

import { BASEURL } from "../../zerodha/BaseURL";

const home = async (exchange) => {
  

    const myHeaders = new Headers();
    const token = localStorage.getItem("requesttoken");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);
    const getdata = await fetch(BASEURL + `home/weekhigh/performance?exchange=${exchange}`, {
      method: "GET",
      headers: myHeaders,
     
    });
    let  data = await getdata.json();
    return data
  };

  const home2 = async (exchange) => {
  

    const myHeaders = new Headers();
    const token = localStorage.getItem("requesttoken");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);
    const getdata = await fetch(BASEURL + `home/weekhigh/performance2?exchange=${exchange}`, {
      method: "GET",
      headers: myHeaders,
     
    });
    let  data = await getdata.json();
    return data
  };



  const reloaddata=async(exchange)=>{
    const myHeaders = new Headers();
    const token = localStorage.getItem("requesttoken");
    const accesstoken = JSON.parse(localStorage.getItem("fyrestoken"));
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);
    const getdata = await fetch(BASEURL + `home/reload`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({        
        accesstoken,exchange:exchange        
      }),     
    });
    let  data = await getdata.json();
    return data
  }


  



 




export {home,reloaddata,home2}