import { json } from "react-router-dom";
import * as xlsx from "xlsx";

export const exportToExcel=async(downloaddata)=>{
try {
    const ws = xlsx.utils.aoa_to_sheet([[]]);
    xlsx.utils.sheet_add_json(ws, downloaddata, {
        dateNF: "dd-mm-yyyy",
        cellDates: true,
      });
      const wb = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(wb, ws, "Data");
  
      xlsx.writeFile(wb, "Breakdown_holding.xlsx");
} catch (error) {
    console.log(error);
}
}


// export const excelToJson=async(file)=>{

//     try {
        
        
//         const reader = new FileReader();
        
//         reader.onload = async (e) => {
//         const data = e.target.result;
        
//         const workbook = xlsx.read(data, { type: 'array' });
//         const sheetName = workbook.SheetNames[0];
//         const worksheet = workbook.Sheets[sheetName];
//         const json = xlsx.utils.sheet_to_json(worksheet);
//         return json
        
//       };
      
//       reader.readAsArrayBuffer(file);
      
      
      
//     } catch (error) {
//         console.log(error);
//     }
// }


export const excelToJson = (file) => {
    return new Promise((resolve, reject) => {
      try {
        const reader = new FileReader();
  
        reader.onload = (e) => {
          try {
            const data = e.target.result;
  
            const workbook = xlsx.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const json = xlsx.utils.sheet_to_json(worksheet);
  
            resolve(json);
          } catch (error) {
            reject(error);
          }
        };
  
        reader.onerror = (error) => {
          reject(error);
        };
  
        reader.readAsArrayBuffer(file);
      } catch (error) {
        reject(error);
      }
    });
  };
  