import { Button, Input, Modal } from "antd";
import React, { useState } from "react";





import BasketModal2 from "./Basketmodal2";

const BasketModal = () => {
  const [open2, setOpen2] = useState(false);
  const [title, settitle] = useState("");

  return (
    <>
      <button  className="btn btn-sm btn-primary" type="primary" onClick={() => setOpen2(true)}>
        New basket
      </button>
      <Modal
        open={open2}
        // width='fit-content'
        title="Create basket"
        onCancel={() => setOpen2(false)}
        footer={[
          <Button key="back" style={{ display: "none" }}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" style={{ display: "none" }}>
            Submit
          </Button>,
        ]}
      >
        <div>
          <Input
            // className="w-100 p-2"
            type="text"
            placeholder="Basket name"
            onChange={(e) => settitle(e.target.value)}
          />

          <div className="w-100 my-2 py-2">
            <BasketModal2 title={title} name="Create basket" />
          </div>
        </div>
      </Modal>
    </>
  );
};
export default BasketModal;

///###########################################################################

