
import technicalsSlice from './features/counterSlice'
import { configureStore } from '@reduxjs/toolkit'
import { zerodhapositionApi ,zerodhaordersApi,zerodhaholdingsApi,backtestprofitApi} from './features/zerodahapi'
import { profileApi,marginApi,holdingApi ,positionApi,orderApi,tradeApi} from './features/upstoxapi'


export const store = configureStore({
  reducer: { [profileApi.reducerPath]: profileApi.reducer,
    [marginApi.reducerPath]: marginApi.reducer,
    [holdingApi.reducerPath]: holdingApi.reducer,
    [positionApi.reducerPath]: positionApi.reducer,
    [orderApi.reducerPath]: orderApi.reducer,
    [tradeApi.reducerPath]: tradeApi.reducer,
    [zerodhaordersApi.reducerPath]: zerodhaordersApi.reducer,
    
    counter: technicalsSlice,
    [zerodhapositionApi.reducerPath]: zerodhapositionApi.reducer,
    [zerodhaholdingsApi.reducerPath]: zerodhaholdingsApi.reducer,
    

  },
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware().concat(profileApi.middleware,marginApi.middleware,holdingApi.middleware,positionApi.middleware,orderApi.middleware,tradeApi.middleware,zerodhapositionApi.middleware,zerodhaordersApi.middleware,zerodhaholdingsApi.middleware),
})