import React, {useState, useEffect} from 'react';
import {  NavLink, Link, useParams } from "react-router-dom";
import Navbar from './component/Navbar';
// import { useSelector } from 'react-redux';
// import '../css/custom.css';
// import '../css/normalize.css';
// import styles from '../css/skeleton.css';

import { BASEURL } from '../zerodha/BaseURL';
import $ from 'jquery';
// console.log(1111);



const Company = () => {
  // const mode = useSelector((state) => state.counter.theme);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [qrcolumn, setqrcolumn] = useState([]);
  const [plcolumn, setplcolumn] = useState([]);
  const [bscolumn, setbscolumn] = useState([]);
  const [cfcolumn, setcfcolumn] = useState([]);
  const [rtcolumn, setrtcolumn] = useState([]);
  const [shcolumn, setshcolumn] = useState([]);
  let params = useParams();
  console.log(params);

  useEffect(() => {
    if (params.id!=="") {
      import('../css/custom.css');
      import('../css/normalize.css');
      import('../css/skeleton.css');
    }
    getData();
  }, [])

  var addClassOnScroll = function () {
    var windowTop = $(window).scrollTop();
    $('section[id]').each(function (index, elem) {
        var offsetTop = $(elem).offset().top;
        var outerHeight = $(this).outerHeight(true);

        if( windowTop > (offsetTop - 100) && windowTop < ( offsetTop + outerHeight)) {
            var elemId = $(elem).attr('id');
            // console.log(elemId);
            $(".sub-nav a.active").removeClass('active');
            $(`a#${elemId}_active`).addClass('active');
            // $("sub-nav a[href='#" + elemId + "']").addClass('active');
        }
    });
};

$(function () {
    $(window).on('scroll', function () {
        addClassOnScroll();
    });
});

  const getData = async (id="") => {
    setLoading(true);
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    let data = await fetch(BASEURL + `home/get-symboldata`, {
      method: "post",
      body:JSON.stringify({symbol:params.id}),
      headers: myHeaders,
    });
    let apidata = await data.json();
    if (apidata.status === "200") {
      setData(apidata.data);   
      if(apidata.data && apidata.data.qrsqldata.length>0){
        let column = [];
        apidata.data.qrsqldata.forEach((element,i) => {
          if (i === 0) {            
          for (let item in element) {
            column.push(item);
          }            
        }
        });
        column = column.slice(-12);
        setqrcolumn(column);      
      }
     
      if(apidata.data && apidata.data.blsqldata.length>0){
        let column = [];
        apidata.data.blsqldata.forEach((element,i) => {
          if (i === 0) {            
          for (let item in element) {
            column.push(item);
          }            
        }
        });
        column = column.slice(-12);
        setbscolumn(column);      
      }
     
      if(apidata.data && apidata.data.plsqldata.length>0){
        let plcolumn = [];
        apidata.data.plsqldata.forEach((element,i) => {
          if (i === 0) {            
          for (let item in element) {
            plcolumn.push(item);
          }            
        }
        });
        plcolumn = plcolumn.slice(-12);
        setplcolumn(plcolumn);      
      }
      
      if(apidata.data && apidata.data.cfsqldata.length>0){
        let column = [];
        apidata.data.cfsqldata.forEach((element,i) => {
          if (i === 0) {            
          for (let item in element) {
            column.push(item);
          }            
        }
        });
        column = column.slice(-12);
        setcfcolumn(column);      
      }
   
      if(apidata.data && apidata.data.ratiossqldata.length>0){
        let column = [];
        apidata.data.ratiossqldata.forEach((element,i) => {
          if (i === 0) {            
          for (let item in element) {
            column.push(item);
          }            
        }
        });
        column = column.slice(-12);
        setrtcolumn(column);      
      }
   
      if(apidata.data && apidata.data.shareholdingsqldata.length>0){
        let column = [];
        apidata.data.shareholdingsqldata.forEach((element,i) => {
          if (i === 0) {            
          for (let item in element) {
            column.push(item);
          }            
        }
        });
        column = column.slice(-12);
        setshcolumn(column);      
      }
      
    }
  
    setLoading(false);
  };

  
  // console.log(data);
  return (
    <div>

      <Navbar/>

      {/* <div className={mode?"bg-dark text-white w-100":'  w-100 '} style={{overflowY:"scroll", background: "#EBEFF5"}} >
      
      
      
      <div className={mode?'mx-4 bg-dark text-white   shadow-sm ':'mx-4    shadow-sm '}>   
  <div className='my-2 ' >
      <ul className={mode?"d-flex bg-dark text-white companyheader":"d-flex companyheader"}>
    <li >
      <HashLink smooth to={'/company/RELIANCE#section-one'} className={({ isActive }) =>
                        isActive ? `active ${mode?'bg-dark text-white border-white':'text-orange border-orange'}  py-3 border-bottom   px-4` : ` ${mode?"text-white":"text-default"} py-3  px-4 `
                      }>Adani Enterp.</HashLink>
    </li>
    <li className='px-4'>
      <HashLink smooth to={'/company/RELIANCE#section-two'} className={({ isActive }) =>
                        isActive ? `active ${mode?'bg-dark text-white border-white':'text-orange border-orange'} py-3 border-bottom   px-4` : ` ${mode?"text-white":"text-default"} py-3  px-4 `
                      }>Chart</HashLink>
    </li>
    
    <li >
      <NavLink className={({ isActive }) =>
                        isActive ? `active  py-3 border-bottom ${mode?'bg-dark text-white border-white':'text-orange border-orange'}   px-4` : ` ${mode?"text-white":"text-default"} py-3  px-4`
                      } to='weeklow'>Analysis</NavLink>
    </li>
    <li >
      <NavLink className={({ isActive }) =>
                        isActive ? `active  py-3 border-bottom ${mode?'bg-dark text-white border-white':'text-orange border-orange'}  px-4` : `${mode?"text-white":"text-default"}  py-3  px-4`
                      } to='mostactive'>Peers</NavLink>
    </li>
    <li >
      <NavLink className={({ isActive }) =>
                        isActive ? `active  py-3 border-bottom ${mode?'bg-dark text-white border-white':'text-orange border-orange'}  px-4` : `${mode?"text-white":"text-default"}  py-3  px-4`
                      } to='topgainers'>Quarters</NavLink>
    </li>
    <li >
      <NavLink className={({ isActive }) =>
                        isActive ? `active  py-3 border-bottom  ${mode?'bg-dark text-white border-white':'text-orange border-orange'} px-4` : `${mode?"text-white":"text-default"}  py-3  px-4`
                      } to='toplosers'>Profit & Loss</NavLink>
    </li>
    <li >
      <NavLink className={({ isActive }) =>
                        isActive ? `active  py-3 border-bottom  ${mode?'bg-dark text-white border-white':'text-orange border-orange'} px-4` : `${mode?"text-white":"text-default"}  py-3  px-4`
                      } to='toplosers'>Balance Sheet</NavLink>
    </li>
    <li >
      <NavLink className={({ isActive }) =>
                        isActive ? `active  py-3 border-bottom  ${mode?'bg-dark text-white border-white':'text-orange border-orange'} px-4` : `${mode?"text-white":"text-default"}  py-3  px-4`
                      } to='toplosers'>Cash Flow</NavLink>
    </li>
    <li >
      <NavLink className={({ isActive }) =>
                        isActive ? `active  py-3 border-bottom  ${mode?'bg-dark text-white border-white':'text-orange border-orange'} px-4` : `${mode?"text-white":"text-default"}  py-3  px-4`
                      } to='toplosers'>Ratios</NavLink>
    </li>
    <li >
      <NavLink className={({ isActive }) =>
                        isActive ? `active  py-3 border-bottom  ${mode?'bg-dark text-white border-white':'text-orange border-orange'} px-4` : `${mode?"text-white":"text-default"}  py-3  px-4`
                      } to='toplosers'>Investors</NavLink>
    </li>
    <li >
      <NavLink className={({ isActive }) =>
                        isActive ? `active  py-3 border-bottom  ${mode?'bg-dark text-white border-white':'text-orange border-orange'} px-4` : `${mode?"text-white":"text-default"}  py-3  px-4`
                      } to='toplosers'>Documents</NavLink>
    </li>
  </ul>
  </div>
   */}

  <>

  <div className="sticky company-nav">
    <div className="flex flex-space-between container hide-from-tablet-landscape">
     
      <button
        type="button"
        className="a sub plausible-event-name=Notebook plausible-event-user=unregistered"
        onclick="Modal.openInModal(event)"
        data-url="/notebook/1284396/"
        data-title="Ideaforge Technology Ltd"
        data-is-right-modal="true"
        title="View Notebook"
      >
        <i className="icon-book-open" />
      </button>
    </div>
    <div className="sub-nav-holder sticky-top">
      <div className="sub-nav">
        <a id="top_active" href="#top"  className='active'>
          <span className="shrink-text show-from-tablet-landscape">
            {data.symboldata && data.symboldata && data.symboldata[0]?.name}
          </span>
          <span className="hide-from-tablet-landscape">Summary</span>
        </a>
        {/* <a id='chart' href="#chart">Chart</a> */}
        <a id='analysis_active' href="#analysis">Analysis</a>
        <a id='peers_active' href="#peers">Peers</a>
        <a id='quarters_active' href="#quarters">Quarters</a>
        <a id='profit-loss_active' href="#profit-loss">Profit &amp; Loss</a>
        <a id='balance-sheet_active' href="#balance-sheet">Balance Sheet</a>
        <a id='cash-flow_active' href="#cash-flow">Cash Flow</a>
        <a id='ratios_active' href="#ratios">Ratios</a>
        {/* <a id='shareholding_active' href="#shareholding">Investors</a> */}
        {/* <a id='documents_active' href="#documents">Documents</a> */}
        <div className="flex-grow flex show-from- -landscape">
          <div className="flex-grow" />
          <button
            type="button"
            className="a plausible-event-name=Notebook plausible-event-user=unregistered"
            onclick="Modal.openInModal(event)"
            data-url="/notebook/1284396/"
            data-title="Ideaforge Technology Ltd"
            data-is-right-modal="true"
          >
            <i className="icon-book-open" />
            Notebook
          </button>
        </div>
      </div>
    </div>
  </div>
  <main className="flex-grow container mt-5" style={{minWidth:"90%",background:"none"}}>
    <div className="breadcrumb" />
    <div
      data-company-id={1284396}
      data-warehouse-id={103227380}
      data-consolidated="true"
      id="company-info"
    ></div>
    <div className="card card-large" id="top">
      <div className="flex flex-space-between flex-gap-8">
        <div
          className="flex-row flex-wrap flex-align-center flex-grow"
          style={{ flexBasis: 300 }}
        >
          <h1 className="margin-0 show-from-tablet-landscape">
            {/* {data.symboldata && data.symboldata[0]?.name} */}
            {data.companysqldata && data.companysqldata[0]?.company}
          </h1>
          <div
            className="ink-600 show-from-tablet-landscape"
            style={{ opacity: "0.5" }}
          >
            <i className="icon-dot" />
          </div>
          {/* <div className="font-size-18 strong line-height-14">
            <div className="flex flex-align-center">
              <span>₹ 856</span>
              <span className="font-size-12 down margin-left-4">
                <i className="icon-circle-down" />
                -0.45%
              </span>
            </div>
            <div className="ink-600 font-size-11 font-weight-500">
              05 Oct 3:46 p.m.
            </div>
          </div> */}
        </div>
        <form
          method="post"
          className="flex margin-0"
          style={{ alignItems: "center" }}
        >
{/*         
          <button
            className="margin-right-8 plausible-event-name=Excel+Company plausible-event-user=unregistered"
            formAction="/user/company/export/103227380/"
            aria-label="Export to Excel"
          >
            <i className="icon-download" />
            <span className="hide-on-mobile">Export to Excel</span>
          </button> */}
        
        </form>
      </div>
      {/* <div
        className="company-links show-from-tablet-landscape"
        style={{ fontWeight: 500, marginTop: 8 }}
      >
        <a
          href="http://www.ideaforgetech.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="icon-link" />
          <span style={{ fontSize: "1.8rem" }}>ideaforgetech.com</span>
        </a>
        <a
          href="https://www.bseindia.com/stock-share-price/ideaforge-technology-ltd/IDEAFORGE/543932/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="icon-link-ext" />
          <span className="ink-700 upper" style={{ letterSpacing: "0.05px" }}>
            BSE: 543932
          </span>
        </a>
        <a
          href="https://www.nseindia.com/get-quotes/equity?symbol=IDEAFORGE"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="icon-link-ext" />
          <span className="ink-700 upper" style={{ letterSpacing: "0.05px" }}>
            NSE : IDEAFORGE
          </span>
        </a>
      </div> */}
      <div className="company-info">
        <div className="company-profile">
          <div className="flex flex-column" style={{ flex: "1 1" }}>
            <div className="title">About</div>
            <div className="sub show-more-box about" style={{ flexBasis: 100 }}>
              <p>
              {data.symboldata && data.symboldata[0]?.about}
              </p>
            </div>
            <div className="title">Key Points</div>
            <div className="sub commentary always-show-more-box">
              <p>
              {data.symboldata && data.symboldata[0]?.key_points}
              </p>
              <div className="show-more-button" style={{ height: 20 }} />
            </div>
            {/* <button
              className="a upper font-weight-500 letter-spacing plausible-event-name=Key+Insight plausible-event-user=free"
              style={{ fontSize: "1.4rem" }}
              type="button"
              onclick="Modal.openInModal(event)"
              data-title="Ideaforge Technology Ltd"
              data-url="/wiki/company/1284396/commentary/v2/"
              data-is-right-modal="true"
            >
              Read More <i className="icon-right" />
            </button> */}
          </div>
          <div
            className="company-links hide-from-tablet-landscape margin-top-20"
            style={{ fontWeight: 500 }}
          >
            <a
              href="http://www.ideaforgetech.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="icon-link" />
              <span style={{ fontSize: "1.6rem" }}>Website</span>
            </a>
            <a
              href="https://www.bseindia.com/stock-share-price/ideaforge-technology-ltd/IDEAFORGE/543932/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="icon-link-ext" />
              <span
                className="ink-700 upper"
                style={{ letterSpacing: "0.05px" }}
              >
                BSE
              </span>
            </a>
            <a
              href="https://www.nseindia.com/get-quotes/equity?symbol=IDEAFORGE"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="icon-link-ext" />
              <span
                className="ink-700 upper"
                style={{ letterSpacing: "0.05px" }}
              >
                NSE
              </span>
            </a>
          </div>
        </div>
        <div className="company-ratios">
          <ul id="top-ratios">
            <li className="flex flex-space-between" data-source="default">
              <span className="name">Market Cap</span>
              <span className="nowrap value">
                ₹<span className="number">{data.symboldata && data.symboldata[0]?.Market_Cap} </span>
                {data.symboldata && data.symboldata[0]?.mkt_cap_symbol}
              </span>
            </li>
            <li className="flex flex-space-between" data-source="default">
              <span className="name">Current Price</span>
              <span className="nowrap value">
                ₹<span className="number">{data.symboldata && data.symboldata[0]?.Current_Price}</span>
              </span>
            </li>
            <li className="flex flex-space-between" data-source="default">
              <span className="name">High / Low</span>
              <span className="nowrap value">
                ₹ <span className="number">{data.symboldata && data.symboldata[0]?.High_Low}</span> /{" "}
                <span className="number">850</span>
              </span>
            </li>
            <li className="flex flex-space-between" data-source="default">
              <span className="name">Stock P/E</span>
              <span className="nowrap value">
                <span className="number">{data.symboldata && data.symboldata[0]?.Stock_PE}</span>
              </span>
            </li>
            <li className="flex flex-space-between" data-source="default">
              <span className="name">Book Value</span>
              <span className="nowrap value">
                ₹ {data.symboldata && data.symboldata[0]?.Book_Value}
                <span className="number" />
              </span>
            </li>
            <li className="flex flex-space-between" data-source="default">
              <span className="name">Dividend Yield</span>
              <span className="nowrap value">
                <span className="number">{data.symboldata && data.symboldata[0]?.Dividend_Yield}</span>%
              </span>
            </li>
            <li className="flex flex-space-between" data-source="default">
              <span className="name">ROCE</span>
              <span className="nowrap value">
                <span className="number">{data.symboldata && data.symboldata[0]?.ROCE}</span>%
              </span>
            </li>
            <li className="flex flex-space-between" data-source="default">
              <span className="name">ROE</span>
              <span className="nowrap value">
                <span className="number">{data.symboldata && data.symboldata[0]?.ROE}</span>%
              </span>
            </li>
            <li className="flex flex-space-between" data-source="default">
              <span className="name">Face Value</span>
              <span className="nowrap value">
                ₹<span className="number">{data.symboldata && data.symboldata[0]?.Face_Value}</span>
              </span>
            </li>
          </ul>
          {/* <div style={{ marginTop: 32 }}>
            <label htmlFor="quick-ratio-search">Add ratio to table</label>
            <div className="flex-row flex-space-between flex-baseline flex-gap-16">
              <div
                className="dropdown-typeahead flex-grow"
                style={{ maxWidth: 480 }}
              >
                <input
                  id="quick-ratio-search"
                  type="text"
                  placeholder="eg. Promoter holding"
                  style={{ width: "100%" }}
                />
              </div>
              <a
                href="https://www.screener.in/user/quick_ratios/?next=/company/IDEAFORGE/consolidated/"
                className="smaller"
              >
                <i className="icon-pencil" />
                <span className="upper font-weight-500 letter-spacing">
                  Edit ratios
                </span>
              </a>
            </div>
          </div> */}
        </div>
      </div>
    </div>
    {/* <section
      id="chart"
      className="card card-large"
      style={{ marginBottom: "2.5rem" }}
    >
      <div
        id="chart-menu"
        className="flex flex-space-between flex-wrap"
        style={{ marginBottom: 16 }}
      >
        <div className="options" id="company-chart-days">
          <button
            name="days"
            value={30}
            onclick="CompanyChart.setActive(event)"
            type="button"
          >
            1m
          </button>
          <button
            name="days"
            value={180}
            onclick="CompanyChart.setActive(event)"
            type="button"
          >
            6m
          </button>
          <button
            name="days"
            value={365}
            onclick="CompanyChart.setActive(event)"
            type="button"
          >
            1Yr
          </button>
          <button
            name="days"
            value={1095}
            onclick="CompanyChart.setActive(event)"
            type="button"
          >
            3Yr
          </button>
          <button
            name="days"
            value={1825}
            onclick="CompanyChart.setActive(event)"
            type="button"
          >
            5Yr
          </button>
          <button
            name="days"
            value={3652}
            onclick="CompanyChart.setActive(event)"
            type="button"
          >
            10Yr
          </button>
          <button
            name="days"
            value={10000}
            onclick="CompanyChart.setActive(event)"
            type="button"
          >
            Max
          </button>
        </div>
        <div className="flex margin-bottom-24">
          <div
            className="options margin-right-8"
            id="company-chart-metrics"
            style={{ marginBottom: 0 }}
          >
            <button
              className="plausible-event-name=Chart+Metric plausible-event-user=unregistered plausible-event-metric=Price"
              onclick="CompanyChart.setActive(event)"
              name="metrics"
              value="Price-DMA50-DMA200-Volume"
              type="button"
            >
              Price
            </button>
            <button
              className="plausible-event-name=Chart+Metric plausible-event-user=unregistered plausible-event-metric=PE"
              onclick="CompanyChart.setActive(event)"
              name="metrics"
              value="Price to Earning-Median PE-EPS"
              type="button"
            >
              PE Ratio
            </button>
            <button
              className="hidden"
              id="company-chart-metrics-more-active"
              onclick="CompanyChart.setActive(event)"
              name="metrics"
              value=""
              type="button"
            >
              Hidden
            </button>
            <div className="dropdown-menu" id="metrics-dropdown">
              <button
                type="button"
                className="button-plain"
                onclick="Utils.toggleDropdown(event)"
              >
                More <i className="icon-down" />
              </button>
              <ul
                className="dropdown-content"
                style={{ maxWidth: 160, right: 0 }}
              >
                <li>
                  <button
                    className="a u-full-width plausible-event-name=Chart+Metric plausible-event-user=unregistered plausible-event-metric=Sales"
                    style={{ padding: "4px 12px" }}
                    onclick="CompanyChart.setActive(event)"
                    name="metrics"
                    value="GPM-OPM-NPM-Quarter Sales"
                    type="button"
                  >
                    Sales &amp; Margin
                  </button>
                </li>
                <li>
                  <button
                    className="a u-full-width plausible-event-name=Chart+Metric plausible-event-user=unregistered plausible-event-metric=EV-EBITDA"
                    style={{ padding: "4px 12px" }}
                    onclick="CompanyChart.setActive(event)"
                    name="metrics"
                    value="EV Multiple-Median EV Multiple-EBITDA"
                    type="button"
                  >
                    EV / EBITDA
                  </button>
                </li>
                <li>
                  <button
                    className="a u-full-width plausible-event-name=Chart+Metric plausible-event-user=unregistered plausible-event-metric=PBV"
                    style={{ padding: "4px 12px" }}
                    onclick="CompanyChart.setActive(event)"
                    name="metrics"
                    value="Price to book value-Median PBV-Book value"
                    type="button"
                  >
                    Price to Book
                  </button>
                </li>
                <li>
                  <button
                    className="a u-full-width plausible-event-name=Chart+Metric plausible-event-user=unregistered plausible-event-metric=Market+Cap+to+Sales"
                    style={{ padding: "4px 12px" }}
                    onclick="CompanyChart.setActive(event)"
                    name="metrics"
                    value="Market Cap to Sales-Median Market Cap to Sales-Sales"
                    type="button"
                  >
                    Market Cap / Sales
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <button
            type="button"
            className="button-small button-secondary flex flex-align-center"
            style={{ borderColor: "var(--button-border)", borderRadius: 3 }}
            data-title="Alerts for Ideaforge Technology Ltd"
            onclick="Modal.openInModal(event)"
            data-url="/alerts/stock-103227380/"
            aria-label="Add alerts for company"
          >
            <i className="icon-bell" />
          </button>
        </div>
      </div>
      <div className="flex no-select">
        <div className="hide-on-mobile chart-label">
          <div id="chart-label-left" />
        </div>
        <div id="chart-area" style={{ flexGrow: 1 }}>
          <div id="chart-info" className="invisible">
            <div id="chart-crosshair" />
            <div id="chart-tooltip" className="font-size-14">
              <div id="chart-tooltip-meta" className="ink-700 font-size-12" />
              <div
                className="font-weight-500 font-size-15"
                id="chart-tooltip-title"
              />
              <div id="chart-tooltip-info" />
            </div>
          </div>
          <div id="canvas-chart-holder" style={{ height: 375 }} />
        </div>
        <div className="hide-on-mobile chart-label">
          <div id="chart-label-right" />
        </div>
      </div>
      <div id="chart-legend" />
    </section> */}
    {/* <section id="analysis" className="card card-large" >
      <div className="flex flex-column-mobile flex-gap-32" style={{display:"flex"}}>
        <div className="pros" style={{ flexBasis: "50%" }}>
          <p className="title">Pros</p>
          <ul></ul>
        </div>
        <div className="cons" style={{ flexBasis: "50%" }}>
          <p className="title">Cons</p>
          <ul>
            <li>Promoter holding is low: 30.2%</li>
          </ul>
        </div>
      </div>
      <p className="sub font-size-14 margin-top-16 margin-bottom-0">
        <span style={{ verticalAlign: "sub" }}>*</span>
        <span className="font-weight-500">
          The pros and cons are machine generated.
        </span>
        <span className="has-tooltip">
          <i className="icon-info" />
          <span className="tooltip" style={{ width: 300, left: "-100px" }}>
            Pros / cons are based on a checklist to highlight important points.
            Please exercise caution and do your own analysis.
          </span>
        </span>
      </p>
    </section> */}
    {/* <section id="peers" className="card card-large">
      <div className="flex flex-space-between" style={{ alignItems: "center" }}>
        <div>
          <h2>Peer comparison</h2>
          <p className="sub">
            Sector:
            <a href="https://www.screener.in/company/compare/00000072/">
              Engineering
            </a>
            <span style={{ margin: 16 }} />
            Industry:
            <a href="https://www.screener.in/company/compare/00000072/00000044/">
              Engineering
            </a>
          </p>
        </div>
        <div className="flex-reverse">
          <a
            href="https://www.screener.in/user/columns/?next=/company/IDEAFORGE/consolidated/#peers"
            className="button button-secondary"
          >
            <i className="icon-cog" />
            <span className="hide-on-mobile">Edit</span>
            Columns
          </a>
        </div>
      </div>
      <div id="peers-table-placeholder">Loading peers table ...</div>
      <div className="flex-row flex-baseline">
        <label
          htmlFor="search-peer-comparison"
          style={{ paddingRight: "1rem" }}
        >
          Detailed Comparison with:
        </label>
        <div className="dropdown-typeahead">
          <input
            type="text"
            id="search-peer-comparison"
            placeholder="eg. Infosys"
            className="small"
          />
        </div>
      </div>
    </section> */}
    <section id="quarters" className="card card-large">
      <div className="flex-row flex-space-between flex-gap-16">
        <div>
          <h2>Quarterly Results</h2>
          {/* <p className="sub" style={{ margin: 0 }}>
            Consolidated Figures in Rs. Crores /
            <a
              href="https://www.screener.in/company/IDEAFORGE/#quarters"
              className=""
            >
              View Standalone
            </a>
          </p> */}
        </div>
        <div
          className="flex flex-gap-8"
          style={{ justifyContent: "flex-end", alignItems: "center" }}
        ></div>
      </div>
      <div className="responsive-holder fill-card-width" data-result-table="">
        <table className="data-table responsive-text-nowrap">
          <thead>
            <tr>
              <th className="text" />
              {qrcolumn && qrcolumn.length>0 && qrcolumn.map((item,i)=>{
                  return <th className="highlight-cell">{item.replace('_',' ')}</th>
               })}                         
            </tr>
          </thead>
          <tbody>
            {data.qrsqldata && data.qrsqldata.length>0 && data.qrsqldata.map((item,i)=>{
                  return <tr className="stripe">
              <td className="text">
                <button
                  className="button-plain"
                >
                  {/* Sales&nbsp;<span className="blue-icon">+</span> */}
                  {item.column_name}
                  
                </button>
              </td>
              <td className="highlight-cell">{item[qrcolumn[0]]}</td>
              <td className="">{item[qrcolumn[1]]}</td>
              <td className="highlight-cell">{item[qrcolumn[2]]}</td>
              <td className="">{item[qrcolumn[3]]}</td>
              <td className="highlight-cell">{item[qrcolumn[4]]}</td>
              <td className="">{item[qrcolumn[5]]}</td>
              <td className="highlight-cell">{item[qrcolumn[6]]}</td>
              <td className="">{item[qrcolumn[7]]}</td>
              <td className="highlight-cell">{item[qrcolumn[8]]}</td>
              <td className="">{item[qrcolumn[9]]}</td>
              <td className="highlight-cell">{item[qrcolumn[10]]}</td>
              <td className="">{item[qrcolumn[11]]}</td>
            </tr>
            })}               
          </tbody>
        </table>
      </div>
    </section>
    <section id="profit-loss" className="card card-large">
      <div className="flex-row flex-space-between flex-gap-16">
        <div>
          <h2>Profit &amp; Loss</h2>
          <p className="sub" style={{ margin: 0 }}>
            Consolidated Figures in Rs. Crores /
            <a
              href="https://www.screener.in/company/IDEAFORGE/#profit-loss"
              className=""
            >
              View Standalone
            </a>
          </p>
        </div>
        <div
          className="flex flex-gap-8"
          style={{ justifyContent: "flex-end", alignItems: "center" }}
        ></div>
      </div>
      <div className="responsive-holder fill-card-width" data-result-table="">
      <table className="data-table responsive-text-nowrap">
          <thead>
            <tr>
              <th className="text" />
              {plcolumn && plcolumn.length>0 && plcolumn.map((item,i)=>{
                  return <th className="highlight-cell">{item.replace('_',' ')}</th>
               })}                         
            </tr>
          </thead>
          <tbody>
            {data.plsqldata && data.plsqldata.length>0 && data.plsqldata.map((item,i)=>{
                  return <tr className="stripe">
              <td className="text">
                <button
                  className="button-plain"
                >
                  {/* Sales&nbsp;<span className="blue-icon">+</span> */}
                  {item.column_name}
                  
                </button>
              </td>
              <td className="highlight-cell">{item[plcolumn[0]]}</td>
              <td className="">{item[plcolumn[1]]}</td>
              <td className="highlight-cell">{item[plcolumn[2]]}</td>
              <td className="">{item[plcolumn[3]]}</td>
              <td className="highlight-cell">{item[plcolumn[4]]}</td>
              <td className="">{item[plcolumn[5]]}</td>
              <td className="highlight-cell">{item[plcolumn[6]]}</td>
              <td className="">{item[plcolumn[7]]}</td>
              <td className="highlight-cell">{item[plcolumn[8]]}</td>
              <td className="">{item[plcolumn[9]]}</td>
              <td className="highlight-cell">{item[plcolumn[10]]}</td>
              <td className="">{item[plcolumn[11]]}</td>
            </tr>
            })}               
          </tbody>
        </table>

        {/* <table className="data-table responsive-text-nowrap">
          <thead>
            <tr>
              <th className="text" />
              <th className="">Mar 2020</th>
              <th className="">Mar 2021</th>
              <th className="">Mar 2022</th>
              <th className="">Mar 2023</th>
            </tr>
          </thead>
          <tbody>
            <tr className="stripe">
              <td className="text">
                <button
                  className="button-plain"
                  onclick="Company.showSchedule('Sales', 'profit-loss', this)"
                >
                  Sales&nbsp;<span className="blue-icon">+</span>
                </button>
              </td>
              <td className="">14</td>
              <td className="">35</td>
              <td className="">159</td>
              <td className="">186</td>
            </tr>
            <tr className="">
              <td className="text">
                <button
                  className="button-plain"
                  onclick="Company.showSchedule('Expenses', 'profit-loss', this)"
                >
                  Expenses&nbsp;<span className="blue-icon">+</span>
                </button>
              </td>
              <td className="">26</td>
              <td className="">45</td>
              <td className="">85</td>
              <td className="">138</td>
            </tr>
            <tr className="stripe strong">
              <td className="text">Operating Profit</td>
              <td className="">-12</td>
              <td className="">-11</td>
              <td className="">75</td>
              <td className="">48</td>
            </tr>
            <tr className="">
              <td className="text">OPM %</td>
              <td className="">-88%</td>
              <td className="">-31%</td>
              <td className="">47%</td>
              <td className="">26%</td>
            </tr>
            <tr className="stripe">
              <td className="text">
                <button
                  className="button-plain"
                  onclick="Company.showSchedule('Other Income', 'profit-loss', this)"
                >
                  Other Income&nbsp;<span className="blue-icon">+</span>
                </button>
              </td>
              <td className="">2</td>
              <td className="">2</td>
              <td className="">2</td>
              <td className="">10</td>
            </tr>
            <tr className="">
              <td className="text">Interest</td>
              <td className="">1</td>
              <td className="">2</td>
              <td className="">19</td>
              <td className="">6</td>
            </tr>
            <tr className="stripe">
              <td className="text">Depreciation</td>
              <td className="">3</td>
              <td className="">4</td>
              <td className="">7</td>
              <td className="">12</td>
            </tr>
            <tr className=" strong">
              <td className="text">Profit before tax</td>
              <td className="">-13</td>
              <td className="">-14</td>
              <td className="">50</td>
              <td className="">41</td>
            </tr>
            <tr className="stripe">
              <td className="text">Tax %</td>
              <td className="">-0%</td>
              <td className="">-1%</td>
              <td className="">12%</td>
              <td className="">22%</td>
            </tr>
            <tr className=" strong">
              <td className="text">
                <button
                  className="button-plain"
                  onclick="Company.showSchedule('Net Profit', 'profit-loss', this)"
                >
                  Net Profit&nbsp;<span className="blue-icon">+</span>
                </button>
              </td>
              <td className="">-13</td>
              <td className="">-15</td>
              <td className="">44</td>
              <td className="">32</td>
            </tr>
            <tr className="stripe">
              <td className="text">EPS in Rs</td>
              <td className="">-1,499.44</td>
              <td className="">-1,625.56</td>
              <td className="">4,862.98</td>
              <td className="">14.99</td>
            </tr>
            <tr className="">
              <td className="text">Dividend Payout %</td>
              <td className="">0%</td>
              <td className="">0%</td>
              <td className="">0%</td>
              <td className="">0%</td>
            </tr>
          </tbody>
        </table> */}
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))",
          gap: "2%"
        }}
      >
        <table className="ranges-table">
          <tbody>
            <tr>
              <th colSpan={2}>Compounded Sales Growth</th>
            </tr>
            <tr>
              <td>10 Years:</td>
              <td>%</td>
            </tr>
            <tr>
              <td>5 Years:</td>
              <td>%</td>
            </tr>
            <tr>
              <td>3 Years:</td>
              <td>137%</td>
            </tr>
            <tr>
              <td>TTM:</td>
              <td>17%</td>
            </tr>
          </tbody>
        </table>
        <table className="ranges-table">
          <tbody>
            <tr>
              <th colSpan={2}>Compounded Profit Growth</th>
            </tr>
            <tr>
              <td>10 Years:</td>
              <td>%</td>
            </tr>
            <tr>
              <td>5 Years:</td>
              <td>%</td>
            </tr>
            <tr>
              <td>3 Years:</td>
              <td>62%</td>
            </tr>
            <tr>
              <td>TTM:</td>
              <td>-31%</td>
            </tr>
          </tbody>
        </table>
        <table className="ranges-table">
          <tbody>
            <tr>
              <th colSpan={2}>Stock Price CAGR</th>
            </tr>
            <tr>
              <td>10 Years:</td>
              <td>%</td>
            </tr>
            <tr>
              <td>5 Years:</td>
              <td>%</td>
            </tr>
            <tr>
              <td>3 Years:</td>
              <td>%</td>
            </tr>
            <tr>
              <td>1 Year:</td>
              <td>%</td>
            </tr>
          </tbody>
        </table>
        <table className="ranges-table">
          <tbody>
            <tr>
              <th colSpan={2}>Return on Equity</th>
            </tr>
            <tr>
              <td>10 Years:</td>
              <td>%</td>
            </tr>
            <tr>
              <td>5 Years:</td>
              <td>%</td>
            </tr>
            <tr>
              <td>3 Years:</td>
              <td>14%</td>
            </tr>
            <tr>
              <td>Last Year:</td>
              <td>13%</td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
    <section id="balance-sheet" className="card card-large">
      <div className="flex-row flex-space-between flex-gap-16">
        <div>
          <h2>Balance Sheet</h2>
          {/* <p className="sub" style={{ margin: 0 }}>
            Consolidated Figures in Rs. Crores /
            <a
              href="https://www.screener.in/company/IDEAFORGE/#balance-sheet"
              className=""
            >
              View Standalone
            </a>
          </p> */}
        </div>
        {/* <div
          className="flex flex-gap-8"
          style={{ justifyContent: "flex-end", alignItems: "center" }}
        >
          <button
            type="button"
            className="button-secondary button-small plausible-event-name=Corporate+Actions plausible-event-user=free"
            onclick="Modal.openInModal(event)"
            data-title="Corporate actions"
            data-url="/company/actions/1284396/"
          >
            Corporate actions
          </button>
        </div> */}
      </div>
      <div className="responsive-holder fill-card-width" data-result-table="">
      <table className="data-table responsive-text-nowrap">
          <thead>
            <tr>
              <th className="text" />
              {bscolumn && bscolumn.length>0 && bscolumn.map((item,i)=>{
                  return <th className="highlight-cell">{item.replace('_',' ')}</th>
               })}                         
            </tr>
          </thead>
          <tbody>
            {data.blsqldata && data.blsqldata.length>0 && data.blsqldata.map((item,i)=>{
                  return <tr className="stripe">
              <td className="text">
                <button
                  className="button-plain"
                >
                  {/* Sales&nbsp;<span className="blue-icon">+</span> */}
                  {item.column_name}
                  
                </button>
              </td>
              <td className="highlight-cell">{item[bscolumn[0]]}</td>
              <td className="">{item[bscolumn[1]]}</td>
              <td className="highlight-cell">{item[bscolumn[2]]}</td>
              <td className="">{item[bscolumn[3]]}</td>
              <td className="highlight-cell">{item[bscolumn[4]]}</td>
              <td className="">{item[bscolumn[5]]}</td>
              <td className="highlight-cell">{item[bscolumn[6]]}</td>
              <td className="">{item[bscolumn[7]]}</td>
              <td className="highlight-cell">{item[bscolumn[8]]}</td>
              <td className="">{item[bscolumn[9]]}</td>
              <td className="highlight-cell">{item[bscolumn[10]]}</td>
              <td className="">{item[bscolumn[11]]}</td>
            </tr>
            })}               
          </tbody>
        </table>

        {/* <table className="data-table responsive-text-nowrap">
          <thead>
            <tr>
              <th className="text" />
              <th className="">Mar 2020</th>
              <th className="">Mar 2021</th>
              <th className="">Mar 2022</th>
              <th className="">Mar 2023</th>
            </tr>
          </thead>
          <tbody>
            <tr className="stripe">
              <td className="text">
                <button
                  className="button-plain"
                  onclick="Company.showSchedule('Share Capital', 'balance-sheet', this)"
                >
                  Share Capital&nbsp;<span className="blue-icon">+</span>
                </button>
              </td>
              <td className="">0</td>
              <td className="">0</td>
              <td className="">0</td>
              <td className="">21</td>
            </tr>
            <tr className="">
              <td className="text">Reserves</td>
              <td className="">68</td>
              <td className="">60</td>
              <td className="">163</td>
              <td className="">303</td>
            </tr>
            <tr className="stripe">
              <td className="text">
                <button
                  className="button-plain"
                  onclick="Company.showSchedule('Borrowings', 'balance-sheet', this)"
                >
                  Borrowings&nbsp;<span className="blue-icon">+</span>
                </button>
              </td>
              <td className="">7</td>
              <td className="">51</td>
              <td className="">16</td>
              <td className="">101</td>
            </tr>
            <tr className="">
              <td className="text">
                <button
                  className="button-plain"
                  onclick="Company.showSchedule('Other Liabilities', 'balance-sheet', this)"
                >
                  Other Liabilities&nbsp;<span className="blue-icon">+</span>
                </button>
              </td>
              <td className="">5</td>
              <td className="">13</td>
              <td className="">43</td>
              <td className="">63</td>
            </tr>
            <tr className="stripe strong">
              <td className="text">Total Liabilities</td>
              <td className="">80</td>
              <td className="">124</td>
              <td className="">222</td>
              <td className="">488</td>
            </tr>
            <tr className="">
              <td className="text">
                <button
                  className="button-plain"
                  onclick="Company.showSchedule('Fixed Assets', 'balance-sheet', this)"
                >
                  Fixed Assets&nbsp;<span className="blue-icon">+</span>
                </button>
              </td>
              <td className="">5</td>
              <td className="">13</td>
              <td className="">26</td>
              <td className="">49</td>
            </tr>
            <tr className="stripe">
              <td className="text">CWIP</td>
              <td className="">17</td>
              <td className="">14</td>
              <td className="">19</td>
              <td className="">25</td>
            </tr>
            <tr className="">
              <td className="text">Investments</td>
              <td className="">12</td>
              <td className="">0</td>
              <td className="">11</td>
              <td className="">125</td>
            </tr>
            <tr className="stripe">
              <td className="text">
                <button
                  className="button-plain"
                  onclick="Company.showSchedule('Other Assets', 'balance-sheet', this)"
                >
                  Other Assets&nbsp;<span className="blue-icon">+</span>
                </button>
              </td>
              <td className="">46</td>
              <td className="">97</td>
              <td className="">166</td>
              <td className="">289</td>
            </tr>
            <tr className=" strong">
              <td className="text">Total Assets</td>
              <td className="">80</td>
              <td className="">124</td>
              <td className="">222</td>
              <td className="">488</td>
            </tr>
          </tbody>
        </table> */}
      </div>
    </section>
    <section id="cash-flow" className="card card-large">
      <div className="flex-row flex-space-between flex-gap-16">
        <div>
          <h2>Cash Flows</h2>
          <p className="sub" style={{ margin: 0 }}>
            Consolidated Figures in Rs. Crores /
            <a
              href="https://www.screener.in/company/IDEAFORGE/#cash-flow"
              className=""
            >
              View Standalone
            </a>
          </p>
        </div>
        <div
          className="flex flex-gap-8"
          style={{ justifyContent: "flex-end", alignItems: "center" }}
        ></div>
      </div>
      <div className="responsive-holder fill-card-width" data-result-table="">
      <table className="data-table responsive-text-nowrap">
          <thead>
            <tr>
              <th className="text" />
              {cfcolumn && cfcolumn.length>0 && cfcolumn.map((item,i)=>{
                  return <th className="highlight-cell">{item.replace('_',' ')}</th>
               })}                         
            </tr>
          </thead>
          <tbody>
            {data.cfsqldata && data.cfsqldata.length>0 && data.cfsqldata.map((item,i)=>{
                  return <tr className="stripe">
              <td className="text">
                <button
                  className="button-plain"
                >
                  {/* Sales&nbsp;<span className="blue-icon">+</span> */}
                  {item.column_name}
                  
                </button>
              </td>
              <td className="highlight-cell">{item[cfcolumn[0]]}</td>
              <td className="">{item[cfcolumn[1]]}</td>
              <td className="highlight-cell">{item[cfcolumn[2]]}</td>
              <td className="">{item[cfcolumn[3]]}</td>
              <td className="highlight-cell">{item[cfcolumn[4]]}</td>
              <td className="">{item[cfcolumn[5]]}</td>
              <td className="highlight-cell">{item[cfcolumn[6]]}</td>
              <td className="">{item[cfcolumn[7]]}</td>
              <td className="highlight-cell">{item[cfcolumn[8]]}</td>
              <td className="">{item[cfcolumn[9]]}</td>
              <td className="highlight-cell">{item[cfcolumn[10]]}</td>
              <td className="">{item[cfcolumn[11]]}</td>
            </tr>
            })}               
          </tbody>
        </table>

      </div>
    </section>
    <section id="ratios" className="card card-large">
      <div className="flex-row flex-space-between flex-gap-16">
        <div>
          <h2>Ratios</h2>
          <p className="sub" style={{ margin: 0 }}>
            Consolidated Figures in Rs. Crores /
            <a
              href="https://www.screener.in/company/IDEAFORGE/#ratios"
              className=""
            >
              View Standalone
            </a>
          </p>
        </div>
        <div
          className="flex flex-gap-8"
          style={{ justifyContent: "flex-end", alignItems: "center" }}
        ></div>
      </div>
      <div className="responsive-holder fill-card-width" data-result-table="">
      <table className="data-table responsive-text-nowrap">
          <thead>
            <tr>
              <th className="text" />
              {rtcolumn && rtcolumn.length>0 && rtcolumn.map((item,i)=>{
                  return <th className="highlight-cell">{item.replace('_',' ')}</th>
               })}                         
            </tr>
          </thead>
          <tbody>
            {data.ratiossqldata && data.ratiossqldata.length>0 && data.ratiossqldata.map((item,i)=>{
                  return <tr className="stripe">
              <td className="text">
                <button
                  className="button-plain"
                >
                  {/* Sales&nbsp;<span className="blue-icon">+</span> */}
                  {item.column_name}
                  
                </button>
              </td>
              <td className="highlight-cell">{item[rtcolumn[0]]}</td>
              <td className="">{item[rtcolumn[1]]}</td>
              <td className="highlight-cell">{item[rtcolumn[2]]}</td>
              <td className="">{item[rtcolumn[3]]}</td>
              <td className="highlight-cell">{item[rtcolumn[4]]}</td>
              <td className="">{item[rtcolumn[5]]}</td>
              <td className="highlight-cell">{item[rtcolumn[6]]}</td>
              <td className="">{item[rtcolumn[7]]}</td>
              <td className="highlight-cell">{item[rtcolumn[8]]}</td>
              <td className="">{item[rtcolumn[9]]}</td>
              <td className="highlight-cell">{item[rtcolumn[10]]}</td>
              <td className="">{item[rtcolumn[11]]}</td>
            </tr>
            })}               
          </tbody>
        </table>
      </div>
    </section>
    <section id="shareholding" className="card card-large">
      <div className="flex flex-space-between flex-wrap margin-bottom-8 flex-align-center">
        <div>
          <h2 className="margin-0">Shareholding Pattern</h2>
          <p className="sub">Numbers in percentages</p>
        </div>
        {/* <div className="flex">
          <div className="options small margin-0">
            <button
              onclick="Utils.setActiveTab(event)"
              className="active"
              data-tab-id="quarterly-shp"
              type="button"
            >
              Quarterly
            </button>
            <button
              onclick="Utils.setActiveTab(event)"
              data-tab-id="yearly-shp"
              type="button"
            >
              Yearly
            </button>
          </div>
          <div className="text-align-center margin-left-12">
            <button
              type="button"
              className="button-secondary button-small"
              onclick="Modal.openInModal(event)"
              data-title="Ideaforge Tech trades"
              data-url="/trades/company-1284396/"
            >
              Trades
            </button>
            <div
              className="ink-700 font-size-12"
              style={{ position: "absolute", paddingLeft: 15 }}
            >
              10 Recently
            </div>
          </div>
        </div> */}
      </div>
      <div id="quarterly-shp">
        <div className="responsive-holder fill-card-width">
        <table className="data-table responsive-text-nowrap">
          <thead>
            <tr>
              <th className="text" />
              {shcolumn && shcolumn.length>0 && shcolumn.map((item,i)=>{
                  return <th className="highlight-cell">{item.replace('_',' ')}</th>
               })}                         
            </tr>
          </thead>
          <tbody>
            {data.shareholdingsqldata && data.shareholdingsqldata.length>0 && data.shareholdingsqldata.map((item,i)=>{
                  return <tr className="stripe">
              <td className="text">
                <button
                  className="button-plain"
                >
                  {/* Sales&nbsp;<span className="blue-icon">+</span> */}
                  {item.column_name}
                  
                </button>
              </td>
              <td className="highlight-cell">{item[shcolumn[0]]}</td>
              <td className="">{item[shcolumn[1]]}</td>
              <td className="highlight-cell">{item[shcolumn[2]]}</td>
              <td className="">{item[shcolumn[3]]}</td>
              <td className="highlight-cell">{item[shcolumn[4]]}</td>
              <td className="">{item[shcolumn[5]]}</td>
              <td className="highlight-cell">{item[shcolumn[6]]}</td>
              <td className="">{item[shcolumn[7]]}</td>
              <td className="highlight-cell">{item[shcolumn[8]]}</td>
              <td className="">{item[shcolumn[9]]}</td>
              <td className="highlight-cell">{item[shcolumn[10]]}</td>
              <td className="">{item[shcolumn[11]]}</td>
            </tr>
            })}               
          </tbody>
        </table>
        </div>
      </div>
      <div id="yearly-shp" className="hidden">
        <div className="responsive-holder fill-card-width">
          <table className="data-table">
            <thead>
              <tr>
                <th className="text" />
                <th>Jul 2023</th>
              </tr>
            </thead>
            <tbody>
              <tr className="stripe">
                <td className="text">
                  <button
                    className="button-plain plausible-event-name=View+Shareholders plausible-event-user=free plausible-event-classification=promoters plausible-event-period=yearly"
                    onclick="Company.showShareholders('promoters', 'yearly', this)"
                  >
                    Promoters&nbsp;<span className="blue-icon">+</span>
                  </button>
                </td>
                <td>30.19%</td>
              </tr>
              <tr className="">
                <td className="text">
                  <button
                    className="button-plain plausible-event-name=View+Shareholders plausible-event-user=free plausible-event-classification=foreign_institutions plausible-event-period=yearly"
                    onclick="Company.showShareholders('foreign_institutions', 'yearly', this)"
                  >
                    FIIs&nbsp;<span className="blue-icon">+</span>
                  </button>
                </td>
                <td>4.78%</td>
              </tr>
              <tr className="stripe">
                <td className="text">
                  <button
                    className="button-plain plausible-event-name=View+Shareholders plausible-event-user=free plausible-event-classification=domestic_institutions plausible-event-period=yearly"
                    onclick="Company.showShareholders('domestic_institutions', 'yearly', this)"
                  >
                    DIIs&nbsp;<span className="blue-icon">+</span>
                  </button>
                </td>
                <td>13.31%</td>
              </tr>
              <tr className="">
                <td className="text">
                  <button
                    className="button-plain plausible-event-name=View+Shareholders plausible-event-user=free plausible-event-classification=public plausible-event-period=yearly"
                    onclick="Company.showShareholders('public', 'yearly', this)"
                  >
                    Public&nbsp;<span className="blue-icon">+</span>
                  </button>
                </td>
                <td>51.73%</td>
              </tr>
              <tr className="sub">
                <td className="text">No. of Shareholders</td>
                <td>42,794</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <p className="sub small">
        * The classifications might have changed from Sep'2022 onwards.
        <span className="has-tooltip">
          <i className="icon-info" />
          <span className="tooltip" style={{ width: 300 }}>
            The new XBRL format added more details from Sep'22 onwards.
            <br />
            <br />
            Classifications such as banks and foreign portfolio investors were
            not available earlier. The sudden changes in FII or DII can be
            because of these changes.
            <br />
            <br />
            Click on the line-items to see the names of individual entities.
          </span>
        </span>
      </p>
    </section>
    {/* <section id="documents" className="card card-large">
      <div className="flex flex-space-between margin-bottom-16">
        <h2 className="margin-0">Documents</h2>
      </div>
      <div className="flex-row flex-gap-small">
        <div className="documents flex-column" style={{ flex: "2 1 250px" }}>
          <h3 className="margin-bottom-8">Announcements</h3>
          <div className="show-more-box" style={{ flexBasis: 300 }}>
            <div id="company-announcements-tab">
              <div className="flex">
                <div className="options small" style={{ marginBottom: 0 }}>
                  <button
                    type="button"
                    className="active"
                    onclick="Utils.ajaxLoad(event, 'https://www.screener.in/announcements/recent/1284396/')"
                    data-swap="#company-announcements-tab"
                  >
                    Recent
                  </button>
                  <button
                    type="button"
                    onclick="Utils.ajaxLoad(event, 'https://www.screener.in/announcements/important/1284396/')"
                    data-swap="#company-announcements-tab"
                  >
                    Important
                  </button>
                  <button
                    type="button"
                    onclick="Utils.ajaxLoad(event, 'https://www.screener.in/announcements/search/1284396/')"
                    data-swap="#company-announcements-tab"
                  >
                    Search
                  </button>
                  <a
                    className="button"
                    href="https://www.bseindia.com/stock-share-price/ideaforge-technology-ltd/ideaforge/543932/corp-announcements/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    All
                    <i className="icon-link-ext smaller" />
                  </a>
                </div>
              </div>
              <ul className="list-links">
                <li className="overflow-wrap-anywhere">
                  <a
                    href="https://www.bseindia.com/xml-data/corpfiling/AttachLive/c67a0488-009a-43db-af80-19cce36eca62.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Regulation 30 Of The SEBI (LODR) Regulations, 2015 ('Listing
                    Regulations') - Intimation Regarding Receipt Of Insurance
                    Claim
                    <span className="ink-600 smaller">15h</span>
                  </a>
                </li>
                <li className="overflow-wrap-anywhere">
                  <a
                    href="https://www.bseindia.com/xml-data/corpfiling/AttachLive/30f77321-38ab-4910-b31f-8438bd6a3d50.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Announcement under Regulation 30 (LODR)-Allotment
                    <span className="ink-600 smaller">1d</span>
                  </a>
                </li>
                <li className="overflow-wrap-anywhere">
                  <a
                    href="https://www.bseindia.com/xml-data/corpfiling/AttachLive/e0bb8b2d-147c-4091-bb8c-2c94006227b4.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Announcement under Regulation 30 (LODR)-Newspaper
                    Publication
                    <div className="ink-600 smaller">
                      30 Sep - Newspaper Advertisement - Notice of Postal Ballot
                      and E-voting information
                    </div>
                  </a>
                </li>
                <li className="overflow-wrap-anywhere">
                  <a
                    href="https://www.bseindia.com/xml-data/corpfiling/AttachLive/f77972d5-43af-48a3-958d-8313a7230975.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Shareholder Meeting / Postal Ballot-Notice of Postal Ballot
                    <span className="ink-600 smaller">29 Sep</span>
                  </a>
                </li>
                <li className="overflow-wrap-anywhere">
                  <a
                    href="https://www.bseindia.com/xml-data/corpfiling/AttachLive/d9833f35-4ed9-45bf-bb31-6326e365ab96.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Closure of Trading Window
                    <span className="ink-600 smaller">28 Sep</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          className="documents annual-reports flex-column"
          style={{ flex: "1 1 200px", maxWidth: 210 }}
        >
          <h3>Annual reports</h3>
          <div className="show-more-box">
            <p className="sub">No data available.</p>
          </div>
          <div className="margin-top-16">
            <a
              className="button button-secondary button-small"
              href="https://www.sebi.gov.in/filings/public-issues/jul-2023/ideaforge-technology-limited-prospectus_73437.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              DRHP
              <i className="icon-link-ext" />
            </a>
          </div>
        </div>
        <div
          className="documents credit-ratings flex-column"
          style={{ flex: "1 1 200px", maxWidth: 220 }}
        >
          <h3>Credit ratings</h3>
          <div className="show-more-box">
            <ul className="list-links">
              <li>
                <a
                  href="https://www.crisil.com/mnt/winshare/Ratings/RatingList/RatingDocs/IdeaforgeTechnologyLimited_August 30, 2023_RR_324456.html"
                  className="plausible-event-name=Credit+Rating plausible-event-user=free"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Rating update
                  <div className="ink-600 smaller">30 Aug from crisil</div>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div
          className="documents concalls flex-column"
          style={{ flex: "2 1 250px", maxWidth: "fit-content" }}
        >
          <div className="flex flex-space-between flex-align-center margin-bottom-8">
            <h3 className="margin-0">Concalls</h3>
            <button
              className="a font-size-12 font-weight-500 sub"
              onclick="Modal.openInModal(event)"
              data-url="/concalls/add-1284396/"
              data-title="Ideaforge Tech - Add Link"
            >
              <i className="icon-flag" />
              <span className="underline-link">Add Missing</span>
            </button>
          </div>
          <div className="show-more-box">
            <ul className="list-links">
              <li className="flex flex-gap-8 flex-wrap">
                <div
                  className="ink-600 font-size-15 font-weight-500 nowrap"
                  style={{ width: 74 }}
                >
                  Aug 2023
                </div>
                <a
                  className="concall-link"
                  href="https://www.bseindia.com/xml-data/corpfiling/AttachHis/f2db0ff8-0fd1-49d8-b09d-5e9947e08457.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Raw Transcript"
                >
                  Transcript
                </a>
                <button
                  className="concall-link plausible-event-name=Concall+Notes plausible-event-user=free"
                  type="button"
                  data-url="/concalls/summary/13168138/"
                  onclick="Modal.openInModal(event)"
                  data-is-right-modal="true"
                  data-title="Concall Notes - Aug 2023"
                >
                  Notes
                </button>
                <a
                  href="https://www.bseindia.com/xml-data/corpfiling/AttachHis/6eed43b5-9d5d-44be-9309-70f2614bf0b5.pdf"
                  className="concall-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  PPT
                </a>
              </li>
              <li className="flex flex-gap-8 flex-wrap">
                <div
                  className="ink-600 font-size-15 font-weight-500 nowrap"
                  style={{ width: 74 }}
                >
                  Aug 2023
                </div>
                <div className="concall-link">Transcript</div>
                <div className="concall-link">Notes</div>
                <a
                  href="https://www.bseindia.com/xml-data/corpfiling/AttachHis/4b35068e-3a85-48d6-be84-e71ba4b5b6c2.pdf"
                  className="concall-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  PPT
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section> */}
    {/* cache time 2023-10-05 11:05:06 */}
  </main>
  
  
  {/* Important JS (which provide global objects)- check below too */}
  {/* Independent JS scripts for page interactions */}
  {/* Service worker installer for PWA */}
</>

{/*                     
  <div className="card companycard" id='section-one' style={{background:'white'}}>
  <div className="card-body" >
  <div className="d-flex flex-row">
    <div className="p-2 col-3"><h2>Adani Enterprises Ltd</h2></div>
    <div className="p-2 col-8">₹ 2,490</div>
    <div className="col-1 text-right"><button type="button" className="btn btn-primary">Export</button></div>  
  </div>
  <div className="d-flex flex-row">
    <div className="p-2 col-1"><h6>Adani.com</h6></div>
    <div className="p-2 col-1">BSE: 512599</div>
    <div className="p-2 col-1">NSE : ADANIENT</div>
    
  </div>
<div className="d-flex flex-row border">
<li className="flex flex-space-between p-3" data-source="default">
  <span className="name">
    
    Market Cap
    
  </span>

  
  <span className="nowrap value px-4">
    
      ₹
    
    <span className="number">2,83,826</span>
    
      Cr.
    
  </span>
  
</li>
</div>
  </div>
</div>

   
      <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
      <div className="card companycard" id='section-two' style={{background:'white'}}>
  <div className="card-body" >
  <div className="d-flex flex-row">
    <div className="p-2 col-3"><h2>Adani Enterprises Ltd</h2></div>
    <div className="p-2 col-8">₹ 2,490</div>
    <div className="col-1 text-right"><button type="button" className="btn btn-primary">Export</button></div>  
  </div>
  <div className="d-flex flex-row">
    <div className="p-2 col-1"><h6>Adani.com</h6></div>
    <div className="p-2 col-1">BSE: 512599</div>
    <div className="p-2 col-1">NSE : ADANIENT</div>
    
  </div>
<div className="d-flex flex-row border">
<li className="flex flex-space-between p-3" data-source="default">
  <span className="name">
    
    Market Cap
    
  </span>

  
  <span className="nowrap value px-4">
    
      ₹
    
    <span className="number">2,83,826</span>
    
      Cr.
    
  </span>
  
</li>
</div>
  </div>
</div>
    </div>
    </div>*/}
    </div> 
  );
};

export default Company