import React, { useEffect, useState } from 'react'
import { getunofficialholding } from '../../api/api';
import { Table } from 'antd';

function Haircutting() {
    const [holdingData,setHoldingData]=useState([])
    const fetchHolding = async ()=>{
        const holding = await getunofficialholding();
        setHoldingData(holding.data)
    }

    useEffect(()=>{
        fetchHolding()
    },[])

    const tableColumns2 = [
        {
          title: "Instrument",
          dataIndex: "tradingsymbol",
          width: 200,
          sorter: (a, b) => a.tradingsymbol.localeCompare(b.tradingsymbol),
        },
        {
          title: "Qty.",
          dataIndex: "quantity",
          sorter: (a, b) => a.quantity - b.quantity,
          render: (_, elm) => <span className="text-nowrap">{elm.quantity?.toFixed(2)}</span>,
        },
        {
          title: "Avg. cost",
          dataIndex: "average_price",
          sorter: (a, b) => a.average_price - b.average_price,
          render: (_, elm) => <span className="text-nowrap">{elm.average_price?.toFixed(2)}</span>,
        },
        {
          title: "LTP",
          dataIndex: "last_price",
          sorter: (a, b) => a.last_price - b.last_price,
          render: (_, elm) => <span className="text-nowrap">{elm.last_price?.toFixed(2)}</span>,
        },
        {
          title: "Invested",
          dataIndex: "curval",
          sorter: (a, b) =>
            a.average_price * a.quantity - b.average_price * b.quantity,
          render: (_, elm) => <span className="text-nowrap">{(elm.average_price * elm.quantity)?.toFixed(2)}</span>,
        },
        {
          title: "Cur. val",
          dataIndex: "curval",
          sorter: (a, b) => a.last_price * a.quantity - b.last_price * b.quantity,
          render: (_, elm) => <span className="text-nowrap">{(elm.last_price * elm.quantity)?.toFixed(2)}</span>,
        },
        {
          title: "Total P&L amount",
          dataIndex: "pl",
          sorter: (a, b) => a.pnl - b.pnl,
          render: (_, elm) => (
            <span className={elm.pnl > 0 ? "text-success text-nowrap" : "text-danger text-nowrap"}>
              {((elm.last_price - elm.average_price) * elm.quantity)?.toFixed(2)}
            </span>
          ),
        },
        {
          title: "total /share change amt ",
          dataIndex: "netcharge",
          sorter: (a, b) => ((a.last_price - a.average_price) * a.quantity) - ((b.last_price - b.average_price) * b.quantity),
    
    
          render: (_, elm) => (
            <span className={elm.last_price - elm.average_price > 0 ? "text-success text-nowrap" : "text-danger text-nowrap"}>
              {((elm.last_price - elm.average_price) * elm.quantity).toFixed(2)}
            </span>
          ),
        },
        {
          title: "total chg %",
          dataIndex: "netcharge",
          sorter: (a, b) =>
            (a.pnl * 100) / (a.average_price * a.quantity) -
            (b.pnl * 100) / (b.average_price * b.quantity),
          render: (_, elm) => (
            <span className={elm.pnl > 0 ? "text-success" : "text-danger"}>
              {elm.average_price != 0 ? ((elm.pnl * 100) / (elm.average_price * elm.quantity))?.toFixed(2) : 0}
            </span>
          ),
        },
        
        {
          title: "Day chg. amt",
          dataIndex: "daycharge",
          sorter: (a, b) => a.quantity * a.day_change - b.quantity * b.day_change,
          render: (_, elm) => (
            <span className={`text-nowrap ${elm.day_change > 0 ? "text-success" : "text-danger"}`}>
              {(elm.quantity * elm.day_change)?.toFixed(2)}
            </span>
          ),
        },
        {
          title: "Day chg./share",
          dataIndex: "daycharge",
          sorter: (a, b) => a.quantity * a.day_change - b.quantity * b.day_change,
          render: (_, elm) => (
            <span className={`text-nowrap ${elm.day_change > 0 ? "text-success" : "text-danger"}`}>
              {elm?.day_change?.toFixed(2)}
            </span>
          ),
        },
        {
          title: "Day chg.%",
          dataIndex: "daycharge_percent",
          sorter: (a, b) => a.day_change_percentage - b.day_change_percentage,
          render: (_, elm) => (
            <span className={`text-nowrap ${elm.day_change_percentage > 0 ? "text-success" : "text-danger"}`}>
              {(elm.day_change_percentage)?.toFixed(2)}%
            </span>
          ),
        },
        
        
    
      ];
    
  return (
    <div>
        <div>
        <Table
              bordered
            //   scroll={{
            //     x: 1400,
            //     // y: 540,
            //   }}
              pagination={false}
              columns={tableColumns2}
              size="middle"

              
              dataSource={holdingData}

            // sticky={{
            //   offsetHeader: 0,
            // }}
            />
        </div>
    </div>
  )
}

export default Haircutting