import React, { useEffect, useState } from 'react';
import { Button, Modal, Table } from 'antd';
import { contractNote } from '../api/api';


const ContractNoteModals = (data) => {
   const [response,setresponse]=useState([])

    const getMargin=async()=>{
        const values=data?.data?.map((e)=>{
         return   {
                "order_id":e.order_id,
                "exchange":e.exchange,
                "tradingsymbol":e.tradingsymbol,
                "transaction_type":e.transaction_type,
                "variety":e.variety,
                "product":e.product,
                "order_type":e.order_type,
                "quantity":e.quantity,
                "average_price":e.price,
            }
        })

        const marginResponse = await contractNote(values)
        setresponse(marginResponse?.data)
        

        

        
    }

    const totalCharges={
        brokerage:response?.map((j)=>j?.charges?.brokerage)?.reduce((a,b)=>a+b,0),
        stt:response?.map((j)=>j?.charges?.transaction_tax)?.reduce((a,b)=>a+b,0),
        exchange_turnover_charge:response?.map((j)=>j?.charges?.exchange_turnover_charge)?.reduce((a,b)=>a+b,0),
        sebi_turnover_charge:response?.map((j)=>j?.charges?.sebi_turnover_charge)?.reduce((a,b)=>a+b,0),
        gst:response?.map((j)=>j?.charges?.gst?.total)?.reduce((a,b)=>a+b,0),
        total:response?.map((j)=>j?.charges?.total)?.reduce((a,b)=>a+b,0)
    }

    useEffect(()=>{
        getMargin()
    },[data.data])

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
 
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const columns=[
    
      {
        title: "Transaction",
        dataIndex: "transaction_type",
        sorter: (a, b) => a.transaction_type-b.transaction_type,
        // render: (_, elm) => format(new Date(elm.order_timestamp), "yyyy-MM-dd kk:mm:ss"),
      },
      {
        title: "Instrument",
        dataIndex: "tradingsymbol",
        sorter: (a, b) => a.tradingsymbol-b.tradingsymbol,
        // render: (_, elm) => format(new Date(elm.order_timestamp), "yyyy-MM-dd kk:mm:ss"),
      },
      {
        title: "quantity",
        dataIndex: "quantity",
        sorter: (a, b) => a.quantity-b.quantity,
        // render: (_, elm) => format(new Date(elm.order_timestamp), "yyyy-MM-dd kk:mm:ss"),
      },
      {
        title: "Price",
        dataIndex: "price",
        sorter: (a, b) => a.price-b.price,
        // render: (_, elm) => format(new Date(elm.order_timestamp), "yyyy-MM-dd kk:mm:ss"),
      },
      {
        title: "Charges",
        dataIndex: "charges",
        sorter: (a, b) => a.total-b.total,
        render: (_, elm) =>elm.charges.total.toFixed(2),
      },
  ]
  return (
    <>
      <Button  onClick={showModal}>
        Contract note
      </Button>
      <Modal 
       width={"fit-content"}
      title="Contract note" open={isModalOpen}  onCancel={handleCancel}>
        <div >
            <div className='d-flex justify-content-between'>
                <span>Brokerage</span>
                <span>{totalCharges?.brokerage?.toFixed(2)}</span>
            </div>
            <div  className='d-flex justify-content-between'>
                <span>Stt</span>
                <span>{totalCharges?.stt?.toFixed(2)}</span>
            </div>
            <div className='d-flex justify-content-between'>
                <span>Exchange turnover charge</span>
                <span>{totalCharges?.exchange_turnover_charge?.toFixed(2)}</span>
            </div>
            <div className='d-flex justify-content-between'>
                <span>Sebi turnover charge</span>
                <span>{totalCharges?.sebi_turnover_charge?.toFixed(2)}</span>
            </div>
            <div className='d-flex justify-content-between border-bottom'>
                <span>Gst</span>
                <span>{totalCharges?.gst?.toFixed(2)}</span>
            </div>
            <div className='d-flex justify-content-between border-bottom py-2'>
                <span>Total</span>
                <span>{totalCharges?.total?.toFixed(2)}</span>
            </div>
        </div>

        <div className='mt-2'>
            <Table dataSource={response} columns={columns}/>
        </div>
      </Modal>
    </>
  );
};
export default ContractNoteModals;