import React, { useEffect, useState } from "react";
import { redirect, useNavigate, useSearchParams } from "react-router-dom";
import { BASEURL } from "../zerodha/BaseURL";
// import { BASEURL } from "../componunts/BaseURL";
// import Swal from "sweetalert";

const Getaccesstoken_fyres = () => {
  const navigate = useNavigate();
  const [searchParams, setsearchParams] = useSearchParams();
  //   const [code, setcode] = useState("")

  useEffect(() => {
    if (searchParams.get("auth_code") !== "") {
      getuserdata(searchParams.get("auth_code"));
    }
  }, []);

  const getuserdata = async (token) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    // myHeaders.append("Authorization", token);
    const getdata = await fetch(BASEURL + "home/fyers-getaccesstoken", {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        code: token,
      }),
    });

    const data = await getdata.json();

    if(data.status!='200') return  navigate("/", { replace: true });


    // console.log(data);
    // return
  //   let data = {
  //     "status": "200",
  //     "data": {
  //         "s": "ok",
  //         "code": 200,
  //         "message": "",
  //         "access_token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJhcGkuZnllcnMuaW4iLCJpYXQiOjE2OTkzNDg5ODAsImV4cCI6MTY5OTQwMzQwMCwibmJmIjoxNjk5MzQ4OTgwLCJhdWQiOlsieDowIiwieDoxIiwieDoyIiwiZDoxIiwiZDoyIiwieDoxIiwieDowIl0sInN1YiI6ImFjY2Vzc190b2tlbiIsImF0X2hhc2giOiJnQUFBQUFCbFNnSDBaUXhWcTg5RXU5dVJwWU1RbzdCb0JObkcyRHJKNE5oY3lBMzFoSlNRVlZKcjd0UFJ2Z2FmSVcwQ0x0N0hOajhUMUQ3d3hVdTE4YmFFWFpOWXZ6SnlYY1NtbURfZW1fRUZDNmM4Y21oZGhYMD0iLCJkaXNwbGF5X25hbWUiOiJKSU5FTkRSQSBCSEFSQVRCSEFJIFNIQUgiLCJvbXMiOiJLMSIsImhzbV9rZXkiOiI1ZjM2OWYyNGRlN2M0MTQ4NzFmZmVmOTBjYjVmOWRlYTZiYmRjYmNlMTM3YzAzMmI3NTJjODIzMyIsImZ5X2lkIjoiWEoxMjQ2MCIsImFwcFR5cGUiOjEwMCwicG9hX2ZsYWciOiJOIn0.QVviNcJOAkIOQ6hsvIT3tljS_xUsbRdCITs3Afpe-mc",
  //         "refresh_token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJhcGkuZnllcnMuaW4iLCJpYXQiOjE2OTkzNDg5ODAsImV4cCI6MTcwMDYxMzAwMCwibmJmIjoxNjk5MzQ4OTgwLCJhdWQiOlsieDowIiwieDoxIiwieDoyIiwiZDoxIiwiZDoyIiwieDoxIiwieDowIl0sInN1YiI6InJlZnJlc2hfdG9rZW4iLCJhdF9oYXNoIjoiZ0FBQUFBQmxTZ0gwWlF4VnE4OUV1OXVScFlNUW83Qm9CTm5HMkRySjROaGN5QTMxaEpTUVZWSnI3dFBSdmdhZklXMENMdDdITmo4VDFEN3d4VXUxOGJhRVhaTll2ekp5WGNTbW1EX2VtX0VGQzZjOGNtaGRoWDA9IiwiZGlzcGxheV9uYW1lIjoiSklORU5EUkEgQkhBUkFUQkhBSSBTSEFIIiwib21zIjoiSzEiLCJoc21fa2V5IjoiNWYzNjlmMjRkZTdjNDE0ODcxZmZlZjkwY2I1ZjlkZWE2YmJkY2JjZTEzN2MwMzJiNzUyYzgyMzMiLCJmeV9pZCI6IlhKMTI0NjAiLCJhcHBUeXBlIjoxMDAsInBvYV9mbGFnIjoiTiJ9.oYUFdlEiKSiemqwG8izuT_2Fz44bAEQTUH_s_HBnZns"
  //     }
  // }
 

  localStorage.setItem("fyrestoken", data.data.access_token); 
    if (data.data.code === 200) {
      if (data.data.access_token !== "") {
        localStorage.setItem("fyrestoken", data.data.access_token);
        localStorage.setItem("fyres-refreshtoken", data.data.refresh_token);
        
        navigate("/home", { replace: true });
      } else {
      }
    }
  };
};

export default Getaccesstoken_fyres;
