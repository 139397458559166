import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASEURL } from "../zerodha/BaseURL";

//

export const zerodhapositionApi = createApi({
  
    reducerPath: "zerodhaposition",
    baseQuery: fetchBaseQuery({ baseUrl: BASEURL+'get-positions' ,
    prepareHeaders: (headers, { getState }) => {
        headers.set('authorization', localStorage.getItem("requesttoken"))
      return headers
    },

}),
    endpoints: (builder) => ({
    getZerodhaposition: builder.query({
        
        query: () => `?token=${localStorage.getItem('accessdata')}`,
        
      
    }),
    }),
  });


  export const zerodhaordersApi = createApi({
  
    reducerPath: "zerodhaorders",
    baseQuery: fetchBaseQuery({ baseUrl: BASEURL+'get-orders' ,
    prepareHeaders: (headers, { getState }) => {
        headers.set('authorization', localStorage.getItem("requesttoken"))
      return headers
    },
}),
    endpoints: (builder) => ({
    getZerodhaorders: builder.query({
        
        query: () => `?token=${localStorage.getItem('accessdata')}`,
        
        
    }),
    }),
  });


  export const zerodhaholdingsApi = createApi({
  
    reducerPath: "zerodhaholdings",
    baseQuery: fetchBaseQuery({ baseUrl: BASEURL+'get-holdings' ,
    prepareHeaders: (headers, { getState }) => {
        headers.set('authorization', localStorage.getItem("requesttoken"))
      return headers
    },
}),
    endpoints: (builder) => ({
    getZerodhaholdings: builder.query({
        
        query: () => `?token=${localStorage.getItem('accessdata')}`,
        
        
    }),
    }),
  });



  



  


  


  

  export const { useGetZerodhapositionQuery  } = zerodhapositionApi;
  export const { useGetZerodhaordersQuery  } = zerodhaordersApi;
  export const { useGetZerodhaholdingsQuery  } = zerodhaholdingsApi;
  