import React, { useEffect } from "react";
// import "../App.css";
import { Alert, Layout } from 'antd';

import { useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,

} from "react-router-dom";


import Sidebar from "./components/Sidebar";
import Header from "./components/Header";
import Marketwatch from "./modals/Marketwatch";
import Infomodal from "./modals/Infomodal";
import CreateOrder from "./modals/CreateOrder";

import Createalert from "./modals/CreateAlert";
import Technical from "./modals/Technical";
import SearchModal from "./modals/SearchModal";

// import Chargesmodal from "./modals/";

// import Basketonemodal from "./shared-components/Basketonemodal";
// import Newpolicymodal from "./shared-components/Newpolicymodal";
// import Editpolicymodal from "./shared-components/Editpolicymodal";


import WebsocketConnection from "./websocket/WebsocketConnection";
import Home from "./routes/Home";
import Holdings2 from "./routes/Holdings";
import Orders from "./routes/orders/Orders";
import Funds from "./routes/Funds";
import Positions from "./routes/Positions";
import Login from "./routes/Login";
import Chart from "./routes/Charts";


function Mainhome() {

  const { Header: HeaderLayout, Sider, Content } = Layout;

  const location = useLocation();

  const ordersshow = useSelector((state) => state.counter.ordersshow);
  
  const bulletin = useSelector((state) => state.counter.bulletinArray);
  

  if (localStorage.getItem("isZerodhaAuth") == null) {
    return <Login />
  }

  


  const headerStyle = {
    textAlign: 'center',
    backgroundColor: 'white',
  };

  const contentStyle = {
    minHeight: "100vh",
    backgroundColor: 'white',
  };

  const siderStyle = {
    backgroundColor: 'white',
  };

  return (
    <>

      {/* {<Basketonemodal />} */}
      <WebsocketConnection />
      {/* <Editpolicymodal /> */}
      {/* <Chargesmodal /> */}
      <SearchModal />
      {/* <Newpolicymodal /> */}

      <Createalert />
      <Marketwatch />
      <Infomodal />
      {ordersshow && <CreateOrder />}
      {/* <Creategtt /> */}
      <Technical />




      <Layout>
        <HeaderLayout style={headerStyle} className="p-0" ><Header /></HeaderLayout>
        <Layout hasSider={true}>
          <Sider
            className="border shadow-sm"
            breakpoint="lg"
            collapsedWidth="0"
            width={430}
            style={siderStyle} ><Sidebar />
          </Sider>
          <Content className="px-4 pt-3 pb-5" style={contentStyle}>
          {bulletin?.lenght > 0 && <Alert message={bulletin?.[0]?.content ?? ''} type="success" />}
            <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="holdings" element={<Holdings2 />} />
            <Route exact path="orders/*" element={<Orders />} />
            <Route exact path="funds" element={<Funds />} />
            <Route exact path="positions" element={<Positions />} />
            <Route exact path="sidebar" element={<Sidebar />} />
            <Route
              exact
              path="/chart/web/ciq/:exchange/:instrument/:token?"
              element={<Chart key={location.pathname} />}
            />

          </Routes></Content>
        </Layout>

      </Layout>
    </>
  );
}

export default Mainhome;
