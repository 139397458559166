import React from 'react'

function Consoledash() {
  return (
    <div className=''>
      <h1 className='box-title my-4' >Hi jinendra</h1>
      <div className='row'>

{/*Equity* */}
        <div className='col-6 align-items-start'>
<div className='d-flex justify-content-between border-bottom py-3 align-items-center'>
    <div className='fs-5' ><span className='me-2' ><i className="bi bi-pie-chart"></i></span>Equity</div>
    <div><span className='fs-med text-muted'><span className='me-2 '><i className="bi bi-clock-history"></i></span>Updated on </span><span className='text-primary'>2023-06-27</span></div>
</div>
{/*--------------* */}
<div className='row mt-4 align-items-start'>
<div className='col-4'>
    <div>Account value</div>
    <div className='fs-4'>18.42L</div>
</div>
<div className='col-4 '>
    <div className='mb-3'>
        <div className='fs-med text-muted' > Equity holdings</div>
        <div className='fs-5 my-2' >18,37,183.80</div>
    </div>
    <div className='mt-3' >
      <div className='fs-med text-muted '>Cash balance</div>
      <div className='fs-5 my-2'>5,265.98</div>
    </div>
</div>
<div className='col-4'></div>
</div>
        
        
        
        </div>



{/*Commodity* */}
        <div className='col-6'>
            
        </div>
      </div>
    </div>
  )
}

export default Consoledash
