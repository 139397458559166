// export const SITEURL = 'https://zerodha.rayvat.com/'
export const SITEURL = 'https://stocks.rayvat.com/'
export const BASEURL = 'https://zerodha-api.rayvat.com/'
export const CHARTURL ="https://www.axzor.com/chart_new/"
// export const SITEURL = 'http://localhost:3000/'
// export const BASEURL = 'http://localhost:5000/'

// export const SITEURL ="http://zerodha-live.rayvat.com/"
// export const BASEURL="http://zerodha-live-api.rayvat.com/"


export const APPKEY = '2jr4orrvnsf710uw'
export const APPSECRET = 'lawpzrvjowacuagsgvvpyua7rkjz83cc'
