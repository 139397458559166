import React, { useCallback,  useState } from "react";
import { holdingSummary, holdingSummaryPolicy} from "./api/api";
import { Button, Select, Table, Tag } from "antd";
import groupBy from "lodash/groupBy";
import forOwn from "lodash/forOwn";
import dateFormat from "dateformat";
import {
  useQuery,

} from '@tanstack/react-query'


import ComparisonChart from "./ComparisionChart";
import { Link } from "react-router-dom";
import { exportToExcel } from "../../libs/exportToExcel";


function HoldingSummaryPolicy() {
  const [loadingDownload, setloadingDownload] = useState(false);
  const [tabledata, settabledata] = useState([]);
  const [downloaddata, setdownloaddata] = useState("");


  const fetchdata = async () => {
    
    let data = await holdingSummaryPolicy();
    
    
    settabledata(data?.data)

    let isinGroup = groupBy(data?.data, "date");
    

    const final = []
    let amount = 0
    let total = 0

    forOwn(isinGroup, function (value, key) {

      const current = value.map((j) => j.quantity * j.ltp).reduce((a, b) => a + b, 0)?.toFixed(0)
      const invested = value.map((j) => j.quantity * j.average_price).reduce((a, b) => a + b, 0)?.toFixed(0)
      const ledger = data.ledger?.filter((d) => {

        return dateFormat(d.posting_date, "dd-mm-yyyy") == dateFormat(key, "dd-mm-yyyy")
      })

      if (amount == 0) {
        total = invested
      }



      if (ledger?.length > 0) {

        let debit = ledger?.map((a) => Number(a.Debit)).reduce((a, b) => a + b, 0)
        let credit = ledger?.map((a) => Number(a.Credit)).reduce((a, b) => a + b, 0)
        total = total - debit + credit
        amount = Number(amount) - debit + credit
      }

      



      const obj = {
        key,
        date: key,
        total_Openqty: value.map((j) => j.quantity).reduce((a, b) => a + b, 0),
        invested,
        current,
        quantity: value.map((j) => j.deduct_quantity).reduce((a, b) => a + b, 0),
        realised: value.map((j) => j.realised).reduce((a, b) => a + b, 0)?.toFixed(0),
        unrealised: value.map((j) => j.quantity * (Number(j.ltp) - Number(j.average_price)))?.reduce((a, b) => a + b, 0)?.toFixed(0),
        buyValue: value.map((j) => j.buyValue).reduce((a, b) => a + b, 0)?.toFixed(0),
        sellValue: value.map((j) => j.sellValue).reduce((a, b) => a + b, 0)?.toFixed(0),
        cash: total,
        cash_unrealised: current - total

      }


      final.push(obj)


    })




return final.sort((a,b)=>new Date(a.date)-new Date(a.date),0)

    
  };


  const {data,isLoading} = useQuery({ queryKey: ['holdingsSummaryPolicy'], queryFn: fetchdata })

  const tableColumns = [
    {
      title: "Symbol",
      dataIndex: "tradingsymbol",
      sorter: (a, b) => a.tradingsymbol.localeCompare(b.tradingsymbol),
      render: (_, elm) => <Link target="_blank" to={`/console/holding-summary-policy/${elm.isin}`}><span className="text-nowrap"> {elm.tradingsymbol}</span></Link>
    },
    {
      title: "ISIN",
      dataIndex: "isin",
      sorter: (a, b) => a.isin.localeCompare(b.isin),
      render: (_, elm) => <span className="text-nowrap"> {elm.isin}</span>
    },

    {
      title: "Qty",
      dataIndex: "deduct_quantity",
      sorter: (a, b) => a.deduct_quantity - b.deduct_quantity,
    },

    {
      title: "Price",
      dataIndex: "average_price",
      sorter: (a, b) => a.average_price - b.average_price,
      render: (_, elm) => elm.average_price.toFixed(2)
    },
    {
      title: "Ltp",
      dataIndex: "ltp",
      sorter: (a, b) => a.ltp - b.ltp,
      render: (_, elm) => elm.ltp.toFixed(2)
    },

    {
      title: "Openqty",
      dataIndex: "quantity",
      sorter: (a, b) => a.quantity - b.quantity,
    },

    {
      title: "Buy value",
      dataIndex: "buyValue",
      sorter: (a, b) => a.buyValue - b.buyValue,
      render: (_, elm) => <span className="text-nowrap"> {elm.buyValue?.toLocaleString("en-IN")}</span>,
    },
    {
      title: "Sell Value",
      dataIndex: "sellValue",
      sorter: (a, b) => a.sellValue - b.sellValue,
      render: (_, elm) => <span className="text-nowrap"> {elm.sellValue?.toLocaleString("en-IN")}</span>,
    },

    {
      title: "Open invested",
      dataIndex: "Open_invested",
      sorter: (a, b) => (a.quantity * a.average_price) - (b.quantity * b.average_price),
      render: (_, elm) => <span className="text-nowrap"> {(elm.quantity * elm.average_price).toFixed(0)}</span>

    },
    {
      title: "Open current",
      dataIndex: "Open_current",
      sorter: (a, b) => (a.quantity * a.ltp) - (b.quantity * b.ltp),
      render: (_, elm) => <span className="text-nowrap"> {Number((Number(elm.quantity) * Number(elm.ltp)).toFixed(0))?.toLocaleString("en-IN")}</span>

    },

    {
      title: "Realised",
      dataIndex: "realised",
      sorter: (a, b) => a.realised - b.realised,
      render: (_, elm) => (
        <span className={`text-nowrap font-bold ${elm.realised > 0 ? "text-success " : "text-danger "}`}>
          {Number(elm.realised.toFixed(0))?.toLocaleString("en-IN")}
        </span>
      ),
    },
    {
      title: "Unrealised",
      dataIndex: "unrealised",
      sorter: (a, b) => ((a.ltp - a.average_price) * a.quantity) - ((b.ltp - b.average_price) * b.quantity),
      render: (_, elm) => <span className={`text-nowrap font-bold ${((elm.ltp - elm.average_price) * elm.quantity) > 0 ? "text-success " : "text-danger "}`}>{Number(((elm.ltp - elm.average_price) * elm.quantity).toFixed(0))?.toLocaleString("en-IN")}</span>
    },
    {
      title: "prevdiff",
      dataIndex: "prevdiff",
      sorter: (a, b) => a.prevdiff - b.prevdiff,
      render: (_, elm) => <span className={`text-nowrap font-bold ${elm.prevdiff > 0 ? "text-success " : "text-danger "}`}>{Number(elm.prevdiff.toFixed(0))?.toLocaleString("en-IN")}</span>
    },

    {
      title: "Policy",
      dataIndex: "policy",
      render: (_, elm) => <Tag key={elm.policy}>
        {elm.policy.toUpperCase()}
      </Tag>,
    },
  ];


  const expandedRowRender = (b) => {
    const holding2 = tabledata.filter((a) => a.date == b.date)
    let isinGroup = groupBy(holding2, "policy");
    const final = []

    forOwn(isinGroup, function (value, key) {
      const obj = {
        policy: key,
        date: b.date,
        invested: value.map((j) => j.quantity * j.average_price).reduce((a, b) => a + b, 0)?.toFixed(0),
        current: value.map((j) => j.quantity * j.ltp).reduce((a, b) => a + b, 0)?.toFixed(0),
        unrealise: value.map((j) => j.quantity * (Number(j.ltp) - Number(j.average_price))).reduce((a, b) => a + b, 0)?.toFixed(0),
        realised: value.map((j) => j.realised).reduce((a, b) => a + b, 0)?.toFixed(0),
        buyValue: value.map((j) => j.buyValue).reduce((a, b) => a + b, 0)?.toFixed(0),
        sellValue: value.map((j) => j.sellValue).reduce((a, b) => a + b, 0)?.toFixed(0),
        quantity: value.map((j) => j.quantity).reduce((a, b) => a + b, 0)?.toFixed(0),
        deduct_quantity: value.map((j) => j.deduct_quantity).reduce((a, b) => a + b, 0)?.toFixed(0),
      }

      final.push(obj)

    })
    const tableColumns5 = [
      {
        title: "Policy",
        dataIndex: "policy",
        sorter: (a, b) => a.policy - b.policy,
      },
      {
        title: "quantity",
        dataIndex: "deduct_quantity",
        sorter: (a, b) => a.deduct_quantity - b.deduct_quantity,
      },
      {
        title: "buyValue",
        dataIndex: "buyValue",
        sorter: (a, b) => a.buyValue - b.buyValue,
        render: (_, elm) => <span className="text-nowrap"> {elm.buyValue?.toLocaleString("en-IN")}</span>
      },
      {
        title: "sellValue",
        dataIndex: "sellValue",
        sorter: (a, b) => a.sellValue - b.sellValue,
        render: (_, elm) => <span className="text-nowrap"> {elm.sellValue?.toLocaleString("en-IN")}</span>
      },
      {
        title: "realised",
        dataIndex: "realised",
        sorter: (a, b) => a.realised - b.realised,
        render: (_, elm) => <span className="text-nowrap"> {elm.realised?.toLocaleString("en-IN")}</span>
      },
      {
        title: "open qty",
        dataIndex: "quantity",
        sorter: (a, b) => a.quantity - b.quantity,
      },
      {
        title: "invested",
        dataIndex: "invested",
        sorter: (a, b) => a.invested - b.invested,
        render: (_, elm) => <span className="text-nowrap"> {elm.invested?.toLocaleString("en-IN")}</span>
      },
      {
        title: "current",
        dataIndex: "current",
        sorter: (a, b) => a.current - b.current,
        render: (_, elm) => <span className="text-nowrap"> {elm.current?.toLocaleString("en-IN")}</span>
      },
      {
        title: "unrealise",
        dataIndex: "unrealise",
        sorter: (a, b) => a.unrealise - b.unrealise,
        render: (_, elm) => <span className="text-nowrap"> {elm.unrealise?.toLocaleString("en-IN")}</span>

      },
      {
        title: "Download",
        dataIndex: "download",
        sorter: (a, b) => a.cash_unrealised - b.cash_unrealised,
        render: (_, elm) => <Button onClick={async () => {

          const holding2 = tabledata.filter((a) => a.date == elm.date && a.policy == elm.policy)
          const prevdate = new Date(elm.date).setDate(new Date(elm.date).getDate() - 1)
          const prevHolding = tabledata.filter((a) => dateFormat(a.date, "dd-mm-yyyy") == dateFormat(prevdate, "dd-mm-yyyy") && a.policy == elm.policy)
          holding2.forEach((g) => {
            const findValue = prevHolding.find((j) => j.isin == g.isin)
            if (findValue) {
              const currentUnrealise = g.ltp * g.quantity
              const prevUnrealise = findValue.ltp * findValue.quantity
              g.prevdiff = currentUnrealise - prevUnrealise
              g.quantitydiff = g.quantity - findValue.quantity
            } else {
              g.prevdiff = 0
            }

          })

          await downloadExcel(holding2)

        }}  >Download</Button>,

      },



    ]

    return (

      <Table
        dataSource={final}
        columns={tableColumns5}
        pagination={false}

        expandable={{
          expandedRowRender: expandedRowRender2,

        }}
      />


    );
  }

  const expandedRowRender2 = useCallback((b) => {
    const holding2 = tabledata.filter((a) => a.date == b.date && a.policy == b.policy)
    const prevdate = new Date(b.date).setDate(new Date(b.date).getDate() - 1)
    const prevHolding = tabledata.filter((a) => dateFormat(a.date, "dd-mm-yyyy") == dateFormat(prevdate, "dd-mm-yyyy") && a.policy == b.policy)
    
    holding2.forEach((g) => {
      const findValue = prevHolding.find((j) => j.isin == g.isin)
      if (findValue) {
        
        const currentUnrealise = g.ltp * g.quantity
        const prevUnrealise = findValue.ltp * findValue.quantity
        
        g.prevdiff = currentUnrealise - prevUnrealise
      } else {
        g.prevdiff = 0
      }

    })

    return (

      <Table
        dataSource={holding2}
        columns={tableColumns}
        pagination={false}

        bordered


      />


    );
  }, [tabledata])



  const summaryColumn = [

    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => a.date.localeCompare(b.date),
      render: (_, elm) => <span className="text-nowrap"> {dateFormat(elm.date, "dd-mm-yyyy")}</span>,


    },
    {
      title: "Quantity",
      dataIndex: "quantity",
    },

    {
      title: "Buyvalue",
      dataIndex: "buyValue",
      sorter: (a, b) => a.buyValue - b.buyValue,
      render: (_, elm) => <span className="text-nowrap"> {Number(elm.buyValue)?.toLocaleString("en-IN")}</span>,
    },
    {
      title: "Sellvalue",
      dataIndex: "sellValue",
      sorter: (a, b) => a.sellValue - b.sellValue,
      render: (_, elm) => <span className="text-nowrap" > {Number(elm.sellValue)?.toLocaleString("en-IN")}</span>,
    },



    {
      title: "Realised",
      dataIndex: "realised",
      sorter: (a, b) => a.realised - b.realised,
      render: (_, elm) => <span className="text-nowrap">{Number(elm.realised)?.toLocaleString("en-IN")}</span>,
    },
    {
      title: "Open Qty",
      dataIndex: "total_Openqty",
    },

    {
      title: "Invested",
      dataIndex: "invested",
      sorter: (a, b) => a.invested - b.invested,
      render: (_, elm) => <span className="text-nowrap">{Number(elm.invested)?.toLocaleString("en-IN")}</span>,
    },
    {
      title: "Cash Invested",
      dataIndex: "cash",
      sorter: (a, b) => a.cash - b.cash,
      render: (_, elm) => <span className="text-nowrap">{Number(elm.cash)?.toLocaleString("en-IN")}</span>,
    },
    {
      title: "Current",
      dataIndex: "current",
      sorter: (a, b) => a.current - b.current,
      render: (_, elm) => <span className="text-nowrap">{Number(elm.current)?.toLocaleString("en-IN")}</span>,
    },
    {
      title: "Unrealise",
      dataIndex: "unrealised",
      sorter: (a, b) => a.unrealised - b.unrealised,
      render: (_, elm) => <span className="text-nowrap">{Number(elm.unrealised)?.toLocaleString("en-IN")}</span>,

    },
    {
      title: "Cash unrealize",
      dataIndex: "cash_unrealised",
      sorter: (a, b) => a.cash_unrealised - b.cash_unrealised,
      render: (_, elm) => <span className="text-nowrap">{Number(elm.cash_unrealised)?.toLocaleString("en-IN")}</span>,

    },
    {
      title: "Download",
      dataIndex: "download",
      sorter: (a, b) => a.cash_unrealised - b.cash_unrealised,
      render: (_, elm) => <Button onClick={() => downloadExcel(tabledata.filter((a) => a.date == elm.date))}>Download</Button>,

    },

  ];

  const downloadExcelOverall = async (param) => {

try {
  
  setloadingDownload(true)
  let saveData
  if(downloaddata == "summary"){
    saveData=data
  } else if (downloaddata == "summaryBreakdown"){
    const final=[]

for (let k = 0; k < data?.length; k++) {
  const element = data[k];
  
  const obj={
    "Summary":"Summary",
    date:new Date(element.date),
    Qty:element.deduct_quantity,
    "Symbol":"",
      "ISIN" : "",
    "Buy Value":element.buyValue,
    "Sell Value":element.sellValue,
    Realised:element.realised,
    "Open Qty":element.quantity,
    Invested:element.invested,
    "Cash Invested":element.cash,
    Current:element.current,
    Unrealised:element.unrealised,
    "Cash Unrealize":element.cash_unrealised
  }
  final.push(obj)
  
  const breakdown=tabledata.filter((a) => a.date == element.date)


  breakdown.forEach((e)=>{
    const obj={
      "Summary":"",
      date:new Date(e.date),
      "Symbol":e.tradingsymbol,
      "ISIN" : e.isin,
      Qty:e.deduct_quantity,
      "Buy Value":e.buyValue,
      "Sell Value":e.sellValue,
      Realised:(e.buyValue-e.sellValue),
      "Open Qty":e.quantity,
      Invested:e.average_price * e.quantity,
      "Cash Invested":0,
      Current:e.ltp * e.quantity,
      Unrealised:(e.ltp - e.average_price)*e.quantity,
      "Cash Unrealize":"",
      Policy:e.policy
    }
    final.push(obj)

  })

  final.push({})
  
  
}
saveData=final
  } else if (downloaddata == "breakdown"){
    const final=[]

for (let k = 0; k < data?.length; k++) {
  const element = data[k];
  
  const breakdown=tabledata.filter((a) => a.date == element.date)


  breakdown.forEach((e)=>{
    const obj={
      "Summary":"",
      date:new Date(e.date),
      "Symbol":e.tradingsymbol,
      "ISIN" : e.isin,
      Qty:e.deduct_quantity,
      "Buy Value":e.buyValue,
      "Sell Value":e.sellValue,
      Realised:(e.buyValue-e.sellValue),
      "Open Qty":e.quantity,
      Invested:e.average_price * e.quantity,
      Current:e.ltp * e.quantity,
      Unrealised:(e.ltp - e.average_price)*e.quantity,
      "Cash Unrealize":"",
      Policy:e.policy
    }
    final.push(obj)

  })

  final.push({})
  
  
}
saveData=final
  } else if (downloaddata == "summaryPolicyBreakdown"){
    const final=[]

for (let k = 0; k < data?.length; k++) {
  const element = data[k];
  
  
  const obj={
    "Summary":"Summary",
    date:new Date(element.date),
    Qty:element.deduct_quantity,
    "Buy Value":element.buyValue,
    "Sell Value":element.sellValue,
    Realised:element.realised,
    "Open Qty":element.quantity,
    Invested:element.invested,
    "Cash Invested":element.cash,
    Current:element.current,
    Unrealised:element.unrealised,
    "Cash Unrealize":element.cash_unrealised
  }
  final.push(obj)
  
  const breakdown=tabledata.filter((a) => a.date == element.date)

  let isinGroup = groupBy(breakdown, "policy");

    
    
    forOwn(isinGroup, function (value, key) {
      const obj2={
        "Summary":"Summary Policy",
        date:new Date(element.date),
        Qty:value.map((l)=>l.deduct_quantity).reduce((a,b)=>a+b,0),
        "Buy Value":value.map((l)=>l.buyValue).reduce((a,b)=>a+b,0),
        "Sell Value":value.map((l)=>l.sellValue).reduce((a,b)=>a+b,0),
        Realised:value.map((l)=>l.buyValue-l.sellValue).reduce((a,b)=>a+b,0),
        "Open Qty":value.map((l)=>l.quantity).reduce((a,b)=>a+b,0),
        Invested:value.map((l)=>l.average_price * l.quantity).reduce((a,b)=>a+b,0),
        "Cash Invested":0,
        Current:value.map((l)=>l.ltp * l.quantity).reduce((a,b)=>a+b,0),
        Unrealised:value.map((l)=>(l.ltp - l.average_price)*l.quantity).reduce((a,b)=>a+b,0),
        "Cash Unrealize":"",
        Policy:key
      }
      final.push(obj2)
      value.forEach((e)=>{
        const obj={
          "Summary":"",
          date:new Date(e.date),
          Qty:e.deduct_quantity,
          "Buy Value":e.buyValue,
          "Sell Value":e.sellValue,
          Realised:(e.buyValue-e.sellValue),
          "Open Qty":e.quantity,
          Invested:e.average_price * e.quantity,
          "Cash Invested":0,
          Current:e.ltp * e.quantity,
          Unrealised:(e.ltp - e.average_price)*e.quantity,
          "Cash Unrealize":"",
          Policy:e.policy
        }
        final.push(obj)
    
      })
      final.push({})
    })


  

  final.push({})
  
  
}
saveData=final
  } else if (downloaddata == "summaryPolicySummary"){
    const final=[]

for (let k = 0; k < data?.length; k++) {
  const element = data[k];
  
  
  const obj={
    "Summary":"Summary",
    date:new Date(element.date),
    Qty:element.deduct_quantity,
    "Symbol":"",
      "ISIN" : "",
    "Buy Value":element.buyValue,
    "Sell Value":element.sellValue,
    Realised:element.realised,
    "Open Qty":element.quantity,
    Invested:element.invested,
    "Cash Invested":element.cash,
    Current:element.current,
    Unrealised:element.unrealised,
    "Cash Unrealize":element.cash_unrealised
  }
  final.push(obj)
  
  const breakdown=tabledata?.filter((a) => a.date == element.date)

  let isinGroup = groupBy(breakdown, "policy");

    
    
    forOwn(isinGroup, function (value, key) {
      

      const obj={
        "Summary":"Summary Policy",
        date:new Date(element.date),
        Qty:value.map((l)=>l.deduct_quantity).reduce((a,b)=>a+b,0),
        "Buy Value":value.map((l)=>l.buyValue).reduce((a,b)=>a+b,0),
        "Sell Value":value.map((l)=>l.sellValue).reduce((a,b)=>a+b,0),
        Realised:value.map((l)=>l.buyValue-l.sellValue).reduce((a,b)=>a+b,0),
        "Open Qty":value.map((l)=>l.quantity).reduce((a,b)=>a+b,0),
        Invested:value.map((l)=>l.average_price * l.quantity).reduce((a,b)=>a+b,0),
        "Cash Invested":0,
        Current:value.map((l)=>l.ltp * l.quantity).reduce((a,b)=>a+b,0),
        Unrealised:value.map((l)=>(l.ltp - l.average_price)*l.quantity).reduce((a,b)=>a+b,0),
        "Cash Unrealize":"",
        Policy:key
      }
      final.push(obj)
      
    })


  

  final.push({})
  
  
}
saveData=final
  }
  
  await exportToExcel(saveData)
  setloadingDownload(false)
    
} catch (error) {
  setloadingDownload(false)
}


    
    
  };

  const downloadExcel = async (data) => {
    const data2 = []

    data.sort((a,b)=>a.tradingsymbol-b.tradingsymbol,0).forEach((d) => {
      const obj = {
        Date: dateFormat(d.date, "dd-mm-yyyy"),
        Symbol: d.tradingsymbol,
        ISIN: d.isin,
        Qty: d.deduct_quantity,
        Price: d.average_price,
        Lpt: d.ltp,
        "Open Qty": d.quantity,
        "Buy Value": d.buyValue,
        "Sell Value": d.sellValue,
        "Open Invested": d.average_price * d.quantity,
        "Open Current": d.ltp * d.quantity,
        "Realised": d.buyValue - d.sellValue,
        "Unrealised": (d.ltp - d.average_price) * d.quantity,
        "Prev Diff": d?.prevdiff ?? 0,
        "Policy": d.policy

      }
      data2.push(obj)
    })
    await exportToExcel(data2)
  };

  const handleChange = (value) => {
    setdownloaddata(value)
  };

  return (
    <div className="my-5">
      
          <div>
            {data?.length > 0 && (
              <div>
                <ComparisonChart rawdata={tabledata} data={data} />
              </div>
            )}
          </div>
          <div className="d-flex justify-start align-items-center my-3">
            <div className="me-2">
            <Select
      defaultValue="summary"
      style={{
        width: 300,
      }}
      onChange={handleChange}
      options={[
        {
          value: 'summary',
          label: 'Overall summary',
        },
        {
          value: 'summaryBreakdown',
          label: 'Summary-Breakdown',
        },
        {
          value: 'breakdown',
          label: 'Breakdown',
        },
        {
          value: 'summaryPolicySummary',
          label: 'Summary with policy summary',
        },
        {
          value: 'summaryPolicyBreakdown',
          label: 'Summary with policy breakdown',
        },
      ]}
    />
            </div>
            <div>
            <Button loading={loadingDownload} onClick={()=>downloadExcelOverall()}>Download</Button>
          </div>
          </div>
          

          <Table

            dataSource={data}
            expandable={{
              expandedRowRender,

            }}
            loading={isLoading}
            bordered


            columns={summaryColumn}

            sticky={{
              offsetHeader: 55,
            }}
          />
       
    </div>
  );
}

export default HoldingSummaryPolicy;
