import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";

import { home, reloaddata } from "../../../api/api";
import { AgGridReact } from 'ag-grid-react';
import { useSelector } from "react-redux";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Button, Select, Tooltip } from "antd";




function Performance() {

  const [data, setdata] = useState([]);
  const [exchange, setexchange] = useState("NSE");
  const [loading, setloading] = useState(false);
  const [liveloading, setliveloading] = useState(false);
  const [msg, setmsg] = useState("");
  const mode = useSelector((state) => state.counter.theme);

  const [change, setchange] = useState({
    name: false,
    oneday: false,
    threeday: false,
    week: false,
    onemonth: false,
    mtd: false,
    threemonth: false,
    sixmonth: false,
    YTD: false,
    oneyear: false,
    threeyear: false,
    fiveyear: false,
    sevenyear: false,
    tenyear: false,
    twelveyear: false,
    fifteenyear: false,
    symbol: false
  });


  const gridRef = useRef();


  const fetchdata = async () => {
    setloading(true);

    let data = await home(exchange);

    setdata(data?.data);
    setmsg(data?.time);
    setloading(false);
  };




  const livefetch = async () => {
    setliveloading(true);
    try {
      let data = await reloaddata(exchange);
      setmsg(data.data);
      setliveloading(false);
      fetchdata();
    } catch (error) {

    } finally {
      setliveloading(false);
    }

  };
  useEffect(() => {
    fetchdata();
  }, [exchange]);




  const onFirstDataRendered = useCallback((params) => {
    // console.log(params);
    gridRef.current.api.sizeColumnsToFit();
    gridRef.current.api.setFilterModel(null);
  }, []);



  const tableColumns2 = [
    {
      headerName: "Name",
      field: "name",
      rowDrag: true,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      filterParams: {
        buttons: ['reset', 'apply'],
      },


      minWidth: 220,
      cellStyle: { 'padding': 5 },

      hide: change.name,
    },
    {
      headerName: "Symbol",
      field: "symbol",
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      minWidth: 100,
      filterParams: {
        buttons: ['reset', 'apply'],
      },
      cellRenderer: function (params) {
        return <a href={"/company/" + params.data.symbol} target="_blank" rel="noopener"> {params.value} </a>
      },

      cellStyle: { 'padding': 5 },

      hide: change.symbol,
    },
    {
      headerName: "Last price",
      field: "ltp",
      minWidth: 100,
      filterParams: {
        buttons: ['reset', 'apply'],
      },

      volatile: true, suppressMovable: true,
      cellClass: function (params) { return (params.value > 0 ? 'green ' : 'red '); },
      cellStyle: { 'padding': 0, "width": "50px" },
      // hide: change.oneday,




    },
    {
      headerName: "Daily",
      field: "oneday",
      minWidth: 100,
      filterParams: {
        buttons: ['reset', 'apply'],
      },

      volatile: true, suppressMovable: true,
      cellClass: function (params) { return (params.value > 0 ? 'green ' : 'red '); },
      cellStyle: { 'padding': 0, "width": "50px" },
      hide: change.oneday,




    },

    {
      headerName: "3 day",
      minWidth: 100,
      field: "threeday",
      filterParams: {
        buttons: ['reset', 'apply'],
      },

      cellClass: function (params) { return (params.value > 0 ? 'green' : 'red'); },
      cellStyle: { 'padding': 0 },
      hide: change.threeday,
      valueFormatter: params => params?.data?.threeday?.toFixed(2),

    },

    {
      headerName: "Week",
      minWidth: 100,
      field: "week",
      filterParams: {
        buttons: ['reset', 'apply'],
      },

      cellClass: function (params) { return (params.value > 0 ? 'green' : 'red'); },
      cellStyle: { 'padding': 0 },
      hide: change.week,
      valueFormatter: params => params?.data?.week?.toFixed(2),

    },

    {
      headerName: "1 month",
      minWidth: 100,
      field: "onemonth",
      filterParams: {
        buttons: ['reset', 'apply'],
      },

      cellClass: function (params) { return (params.value > 0 ? 'green' : 'red'); },
      cellStyle: { 'padding': 0 },
      hide: change.onemonth,
      valueFormatter: params => params?.data?.onemonth?.toFixed(2),

    },

    {
      headerName: "MTD",
      minWidth: 100,
      field: "mtd",
      filterParams: {
        buttons: ['reset', 'apply'],
      },

      cellClass: function (params) { return (params.value > 0 ? 'green' : 'red'); },
      cellStyle: { 'padding': 0 },
      hide: change.mtd,
      valueFormatter: params => params?.data?.mtd?.toFixed(2),

    },

    {
      headerName: "3 month",
      minWidth: 100,
      field: "threemonth",
      filterParams: {
        buttons: ['reset', 'apply'],
      },

      cellClass: function (params) { return (params.value > 0 ? 'green' : 'red'); },
      cellStyle: { 'padding': 0 },
      hide: change.threemonth,
      valueFormatter: params => params?.data?.threemonth?.toFixed(2),

    },

    {
      headerName: "6 month",
      minWidth: 100,
      field: "sixmonth",
      filterParams: {
        buttons: ['reset', 'apply'],
      },

      cellClass: function (params) { return (params.value > 0 ? 'green' : 'red'); },
      cellStyle: { 'padding': 0 },
      hide: change.sixmonth,
      valueFormatter: params => params?.data?.sixmonth?.toFixed(2),

    },

    {
      headerName: "YTD",
      minWidth: 100,
      field: "YTD",
      filterParams: {
        buttons: ['reset', 'apply'],
      },

      cellClass: function (params) { return (params.value > 0 ? 'green' : 'red'); },
      cellStyle: { 'padding': 0 },
      hide: change.YTD,
      valueFormatter: params => params?.data?.YTD?.toFixed(2),

    },

    {
      headerName: "1 year",
      minWidth: 100,
      field: "oneyear",
      filterParams: {
        buttons: ['reset', 'apply'],
      },

      cellClass: function (params) { return (params.value > 0 ? 'green' : 'red'); },
      cellStyle: { 'padding': 0 },
      hide: change.oneyear,
      valueFormatter: params => params?.data?.oneyear?.toFixed(2),

    },

    {
      headerName: "3 year",
      minWidth: 100,
      filterParams: {
        buttons: ['reset', 'apply'],
      },
      field: "threeyear",

      cellClass: function (params) { return (params.value > 0 ? 'green' : 'red'); },
      cellStyle: { 'padding': 0 },
      hide: change.threeyear,
      valueFormatter: params => params?.data?.threeyear?.toFixed(2),

    },

    {
      headerName: "5 year",
      minWidth: 100,
      field: "fiveyear",
      filterParams: {
        buttons: ['reset', 'apply'],
      },

      cellClass: function (params) { return (params.value > 0 ? 'green' : 'red'); },
      cellStyle: { 'padding': 0 },
      hide: change.fiveyear,
      valueFormatter: params => params?.data?.fiveyear?.toFixed(2),

    },

    {
      headerName: "7 year",
      minWidth: 100,
      field: "sevenyear",
      filterParams: {
        buttons: ['reset', 'apply'],
      },

      cellClass: function (params) { return (params.value > 0 ? 'green' : 'red'); },
      cellStyle: { 'padding': 0 },
      hide: change.sevenyear,
      valueFormatter: params => params?.data?.sevenyear?.toFixed(2),

    },

    {
      headerName: "10 year",
      minWidth: 100,
      field: "tenyear",
      filterParams: {
        buttons: ['reset', 'apply'],
      },

      cellClass: function (params) { return (params.value > 0 ? 'green' : 'red'); },
      cellStyle: { 'padding': 0 },
      hide: change.tenyear,
      valueFormatter: params => params?.data?.tenyear?.toFixed(2),

    },

    {
      headerName: "12 year",
      minWidth: 100,
      field: "twelveyear",
      filterParams: {
        buttons: ['reset', 'apply'],
      },

      cellClass: function (params) { return (params.value > 0 ? 'green' : 'red'); },
      cellStyle: { 'padding': 0 },
      hide: change.twelveyear,
      valueFormatter: params => params?.data?.twelveyear?.toFixed(2),

    },

    {
      headerName: "15 year",
      minWidth: 100,
      field: "fifteenyear",
      filterParams: {
        buttons: ['reset', 'apply'],
      },

      cellClass: function (params) { return (params.value > 0 ? 'green' : 'red'); },
      cellStyle: { 'padding': 0 },
      hide: change.fifteenyear,
      valueFormatter: params => params?.data?.fifteenyear?.toFixed(2),

    },

  ];







  const defaultColDef = useMemo(() => {
    return {


      filter: true,

      domLayout: '1000px',

      rowGroupPanelShow: 'always',
      pivotPanelShow: 'always',
      sortable: true,
      resizable: true,


      wrapHeaderText: true,
      autoHeaderHeight: true,


    };
  }, []);

  const divRef = useRef(null);
  const divRef2 = useRef(null);



  const plainOptions = [
    { value: "name", label: "name" },
    { value: "oneday", label: "oneday" },
    { value: "threeday", label: "threeday" },
    { value: "week", label: "week" },
    { value: "onemonth", label: "onemonth" },
    { value: "mtd", label: "mtd" },
    { value: "threemonth", label: "threemonth" },
    { value: "sixmonth", label: "sixmonth" },
    { value: "YTD", label: "YTD" },
    { value: "oneyear", label: "oneyear" },
    { value: "threeyear", label: "threeyear" },
    { value: "fiveyear", label: "fiveyear" },
    { value: "sevenyear", label: "sevenyear" },
    { value: "tenyear", label: "tenyear" },
    { value: "twelveyear", label: "twelveyear" },
    { value: "fifteenyear", label: "fifteenyear" },
    { value: "symbol", label: "symbol" }
  ]

  const handleChangeOfColumn = (e) => {


    const obj = { ...change }

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {

        const findValue = e.find((j) => j == key)

        if (findValue) obj[key] = false
        else obj[key] = true

      }
    }





    setchange(obj)

  }


  return (
    <div className={mode ? "bg-dark" : ""} >
      
          <div className="d-md-flex flex-md-wrap justify-content-end my-2 mx-2" >
          <Button>Last records time:{msg}</Button>

          <Button className="mx-2" disabled={localStorage.getItem("isZerodhaAuth") === null} onClick={() => livefetch()}>
            {liveloading ? "fetching..." : "Fetch live"}
          </Button>


          <Select
            defaultValue="NSE"
            style={{
              width: 120,
            }}
            onChange={(e) => {
              setexchange(e);
            }}
            options={[
              {
                value: 'NSE',
                label: 'NSE',
              },
              {
                value: 'BSE',
                label: 'BSE',
              },

            ]}
          />


          <Tooltip title='Download excel file'>
            <Button className="mx-2" type="primary" onClick={() => gridRef.current.api.exportDataAsCsv()}>Download <i className="bi bi-box-arrow-down"></i></Button>
          </Tooltip>

          <Tooltip title="Unselect to remove column">
            <Select

              defaultValue={plainOptions.map((ele) => ele.value)}
              onChange={handleChangeOfColumn}
              style={{
                width: 200,
              }}
              placeholder="Please select to remove column"
              mode="tags"
              maxTagCount='responsive'
              options={plainOptions}
            />
          </Tooltip>

        </div>

        {loading ? (
          <div className="h-100 w-100 d-flex align-items-center justify-content-center">
            <div className="spinner-border" role="status"></div>
          </div>
        ) : (
          <>
            <div className=" m-3  " ref={divRef2}

            >


              <div className={`w-100  ${mode ? "ag-theme-alpine-dark mb-5 pb-5" : "ag-theme-alpine mb-5 pb-5"}`} ref={divRef}
                style={{ height: "100vh" }}

              >



                <AgGridReact
                  ref={gridRef}
                  rowData={data}
                  columnDefs={tableColumns2}
                  defaultColDef={defaultColDef}
                  rowDragManaged={true}
                  onFirstDataRendered={onFirstDataRendered}
                  paginationPageSize={100}
                  pagination={true}
                />



              </div>


            </div>


          </>
        )}
      
    </div>
  );
}

export default Performance;







