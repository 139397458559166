import React, { useState, useEffect } from "react";
import { Empty, Table, Tag} from "antd";




// import { format, subYears } from "date-fns";



// import { motion } from "framer-motion";

import { gttPost, zerodhaGetGtt } from "../../api/api";
import Dropdown from "../../components/DropdownOrderMenu";
import { useSelector, useDispatch } from "react-redux";
// import { showgtt, showmodal } from "../features/counterSlice";
// import Creategtt from "./CreateGTT";

const GTT = (props) => {
  // const dispatch = useDispatch();
  // const d = format(subYears(new Date(), 1), "yyyy-MM-dd");
  // const e = format(new Date(), "yyyy-MM-dd");
  // const date = [d, e];

  

  
  
  const [gttdata, setgttdata] = useState([]);
  const [loading, setloading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setloading(true)
    const data = await zerodhaGetGtt();
    // console.log(data);
    setgttdata(data?.data);
    setloading(false);
  };

  const tableColumns = [
    {
      title: "Created on",
      dataIndex: "created_at",
      sorter: (a, b) => new Date(a.created_at) -  new Date(b.created_at),
    },
    {
      title: "Instrument",
      dataIndex: "instrument",
      sorter: (a, b) => a?.tradingsymbol - b?.tradingsymbol,
      render: (_, elm) => (
        <div className="d-flex justify-content-between">
          <div>
            {elm?.condition.tradingsymbol}
            <small>{elm?.condition.exchange}</small>
          </div>
          <div>
            <Dropdown data={elm} />
          </div>
        </div>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      sorter: (a, b) => a.transaction_type - b.transaction_type,
      render: (_, elm) => (
        <>
        <Tag>{elm?.type}</Tag>
        <Tag color={elm?.orders[0].transaction_type =="BUY" ? 'green' :'red'} >  {elm?.orders[0].transaction_type}</Tag>
        </>
        // <div className="d-flex">
        //   <span className="bg-light me-2">{elm?.trigger_type}</span>
        //   <span
        //     className={
        //       elm?.transaction_type === "BUY"
        //         ? "badge text-bg-primary"
        //         : "badge text-bg-danger"
        //     }
        //   >
        //     {elm?.transaction_type}
        //   </span>
        // </div>
      ),
    },
    {
      title: "Trigger",
      dataIndex: "trigger",
    
      render: (_, elm) => (elm.condition.trigger_values[0]
        // <div>
        //   <span>{elm.trigger_values_0}</span>
        //   <span>{"/" + elm.trigger_values_1}</span>
        // </div>
      ),
    },
    {
      title: "LTP",
      dataIndex: "LTP",
      sorter: (a, b) => a.last_price - b.last_price,
      render: (_, elm) => elm?.condition.last_price?.toFixed(2),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      sorter: (a, b) => a.orders[0]?.quantity - b.orders[0]?.quantity,
      render: (_, elm) =>elm.orders[0]?.quantity
        // `${elm.stop_quantity}${
        //   elm.target_quantity ? "/" + elm.target_quantity : ""
        // }`,
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (_, elm) => (
        <Tag color={elm.status=='active' ? "green":'gray'}>{elm.status}</Tag>
        // <span
        //   className={
        //     elm.status === "active"
        //       ? "badge text-bg-primary"
        //       : "badge text-bg-danger"
        //   }
        // >
        //   {elm.status}
        // </span>
      ),
    },
  ];

 

  return (
    <>
      <>
        <div >
          
            <div className="d-flex align-items-start w-100 justify-content-between">
              <h5>GTT({gttdata?.length})</h5>
              {/* <Creategtt/> */}

              {/* TODO : CREATE SEARCH MODAL */}

              {/* <div className="ms-md-3 mb-3 ">
                <button
                  type="button"
                  className="btn btn-sm  btn-primary"
                  onClick={() => {
                    dispatch(showmodal("#creategtt22"));
                  }}
                  data-bs-toggle="modal"
                  data-bs-target="#searchmodal"
                >
                  New GTT
                </button>
              </div> */}
            </div>
            <div >
              {gttdata?.length >0 ?<Table columns={tableColumns} loading={loading} dataSource={gttdata} />:<Empty/>}
              </div>
          
        </div>
      </>
    </>
  );
};

export default GTT;
