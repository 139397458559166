import React from "react";
import { NavLink } from "react-router-dom";

function Navbar() {
  return (
    <div className="header shadow-sm fs-med border-bottom sticky-top bg-white">
      <div className="w-100 d-flex p-0 m-0">
        {/* left */}
        <div className=" border-end p-0-15" style={{flex: "0 0 430px",}}>
          <div
            className="d-flex justify-content-between align-items-center "
            style={{
              fontWeight: 600,
              height: "60px",
              padding: "0 15px",
              
            }}
          >
            <div className="d-flex align-items-center">
              <span className="m-r-2px">NIFTY 50 19537.50</span>{" "}
              <span className="fs-small m-r-2px">-196.05 (-0.99%)</span>
            </div>
            <div className="d-flex align-items-center">
              <span className="m-r-2px">SENSEX 65814.69</span>{" "}
              <span className="fs-small m-r-2px"> -644.62 (-0.97%)</span>
            </div>
          </div>
        </div>

        {/* right */}
        <div className="header-right align-items-center">
          <a href="/">
            <img
              style={{ width: "27px", margin: "0 20px 0 30px" }}
              src="/jenyalogo2.png"
              alt="kitelogo"
            />
          </a>
          <div className="app-nav">
            <NavLink className={({ isActive }) =>isActive ? "p-0-15 active text-orange " : " p-0-15 text-default "}  to="">
              Dashboard
            </NavLink>
            <NavLink className={({ isActive }) =>isActive ? "p-0-15 active text-orange " : " p-0-15 text-default"} to="orders">
              Orders
            </NavLink>
            <NavLink className={({ isActive }) =>isActive ? "p-0-15 active text-orange " : " p-0-15 text-default"} to="holdings">
              Holdings
            </NavLink>
            <NavLink className={({ isActive }) =>isActive ? "p-0-15 active text-orange " : " p-0-15 text-default"} to="Positions">
              Positions
            </NavLink>
            <NavLink className={({ isActive }) =>isActive ? "p-0-15 active text-orange " : " p-0-15 text-default"} to="Funds">
              Funds
            </NavLink>
            {/* <NavLink className={({ isActive }) =>isActive ? "p-0-15 active text-orange " : " p-0-15 "} to="">
              Apps
            </NavLink> */}
          </div>
          <div className="d-flex   ">
            <div className="m-15-0">
              <div className="m-0-15">
                <i class="bi bi-bell "></i>
              </div>
            </div>
            <div className="d-flex" style={{ padding: "15px 10px" }}>
              <a href className="d-flex">
                <div className="">JG</div>
                <span>GMG829</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
