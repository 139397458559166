
import React, {useState, useEffect} from 'react'
// import { useNavigate, useSearchParams } from 'react-router-dom';
import Iframe from 'react-iframe'
import {CHARTURL} from  "../BaseURL"
import { useNavigate } from "react-router-dom";
import  {notification } from 'antd';


const Chart = () => {
	const [modeldata,setmodeldata] = useState([])	;

	const navigate = useNavigate();
	useEffect(() => {


		let accessdata= localStorage.getItem("accessdata")
		
			let urlprm = window.location.pathname;
			let segment = urlprm.split('/');
			if(!segment[5] ){
				notification.info({
					message: "Symbol Not Available",
					description: `Please use Another Symbol`	
				});
				navigate("/", { replace: true })

			}
			
			// let exchange = searchParams.get("request_token")
    		// setmodeldata(`https://kite.zerodha.com/static/build/chart.html?v=3.0.12#token=${segment[6]}&symbol=${segment[5]}&segment=${segment[4]}&volume=true&enctoken=3N1JmMaH7cBAP85JMDau6hjmuMuI3LQct1S0RK3hCEEOb9wi07TNNzg9WRdtESd2LMGgpOxE5k%2BQdLmK9TNj%2F2vXeVEv3kUCZGeAx1UyCUNDxlYsb%2Bv80g%3D%3D&user_id=GMG829&access_token=&api_key=&source=&sdk=&theme=&chart_type=&build_version=&inapp=true`)						
    		// setmodeldata(`http://localhost/chart10/?symbol=${segment[5]}&segment=${segment[4]}&token=${segment[6]}&interval=1d&theme=light&accessdata=${accessdata}`)						
			// console.log(segment[6])
			setmodeldata(`${CHARTURL}?symbol=${segment[5]}&segment=${segment[4]}&token=${segment[6]}&interval=1d&theme=light&accessdata=${accessdata}`)						
			// console.log(`https://kite.zerodha.com/static/build/chart.html?v=3.0.12#token=${segment[6]}&symbol=${segment[5]}&segment=${segment[4]}&volume=true&enctoken=3N1JmMaH7cBAP85JMDau6hjmuMuI3LQct1S0RK3hCEEOb9wi07TNNzg9WRdtESd2LMGgpOxE5k%2BQdLmK9TNj%2F2vXeVEv3kUCZGeAx1UyCUNDxlYsb%2Bv80g%3D%3D&user_id=GMG829&access_token=&api_key=&source=&sdk=&theme=&chart_type=&build_version=&inapp=true`);
	}, [])

	return (
	<>



		<Iframe url={modeldata}
		width="100%"
		height="580vh"
		id=""
		className=""
		display="block"
		position="relative"/>		
	</>
	)
}

export default Chart