import React, { useState, useEffect } from "react";
import { redirect, useNavigate } from "react-router-dom";
import { BASEURL, APPKEY, SITEURL } from "../BaseURL";


import { Button, Form, Input } from 'antd';


import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Space } from "antd";

const Login = () => {
  const navigate = useNavigate();
  const [user_id, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [errormsg, seterrormsg] = useState("");

  const [inputtoken, setinputtoken] = useState("");



  useEffect(() => {
    if (localStorage.getItem('isZerodhaAuth')) {
      navigate("/", { replace: true });
    }
  }, [])





  const loginsubmit = async (type) => {
    
  if (user_id && password) {
      const res = await fetch(BASEURL + "login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id,
          password,
        }),
      });

      const data = await res.json();


      if (type === "fyers") {
        let url = `https://api.fyers.in/api/v2/generate-authcode?client_id=98RFUNK49Z-100&redirect_uri=${SITEURL}getaccesstoken-fyers&response_type=code&state=sample_state`;
        http://127.0.0.1:3000/getaccesstoken-fyers?s=ok&code=200&auth_code=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJhcGkubG9naW4uZnllcnMuaW4iLCJpYXQiOjE3MDQwOTkwNjcsImV4cCI6MTcwNDEyOTA2NywibmJmIjoxNzA0MDk4NDY3LCJhdWQiOiJbXCJ4OjBcIiwgXCJ4OjFcIiwgXCJ4OjJcIiwgXCJkOjFcIiwgXCJkOjJcIiwgXCJ4OjFcIiwgXCJ4OjBcIl0iLCJzdWIiOiJhdXRoX2NvZGUiLCJkaXNwbGF5X25hbWUiOiJYSjEyNDYwIiwib21zIjoiSzEiLCJoc21fa2V5IjoiOWVmMmIwOGE4ZmYwZGY3OGQxZThhZmY3ODg1MTliMDNiMzg3MzYxMmMzOGFjN2MwZWNhMjE2NTEiLCJub25jZSI6IiIsImFwcF9pZCI6Ijk4UkZVTks0OVoiLCJ1dWlkIjoiOGIzMjZhZmZjNjIwNDFiM2I5ZjZmOWZmNGNkZmQyMDMiLCJpcEFkZHIiOiIwLjAuMC4wIiwic2NvcGUiOiIifQ.vcEUKjskk2cievWKzZrfsCCH4zAU50eq6uyAS4el02o&state=sample_state
        window.location.href = url;
        // navigate("/", { replace: true });            
      }
      // http://127.0.0.1:3000


      if (data.status === 201) {
        localStorage.setItem("isZerodhaAuth", JSON.stringify(data));
        localStorage.setItem("requesttoken", JSON.stringify(data.usertoken));
        navigate("/", { replace: true });
      } else {
        seterrormsg(data.error);
      }
    }
  };

  const tokeninput = (e) => {
    setinputtoken(e.target.value);
  };

  return (
    <>
      <div className="d-flex justify-content-center flex-column align-items-center min-vh-100">
        <div className="border shadow-sm px-4 py-5">
          <div className="d-flex justify-content-center  align-items-center ">
            <img src="/jenyalogo2.png" className="w-25 h-25" />
          </div>
          <Form
            name="login"
            layout="vertical"
            style={{
              maxWidth: 400,
            }}
            initialValues={{
              remember: true,
            }}
            // onFinish={loginsubmit}
            autoComplete="off"
          >
            <Form.Item
              label="Username"
              name="username"
              rules={[
                {
                  required: true,
                  message: 'Please input your username!',
                },
              ]}
            >
              <Input onChange={(e) => setemail(e.target.value)} />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
              ]}
            >
              <Input.Password onChange={(e) => setpassword(e.target.value)} />
            </Form.Item>


            <div className="text-center">{errormsg}</div>
            <Form.Item

            >
              <Button className="w-100" type="primary"  onClick={(e) => {
                              e.preventDefault()
                              loginsubmit("zerodha");
                            }}>
                Login Zerodha
              </Button>

              <Button
                            // type="button"
                            type="primary"
                            className="w-100 mt-2"
                            onClick={(e) => {
                              e.preventDefault()
                              loginsubmit("fyers");
                            }}
                          
                          >
                            Login fyers
                          </Button>

              {/* <Button className="w-100" type="primary" htmlType="submit">
                Login Fyers
              </Button> */}


              <Dropdown
                dropdownRender={(menu) => (
                  <>
                    <div className="d-flex flex-column w-100 ">
                      <Input className="mt-2" placeholder="Optional accesstoken" onChange={tokeninput} />
                      <Input className="mt-2" placeholder="Copy console cookie" onChange={(e) => { localStorage.setItem("hcookie", JSON.stringify(e.target.value)) }} />
                      <Input className="mt-2" placeholder="Copy console X-Csrftoken" onChange={(e) => { localStorage.setItem("z_public_token", JSON.stringify(e.target.value)) }} />
                      <Input className="mt-2" placeholder="Copy zerodha cookie" onChange={(e) => { localStorage.setItem("zhcookie", JSON.stringify(e.target.value)) }} />
                      <Input className="mt-2" placeholder="Copy zerodha Authorization" onChange={(e) => { localStorage.setItem("zAuthorixation", JSON.stringify(e.target.value)) }} />
                      <Input className="mt-2" placeholder="Copy zerodha Websocket" onChange={(e) => { localStorage.setItem("websocket", JSON.stringify(e.target.value)) }} />
                    </div>
                  </>
                )}
                trigger={["click"]}
              >
                <Button className="mt-3" >
                  <Space>
                    Set Token
                    <DownOutlined />
                  </Space>
                </Button>

              </Dropdown>



            </Form.Item>

          </Form>
          <Button onClick={()=>redirect('/home')}>Screener</Button>
        </div>
      </div>
    </>
  );
};

export default Login;
