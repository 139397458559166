import React, { useState, useEffect } from "react";
import { pnl } from "./api/api";
import { Button, Input, Table } from "antd";
// import { CSVLink } from "react-csv";
import { DatePicker } from "antd";
import dateFormat from "dateformat";
// import { Excel } from "antd-table-saveas-excel";
import { exportToExcel } from "../../libs/exportToExcel";
const { RangePicker } = DatePicker;


function Pl() {
  // useEffect(() => {
  //   fetchdata();
  // }, []);

  const [data, setdata] = useState([]);
  const [dateArr, setdateArr] = useState([]);
  // const [databkp, setdatabkp] = useState([]);
  const [loading, setloading] = useState(false);
  const [search, setsearch] = useState("");

  const onChange = (date, dateString) => {
    // console.log( dateString);
    setdateArr(dateString);
  };

  const fetchdata = async () => {
    setloading(true);
    let data = await pnl(dateArr[0], dateArr[1]);
    setdata(data?.data);
    setloading(false);
    return data.data
  };

  console.log(fetchdata);

  const handleClick = () => {
    exportToExcel(data)
    // const excel = new Excel();
    // excel
    //   .addSheet("P&L")
    //   .addColumns(tableColumns)
    //   .addDataSource(data, {
    //     str2Percent: true
    //   })
    //   .saveAs("P&L.xlsx");
  };
  const tableColumns = [
    // {
    //   title: "Date",
    //   dataIndex: "date",
    //   sorter: (a, b) =>a.date.localeCompare(b.date),
    //   render: (_, elm) => dateFormat(elm?.event_date, "dd-mm-yyyy"),
    // },
    {
      title: "Symbol",
      dataIndex: "tradingsymbol",
      sorter: (a, b) => a.tradingsymbol.localeCompare(b.tradingsymbol),
    },
    {
      title: "ISIN",
      dataIndex: "isin",
      sorter: (a, b) => a.isin.localeCompare(b.isin),
    },

    {
      title: "Qty",
      dataIndex: "quantity",
      sorter: (a, b) => a.quantity - b.quantity,
    },
    {
      title: "Buy avg",
      dataIndex: "buyavg",
      sorter: (a, b) => a.buyavg - b.buyavg,
    },
    {
      title: "Buy value",
      dataIndex: "buyvalue",
      sorter: (a, b) => Number(a.buyvalue) - Number(b.buyvalue),
      // render: (_, elm) => (elm.quantity*elm.average_price).toFixed(2),
    },
    {
      title: "Sell avg.",
      dataIndex: "sell_avg",
      sorter: (a, b) => a.sell_avg - b.sell_avg,
    },

    {
      title: "Sell value",
      dataIndex: "sell_value",
      sorter: (a, b) => a.quantity * a.sell_avg - b.quantity * b.sell_avg,
      render: (_, elm) => (elm.quantity * elm.sell_avg).toFixed(2),
    },
    {
      title: "Realised",
      dataIndex: "realised",
      sorter: (a, b) => a.realised - b.realised,
    },
    // {
    //   title: "ealiseFifio",
    //   dataIndex: "realiseFifio",
    //   sorter: (a, b) => (a.realiseFifio)-(b.realiseFifio),
    // },
    {
      title: "Open qty",
      dataIndex: "openQty",
      sorter: (a, b) => a.openQty - b.openQty,
    },
    {
      title: "Invested",
      dataIndex: "investedOpen",
      sorter: (a, b) => a.investedOpen - b.investedOpen,
    },

    {
      title: "Current",
      dataIndex: "currentOpen",
      sorter: (a, b) => a.currentOpen - b.currentOpen,
    },
    {
      title: "Prev closing",
      dataIndex: "ltp",
      sorter: (a, b) => a.ltp - b.ltp,
    },
    // {
    //   title: "Ltp date",
    //   dataIndex: "ltpdate",
    //   sorter: (a, b) => a.ltpdate - b.ltpdate,
    // },
    {
      title: "Unrealised",
      dataIndex: "unrealised",
      sorter: (a, b) => Number(a.unrealised) - Number(b.unrealised),
      // render: (_, elm) => (elm.quantity*(elm.ltp-elm.average_price)).toFixed(2),
    },
    {
      title: "Policy",
      dataIndex: "policy",
      sorter: (a, b) => a.policy.localeCompare(b.policy),
      // render: (_, elm) =>elm.ltp?.toFixed(2)
    },
    // {
    //   title: "Unrealised Profit",
    //   dataIndex: "unrealise",
    //   sorter: (a, b) => a.unrealise - b.unrealise,
    //   render: (_, elm) => <span className={elm.unrealise>0?'text-success':"text-danger"}>{elm.unrealise?.toFixed(2)}</span>,
    // },
    // {
    //   title: "Event",
    //   dataIndex: "event",
    //   sorter: (a, b) => a.event.localeCompare(b.event),

    // },
    // {
    //   title: "Event date",
    //   dataIndex: "event_date",
    //   sorter: (a, b) => a.event_date.localeCompare(b.event_date),
    //   render: (_, elm) => dateFormat(elm?.event_date, "dd-mm-yyyy"),

    // },
  ];

  // const tabledata=data.length>0 && data?.filter((ele)=>ele.tradingsymbol.toLowerCase().includes(search.toLocaleLowerCase()))

  const datafilter = data?.filter((e) =>
    e.tradingsymbol?.toLowerCase()?.includes(search.toLowerCase())
  );

  return (
    <div>
      <div className="py-4 border-bottom ">
        <h4 >
          <span className="me-2">
            <i className="bi bi-bar-chart"></i>
          </span>
          P&L
        </h4>
      </div>

      <div className="d-flex align-items-center justify-content-center my-4">
      <Input  placeholder="Symbol" className="w-25"  onChange={(e) => setsearch(e.target.value)}/>
      <RangePicker className="mx-2" onChange={onChange} />
      <Button type="primary" onClick={fetchdata}>
          <i className=" bi bi-arrow-right "></i>
          </Button>
       
      </div>

      <div className="d-flex align-items-center w-100">
  <div>
    <div>Total realise</div>
    <h5>
      {data?.map((ele)=>Number(ele.realised))?.reduce((a,b)=>a+b,0)?.toLocaleString('en-IN')}
    </h5>
  </div>


  <div className="ms-4">
    <div>Total Unrealise</div>
    <h5 >
      {data?.map((ele)=>Number(ele.unrealised))?.reduce((a,b)=>a+b,0)?.toLocaleString('en-IN')}
    </h5>
  </div>

</div>


      <div>
        <div className="d-flex justify-content-end my-2">
        <Input  placeholder="Search" disabled={datafilter.length ==0} className="w-25"  onChange={(e) => setsearch(e.target.value)}/>
          {/* <input
            type="text"
            className="border ps-3 "
            placeholder="Search"
            onChange={(e) => setsearch(e.target.value)}
          /> */}
          {/* <CSVLink
            filename={"P&L.csv"}
            data={data.length > 0 ? data : []}
            className=" mx-2 btn btn-primary  text-white"
          >
            <i className="bi bi-box-arrow-down"></i> Download csv
          </CSVLink> */}
          {/* <button  className=" mx-2 btn btn-primary  text-white" onClick={handleClick}>Download</button> */}
          <Button type="primary" disabled={datafilter.length ==0} className="ms-2" onClick={handleClick} loading={loading}>Download</Button>
        </div>
      
            
            <Table
              columns={tableColumns}
              bordered
              dataSource={datafilter}
              loading={loading}
              // pagination={false}
            />
         
      </div>
    </div>
  );
}

export default Pl;
